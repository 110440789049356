import styled from '@emotion/styled'

export const NavigateBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CenteredInfoNavBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
