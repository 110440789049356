import { TPlayerTransactionAction, TPlayerTransactionEntityType } from '../api/players'

export const playerTransactionEntityType: {
  [key in TPlayerTransactionEntityType]: string
} = {
  game: 'Игра',
  slot: 'Слот',
  operator: 'Оператор',
}

export const playerTransactionEntityAction: {
  [key in TPlayerTransactionAction]: string
} = {
  refill: 'Пополнение',
  withdrawal: 'Снятие',
  lose: 'Проиграл',
  win: 'Выиграл',
  in: 'Внес на игровой депозит',
  out: 'Вывел с игрового депозита',
  winOutOfPlay: 'Выиграл не находясь в игре',
}
