import { FC, Fragment, ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import { TextField } from '@mui/material'
import { formPropsDrawerCrud } from '../../constants/formPropsDrawerCrud'
import OperatorStore, { operatorFields } from '../../stores/Operator.store'
import { Select } from '../index'
import { ERole, roleNamesOptions } from '../../constants/role'
import { operatorStatusNamesOptions } from '../../constants/operator.status'

type OperatorDataProps = {
  operator: OperatorStore
  disabledFields?: boolean
  pickFields: Array<keyof OperatorStore>
}

const OperatorData: FC<OperatorDataProps> = observer(({ operator, pickFields, disabledFields }) => {
  const fieldProps = {
    ...formPropsDrawerCrud,
    ...(disabledFields && { disabled: disabledFields }),
  }
  const fieldComponents: Partial<Record<keyof OperatorStore, ReactNode>> = {
    login: (
      <TextField
        {...fieldProps}
        value={operator.login}
        onChange={(e) => operator.updateFields({ login: e.target.value })}
        label={operatorFields.login}
      />
    ),
    password: (
      <TextField
        {...fieldProps}
        value={operator.password}
        error={operator.password !== operator.confirmPassword}
        onChange={(e) => operator.updateFields({ password: e.target.value })}
        label={operatorFields.password}
      />
    ),
    confirmPassword: (
      <TextField
        {...fieldProps}
        value={operator.confirmPassword}
        error={operator.password !== operator.confirmPassword}
        onChange={(e) =>
          operator.updateFields({
            confirmPassword: e.target.value,
          })
        }
        label="Подтверждение пароля"
      />
    ),
    firstName: (
      <TextField
        {...fieldProps}
        value={operator.firstName}
        onChange={(e) =>
          operator.updateFields({
            firstName: e.target.value,
          })
        }
        label={operatorFields.firstName}
      />
    ),
    lastName: (
      <TextField
        {...fieldProps}
        value={operator.lastName}
        onChange={(e) =>
          operator.updateFields({
            lastName: e.target.value,
          })
        }
        label={operatorFields.lastName}
      />
    ),
    role: (
      <Select
        {...fieldProps}
        id="select-role"
        label={operatorFields.role}
        value={operator.role}
        options={roleNamesOptions}
        onChange={(e) => {
          operator.updateFields({
            role: e as ERole,
          })
        }}
      />
    ),
    status: (
      <Select
        {...fieldProps}
        disabled
        id="select-status-operator"
        label={operatorFields.status}
        value={operator.status}
        options={operatorStatusNamesOptions}
        onChange={(e) => {
          operator.updateFields({
            role: e as ERole,
          })
        }}
      />
    ),
  }

  return (
    <>
      {pickFields.map((item) => {
        return <Fragment key={item}>{fieldComponents[item]}</Fragment>
      })}
    </>
  )
})

export default OperatorData
