import { FC } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import PasswordIcon from '@mui/icons-material/Password'

type ChangePasswordProps = {
  disabled?: boolean
  onClick?: () => void
}

const ChangePassword: FC<ChangePasswordProps> = ({ onClick, disabled }) => {
  return (
    <IconButton
      color="warning"
      disabled={disabled}
      onClick={(e) => {
        e.preventDefault()

        if (onClick) onClick()
      }}>
      <Tooltip title="Изменить пароль">
        <PasswordIcon />
      </Tooltip>
    </IconButton>
  )
}

export default ChangePassword
