import { EActionBalance, EActionGameBalance } from '../../constants/actionBalance'
import http from '../../services/http'
import { EGameName } from '../../constants/gameNames'
import { ESort } from '../../constants/sort'

export interface ICreateTransactionPlayer {
  action: EActionBalance
  comment: string
  playerId: string
  sum: number
}

export type SourceTypeTransactionPlayer = EActionBalance | EActionGameBalance

export interface ITransactionPlayer {
  sum: number
  recipient: IRecipient
  payer: IPayer
  source: ISource
  time_transaction: string
  total_count: string
}

export interface IRecipient {
  operatorId?: string
  playerId?: string
}

export interface IPayer {
  playerId?: string
  operatorId?: string
}

export interface ISource {
  type: SourceTypeTransactionPlayer
  comment?: string
  message?: string
  action?: string
  gameId?: string
  gameName?: EGameName
  sessionId?: string
}

type TypeTransaction = 'game' | 'refill' | 'withdrawal'

class Transaction {
  prefix: string = '/api/transaction'

  getTransactionsByPlayerId = async (payload: {
    playerId: string
    limit: number
    offset: number
    from?: string
    to?: string
    type?: Array<TypeTransaction>
  }): Promise<ITransactionPlayer[]> => {
    const response = await http.post(`${this.prefix}/${payload.playerId}`, {
      limit: payload.limit,
      offset: payload.offset,
      date: payload.from,
      dateTo: payload.to,
      sortByDate: ESort.DESC,
      type: payload.type,
    })

    return response.data
  }
}

export default new Transaction()
