import { get } from 'lodash'
import { makeAutoObservable } from 'mobx'
import { EGameName } from 'constants/gameNames'
import { IStand } from '../api/stand'
import { EStandStatus } from '../constants/stand.status'

export const standFields: {
  [key in keyof IStand]: string
} = {
  id: 'ID',
  status: 'Статус',
  name: 'Название',
  typeGame: 'Игра',
  data: 'Данные',
  grid: 'Сетка',
  createdAt: 'Создано',
  updatedAt: 'Обновлено',
  assignGame: 'Принадлежит игре',
}

export default class StandStore implements IStand {
  id: string = ''

  status: EStandStatus = EStandStatus.ACCEPTED

  name: string = ''

  typeGame: EGameName = EGameName.ROULETTE

  gameName?: EGameName | undefined

  data: {
    name?: string | undefined
    streamId?: string | undefined
    fileLobbyPreview?: string | undefined
    fileQuickGamePreview?: string | undefined
  } = {
    name: '',
    streamId: '',
    fileQuickGamePreview: '',
    fileLobbyPreview: '',
  }

  grid: any

  createdAt: string = ''

  updatedAt: string = ''

  assignGame: boolean = false

  constructor(payload: Partial<IStand>) {
    makeAutoObservable(this)

    Object.assign(this, payload)
  }

  get fileLobbyPreview(): string {
    return get(this.data, 'fileLobbyPreview', '')
  }

  get fileQuickGamePreview(): string {
    return get(this.data, 'fileQuickGamePreview', '')
  }

  get streamName(): string {
    return get(this.data, 'name', '')
  }

  get streamId(): string {
    return get(this.data, 'streamId', '')
  }

  get isBlockStatus(): boolean {
    return this.status === EStandStatus.BLOCKED
  }

  get isBusy(): boolean {
    return Boolean(this.gameName)
  }

  updateFields = (data: Partial<IStand>): void => {
    Object.assign(this, data)
  }
}
