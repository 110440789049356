import { FC, ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import { Drawer, useTheme } from '@mui/material'
import { useStore } from '../../stores'
import {
  GameCreate,
  GameEdit,
  LimitCreate,
  LimitEdit,
  OperatorCreate,
  OperatorEdit,
  PlayerCreate,
  PlayerEdit,
  StandCreate,
  StandEdit,
} from '../index'
import { EDrawerView } from '../../stores/DrawerCRUD.store'

type DrawerCRUDProps = {
  children?: ReactNode
}

const DrawerCRUD: FC<DrawerCRUDProps> = observer(({ children }) => {
  const { drawerCRUDStore } = useStore()
  const theme = useTheme()

  const view = {
    [EDrawerView.UNKNOWN]: <div />,
    [EDrawerView.STAND_CREATE]: <StandCreate />,
    [EDrawerView.STAND_EDIT]: <StandEdit standId={drawerCRUDStore.id} />,
    [EDrawerView.LIMIT_CREATE]: <LimitCreate />,
    [EDrawerView.LIMIT_EDIT]: <LimitEdit limitId={drawerCRUDStore.id} />,
    [EDrawerView.PLAYER_CREATE]: <PlayerCreate />,
    [EDrawerView.PLAYER_EDIT]: <PlayerEdit playerId={drawerCRUDStore.id} />,
    [EDrawerView.OPERATOR_CREATE]: <OperatorCreate />,
    [EDrawerView.OPERATOR_EDIT]: <OperatorEdit operatorId={drawerCRUDStore.id} />,
    [EDrawerView.GAME_CREATE]: <GameCreate />,
    [EDrawerView.GAME_EDIT]: <GameEdit gameId={drawerCRUDStore.id} />,
  }

  return (
    <Drawer
      sx={{
        zIndex: theme.zIndex.drawer + 2,
      }}
      anchor="right"
      open={Boolean(drawerCRUDStore.view)}
      onClose={drawerCRUDStore.resetView}>
      {view[drawerCRUDStore.view]}
      {children}
    </Drawer>
  )
})

export default DrawerCRUD
