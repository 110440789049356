import http from '../../services/http'
import { ELimitStatus } from '../../constants/limit.status'
import { EGameName } from '../../constants/gameNames'

export interface ILimit {
  id: string
  status: ELimitStatus
  name: string
  minimum: number
  maximum: number
  maximumPayout?: number
  nameGame: EGameName
  gameName?: EGameName // Указывает, что уже занят
  minBetColDozen?: number
  maxBetColDozen?: number
  minBetEvenChance?: number
  maxBetEvenChance?: number
  createdAt: string
  updatedAt: string
  assignGame: boolean
}

export interface ILimits {
  count: number
  rows: ILimit[]
}

class Limits {
  prefix: string = '/api/limits'

  getLimits = async (): Promise<ILimits> => {
    const response = await http.get(`${this.prefix}`)

    return response.data
  }

  createLimit = async (payload: Partial<ILimit>): Promise<ILimit> => {
    const response = await http.post(this.prefix, {
      ...payload,
    })

    return response.data
  }

  getLimitById = async (id: string): Promise<ILimit> => {
    const response = await http.get(`${this.prefix}/${id}`)

    return response.data
  }

  updateLimitById = async (id: string, payload: Partial<ILimit>): Promise<ILimit> => {
    const response = await http.put(`${this.prefix}/${id}`, {
      ...payload,
    })

    return response.data
  }

  deleteLimitById = async (id: string): Promise<void> => {
    await http.delete(`${this.prefix}/${id}`)
  }
}

export default new Limits()
