import { makeAutoObservable } from 'mobx'
import Operators, { IOperator, IOperatorData } from '../api/operators'
import { ERole } from '../constants/role'
import { EOperatorStatus } from '../constants/operator.status'

export const operatorFields: {
  [key in keyof IOperator]: string
} = {
  id: 'ID',
  login: 'Логин',
  password: 'Пароль',
  firstName: 'Имя',
  lastName: 'Фамилия',
  role: 'Роль',
  status: 'Статус',
  data: '',
  countLoginError: '',
  createdAt: '',
  updatedAt: '',
}

export default class OperatorStore implements IOperator {
  countLoginError: number = 0

  createdAt: string = ''

  data: IOperatorData = {}

  firstName: string = ''

  id: string = ''

  lastName: string = ''

  login: string = ''

  password: string = ''

  role: ERole = ERole.ADMINISTRATOR

  status: EOperatorStatus = EOperatorStatus.ACTIVE

  updatedAt: string = ''

  // not implement

  confirmPassword: string = ''

  constructor(payload: IOperator | null) {
    makeAutoObservable(this)

    if (payload) Object.assign(this, payload)
  }

  updateFields = (
    data: Partial<
      IOperator & {
        confirmPassword: string
      }
    >
  ): void => {
    Object.assign(this, data)
  }

  get isBlockSave(): boolean {
    return false
  }

  get isBlockStatus(): boolean {
    return [EOperatorStatus.BLOCKED, EOperatorStatus.DELETED].includes(this.status)
  }

  get isDeleteStatus(): boolean {
    return this.status === EOperatorStatus.DELETED
  }

  onCreate = async (): Promise<IOperator> => {
    return Operators.createOperator({
      login: this.login,
      password: this.password,
      firstName: this.firstName,
      lastName: this.lastName,
      role: this.role,
      status: this.status,
    })
  }

  onDelete = async (id: string): Promise<void> => {
    await Operators.deleteOperator(id)
  }

  onUpdateFields = async (id: string): Promise<void> => {
    const { firstName, lastName, role, login } = this

    await Operators.updateFieldsOperator(id, {
      firstName,
      lastName,
      role,
      login,
    })
  }

  onBlockStatusOperator = async (id: string): Promise<void> => {
    await Operators.updateBlockOperator(id)
  }

  onUnBlockStatusOperator = async (id: string): Promise<void> => {
    await Operators.updateUnBlockOperator(id)
  }
}
