import { FC, useEffect, useState } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import HistoryIcon from '@mui/icons-material/History'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { CancelBtn, CrudIconBtn, GameData, WrapDrawerEditForm } from '../index'
import GameStore, { IGameStore } from '../../stores/Game.store'
import { useStore } from '../../stores'
import routes from '../../router/routes'
import { EDateFormat, formatDateFallback, getCurrentDay } from '../../utils/date'

type GameEditProps = {
  gameId: string
}

const GameEdit: FC<GameEditProps> = ({ gameId }) => {
  const [edit, setEdit] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { gamesStore, drawerCRUDStore } = useStore()
  const navigate = useNavigate()

  const game = useLocalObservable(() => {
    return new GameStore({})
  })

  useEffect(() => {
    setLoading(true)
    ;(async () => {
      try {
        const getGame = await gamesStore.getGameById(gameId)

        game.updateFieldsFromServer({
          ...getGame,
        })
      } catch (e) {
        toast.error(`${e}`)
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  const requiredFields: Array<keyof IGameStore> = [
    'gameName',
    'gameType',
    'status',
    'dealerId',
    'limitId',
    'standId',
    'ipCyclops',
    'timerTechPause',
  ]

  const timerFields: Array<keyof IGameStore> = ['timerToSpin', 'timerToEndBets']

  const pickFields: Array<keyof IGameStore> = [...requiredFields, ...(game.ipCyclops.length ? [] : timerFields)]

  return (
    <WrapDrawerEditForm
      title={`Просмотр игры ${gameId}`}
      busyText={(game.isStarted && 'Игра начата') || (game.isFinished && 'Игра закончена') || ''}
      isBusy={game.isStarted || game.isFinished}
      onEdit={() => {
        setEdit(true)
      }}
      isShowSaveBtn={edit}
      isShowEditBtn={!edit}
      onSave={async () => {
        try {
          await gamesStore.onUpdateGameSettingsById(gameId, {
            game_name: game.gameName,
            game_type: game.gameType,
            limitId: game.limitId,
            standId: game.standId,
            timer: {
              toSpin: game.timerToSpin,
              toEndBets: game.timerToEndBets,
              techPause: game.timerTechPause,
            },
            ...(game.ipCyclops && { ipCyclops: game.ipCyclops }),
          })
          toast.success(`Игра ${gameId} успешно обновлена`)
        } catch (e) {
          toast.error(`${e}`)
        } finally {
          setEdit(false)
        }
      }}
      isShowBlockBtn={false}
      isShowDeleteBtn={false}
      additionalButtons={
        <>
          {(game.isConfirmed || game.isCreatedByManager) && (
            <CancelBtn
              tooltip="Отменить игру"
              onClick={async () => {
                try {
                  await gamesStore.onCancelGameById(gameId)
                  toast.success(`Игра ${gameId} успешно отменена`)
                  drawerCRUDStore.resetView()
                } catch (e) {
                  toast.error(`${e}`)
                }
              }}
            />
          )}
          {game.isStarted && (
            <CrudIconBtn
              tooltip="Завершить игру"
              color="success"
              icon={<AssistantPhotoIcon />}
              onClick={async () => {
                try {
                  await gamesStore.onFinishGameById(gameId)
                  toast.success(`Игра ${gameId} успешно завершена`)
                  drawerCRUDStore.resetView()
                } catch (e) {
                  toast.error(`${e}`)
                }
              }}
            />
          )}
          {(game.isStarted || game.isConfirmed) && (
            <CrudIconBtn
              tooltip="Войти в игру"
              color="primary"
              icon={<PlayCircleOutlineIcon />}
              onClick={async () => {
                navigate(`${routes.manageGameRoulette}/${gameId}`)
              }}
            />
          )}
          {game.isFinished && (
            <CrudIconBtn
              icon={<ContentCopyIcon />}
              color="primary"
              disabled
              onClick={() => {
                console.log('copy game')
              }}
              tooltip="Создать подтвержденную игру с такими же параметрами"
            />
          )}
          {game.isShowHistory && (
            <CrudIconBtn
              icon={<HistoryIcon />}
              color="primary"
              onClick={() => {
                drawerCRUDStore.resetView()
                const formatDate = EDateFormat.yyy_MM_dd_HH_mm

                const dateFrom = formatDateFallback(new Date(game.createdAt).toISOString(), formatDate)
                const dateTo = getCurrentDay(formatDate)

                navigate(`${routes.historyRoulette}/${gameId}?dateFrom=${dateFrom}&dateTo=${dateTo}`)
              }}
              tooltip="Показать историю"
            />
          )}
        </>
      }>
      {!loading && <GameData game={game} pickFields={pickFields} disabledAll={!edit} />}
    </WrapDrawerEditForm>
  )
}

export default observer(GameEdit)
