import { makeAutoObservable, runInAction } from 'mobx'
import Stand, { IStand, IStandCreate, IStands } from '../api/stand'
import { EStandStatus } from '../constants/stand.status'
import { EGameName } from '../constants/gameNames'

export class StandsStore implements IStands {
  count: number = 0

  rows: Array<IStand> = []

  constructor() {
    makeAutoObservable(this)
  }

  getStands = async (): Promise<void> => {
    try {
      const data = await Stand.getStands()

      runInAction(() => {
        this.count = data.count

        this.rows = [...data.rows]
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  getStandById = async (id: string): Promise<IStand> => {
    return Stand.getStandById(id)
  }

  onCreateStand = async (data: IStandCreate): Promise<IStand> => {
    return Stand.createStand({ ...data })
  }

  onUpdateStandById = async (id: string, data: Partial<IStand>): Promise<IStand> => {
    return Stand.updateStandById(id, data)
  }

  onDeleteStandById = async (id: string): Promise<void> => {
    return Stand.deleteStand(id)
  }

  onBlockStandById = async (id: string): Promise<IStand> => {
    return Stand.updateStandById(id, {
      status: EStandStatus.BLOCKED,
    })
  }

  onUnBlockStandById = async (id: string): Promise<IStand> => {
    return Stand.updateStandById(id, {
      status: EStandStatus.ACCEPTED,
    })
  }

  geStandsForRoulette = async (): Promise<Array<IStand>> => {
    const data = await Stand.getStands()

    return data.rows.filter((item) => item.typeGame === EGameName.ROULETTE)
  }
}
