import { FC } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { Button, List, ListItem, TextField } from '@mui/material'
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input'
import { toast } from 'react-toastify'
import { ECurrency, symbolCurrency } from '@lbs/currency'
import { Select, WrapDrawerCreateForm } from '../index'
import CreatePlayerStore from '../../stores/CreatePlayer.store'
import { formPropsDrawerCrud } from '../../constants/formPropsDrawerCrud'
import { useStore } from '../../stores'
import Players from '../../api/players'

const PlayerCreate: FC = observer(() => {
  const { drawerCRUDStore } = useStore()
  const player = useLocalObservable(() => {
    return new CreatePlayerStore({})
  })

  return (
    <WrapDrawerCreateForm
      title="Создание нового игрока"
      onSave={async () => {
        try {
          const { login, phones, currency, rouletteRatio } = player

          await Players.createPlayer({
            login,
            phones: [
              ...phones.map((item) => ({
                number: item.number.replace(/\s/g, ''),
                priority: item.priority,
              })),
            ],
            currency,
            rouletteRatio,
          })

          drawerCRUDStore.resetView()
        } catch (e: any) {
          toast.error(`Failed create player`)
        }
      }}
      disabledSave={!player.login || !player.phones.length}>
      <TextField
        {...formPropsDrawerCrud}
        value={player.login}
        onChange={(e) => player.updateFields({ login: e.target.value })}
        label="Логин"
      />
      <Select
        {...formPropsDrawerCrud}
        id="currency"
        label="Валюта"
        value={player.currency}
        options={Object.values(ECurrency).map((item) => {
          return {
            value: item,
            label: `${symbolCurrency[item]} (${item})`,
          }
        })}
        onChange={(e) => {
          player.updateFields({ currency: e as ECurrency })
        }}
      />
      <TextField
        {...formPropsDrawerCrud}
        value={player.rouletteRatio}
        type="number"
        onChange={(e) => player.updateFields({ rouletteRatio: Number(e.target.value) })}
        label="Коэффициент для рулетки"
      />
      <List>
        {player.phones.map((item, index) => {
          const isInvalidPhone = player.phones[index].number.length > 0 && !matchIsValidTel(player.phones[index].number)
          return (
            <ListItem disablePadding key={item.priority}>
              <MuiTelInput
                {...formPropsDrawerCrud}
                error={isInvalidPhone}
                helperText={isInvalidPhone ? 'Номер не валиден' : ''}
                value={player.phones[index].number}
                onChange={(e) => {
                  player.changeNumber(e, index)
                }}
              />
            </ListItem>
          )
        })}
      </List>
      <Button
        type="button"
        variant="outlined"
        onClick={(e) => {
          e.preventDefault()

          player.addPhone()
        }}>
        Добавить номер
      </Button>
    </WrapDrawerCreateForm>
  )
})

export default PlayerCreate
