import http from '../../services/http'

export interface IInviteCode {
  code: number
}

// const zxc = {
//   type: 'register',
//   data: {
//     message: 'Ваш код регистрации: 60112. Запустите приложение на планшете, нажмите РЕГИСТРАЦИЯ.',
//     playerPhone: '3213213131',
//     code: 60112,
//   },
//   sendAt: '2023-08-15 12:52:26.5030',
//   createdFor: '626d-ae7d-8686-e607',
//   resend: false,
// }

export interface IRegisterInvitePlayer {
  type: 'register'
  data: {
    message: string
    playerPhone: string // first player phone number
    code: number // invite code
  }
  sendAt: Date | string
  createdFor: string // id player
  resend: boolean
}

class Coupon {
  prefix: string = '/api/coupon'

  registerInvitePlayer = async (data: IRegisterInvitePlayer): Promise<void> => {
    await http.post(this.prefix, {
      ...data,
    })
  }

  getNewCode = async (): Promise<IInviteCode> => {
    const response = await http.get(`${this.prefix}`, {
      params: {
        action: 'getNewCode',
      },
    })

    return response.data
  }
}

export default new Coupon()
