import { Outlet } from 'react-router-dom'
import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { useStore } from '../../stores'
import AlertDialog from '../../components/AlertDialog'

const Common: FC = () => {
  const { alertDialogStore } = useStore()

  return (
    <>
      <Outlet />

      <AlertDialog
        open={alertDialogStore.open}
        title={alertDialogStore.title}
        content={alertDialogStore.content}
        onAgree={alertDialogStore.onAgree}
        onDisagree={alertDialogStore.onClose}
      />
      <ToastContainer
        position="bottom-left"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  )
}

export default observer(Common)
