export enum ELimitStatus {
  DELETED = 100,
  BLOCKED = 150,
  ACCEPTED = 200,
  IN_GAME = 201,
}

export const limitStatusNames: {
  [k: string]: string
} = {
  [ELimitStatus.DELETED]: 'Удалён',
  [ELimitStatus.BLOCKED]: 'Заблокирован',
  [ELimitStatus.ACCEPTED]: 'Активен',
  [ELimitStatus.IN_GAME]: 'В игре',
}
