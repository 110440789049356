import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Box, IconButton } from '@mui/material'
import AccountCircle from '@mui/icons-material/AccountCircle'
import LogoutIcon from '@mui/icons-material/Logout'
import { useStore } from '../../stores'

const Account: FC = observer(() => {
  const { loginStore } = useStore()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 2,
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 1,
        }}>
        <AccountCircle />
        {loginStore.operator?.login}
      </Box>

      <IconButton
        size="large"
        color="inherit"
        onClick={async (e) => {
          e.preventDefault()

          await loginStore.logout()
        }}>
        <LogoutIcon />
      </IconButton>
    </Box>
  )
})

export default Account
