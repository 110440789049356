import { makeAutoObservable } from 'mobx'
import { EGameStatus } from '../constants/game.status'
import { EGameName } from '../constants/gameNames'
import { EGameVisibility } from '../constants/gameVisibility'
import { IGame } from '../api/games'

export interface IGameStore {
  id: string
  status: EGameStatus
  gameName: EGameName
  gameType: EGameVisibility
  ipCyclops: string
  timerTechPause: number
  timerToEndBets: number
  timerToSpin: number
  limitId: string
  standId: string
  dealerId: string
}

export const gameFields: {
  [key in keyof IGameStore]: string
} = {
  id: 'ID',
  status: 'Статус',
  gameName: 'Игра',
  gameType: 'Видимость игры',
  ipCyclops: 'IP циклопа',
  timerTechPause: 'Время для технической паузы',
  timerToEndBets: 'Время на постановку ставок',
  timerToSpin: 'Время до спина',
  limitId: 'Лимит',
  standId: 'Стойка',
  dealerId: 'Дилер',
}

export default class GameStore implements IGameStore {
  id: string = ''

  gameName = EGameName.ROULETTE

  gameType = EGameVisibility.PUBLIC

  ipCyclops = ''

  status = EGameStatus.CONFIRMED

  timerTechPause = 5

  timerToEndBets = 25 // не может быть больше чем timerToSpin

  timerToSpin = 30

  dealerId = ''

  limitId = ''

  standId = ''

  createdAt = ''

  constructor(payload: Partial<IGameStore>) {
    makeAutoObservable(this)

    Object.assign(payload)
  }

  get isStarted(): boolean {
    return this.status === EGameStatus.STARTED
  }

  get isConfirmed(): boolean {
    return this.status === EGameStatus.CONFIRMED
  }

  get isFinished(): boolean {
    return this.status === EGameStatus.FINISHED
  }

  get isShowHistory(): boolean {
    return this.isStarted || this.isFinished
  }

  get isCreatedByManager(): boolean {
    return this.status === EGameStatus.CREATED_BY_MANAGER
  }

  updateFields = (data: Partial<IGameStore>): void => {
    Object.assign(this, data)
  }

  updateFieldsFromServer = (data: IGame): void => {
    const { id, gameSettings, acceptedDealer, status, createdAt } = data
    const { timer, limitId, standId, ipCyclops } = gameSettings
    const { toSpin, techPause, toEndBets } = timer

    this.id = id
    this.timerTechPause = techPause
    this.timerToEndBets = toEndBets
    this.timerToSpin = toSpin
    this.dealerId = acceptedDealer
    this.limitId = limitId
    this.standId = standId
    this.ipCyclops = ipCyclops || ''
    this.status = status
    this.createdAt = createdAt
  }

  changeTimerToEndBets = (data: number): void => {
    this.timerToEndBets = Math.max(10, Math.min(this.timerToSpin, data))
  }

  changeTimerToSpin = (data: number): void => {
    this.timerToSpin = Math.max(10, Math.max(data, this.timerToEndBets))
  }

  changeTimerTechPause = (data: number): void => {
    this.timerTechPause = Math.max(1, data)
  }
}
