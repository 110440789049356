import { FC } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

type CancelBtnProps = {
  disabled?: boolean
  onClick?: () => void
  tooltip?: string
}

const CancelBtn: FC<CancelBtnProps> = ({ onClick, disabled, tooltip }) => {
  return (
    <IconButton
      color="error"
      disabled={disabled}
      onClick={(e) => {
        e.preventDefault()

        if (onClick) onClick()
      }}>
      <Tooltip title={tooltip || ''}>
        <CloseIcon />
      </Tooltip>
    </IconButton>
  )
}

export default CancelBtn
