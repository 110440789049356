import { makeAutoObservable } from 'mobx'
import Recordings, { IRecording, IRecordingWithEvents } from '../api/recordings'

export class RecordingsStore {
  limit: number = 10

  offset: number = 0

  totalRows: number = 0

  recordings: IRecording[] = []

  constructor() {
    makeAutoObservable(this)
  }

  getRecordingById = async (id: string): Promise<IRecordingWithEvents> => {
    return Recordings.getRecordingById(id)
  }

  onLoadMore = (): void => {
    this.offset += this.limit

    // this.getRecordings()
  }
}
