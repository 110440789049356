import { FC } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { toast } from 'react-toastify'
import { StandData, WrapDrawerCreateForm } from '../index'
import { useStore } from '../../stores'
import StandStore from '../../stores/Stand.store'
import { EGameName } from '../../constants/gameNames'
import { EDrawerView } from '../../stores/DrawerCRUD.store'

const StandCreate: FC = () => {
  const { drawerCRUDStore, standsStore } = useStore()

  const stand = useLocalObservable(() => {
    return new StandStore({
      name: 'Название стойки',
      typeGame: EGameName.ROULETTE,
    })
  })

  return (
    <WrapDrawerCreateForm
      title="Создание стойки"
      onSave={async () => {
        try {
          const { id } = await standsStore.onCreateStand({
            name: stand.name,
            typeGame: stand.typeGame,
            fileLobbyPreview: stand?.data?.fileLobbyPreview,
            fileQuickGamePreview: stand?.data?.fileQuickGamePreview,
            streamName: stand?.data?.name,
            streamId: stand?.data?.streamId,
          })
          toast.success('Стойка успешно создана')

          drawerCRUDStore.changeView(EDrawerView.STAND_EDIT, id)
        } catch (e) {
          toast.error(`${e}`)
        }
      }}>
      <StandData
        stand={stand}
        pickFields={['name', 'typeGame', 'fileLobbyPreview', 'fileQuickGamePreview', 'streamName']}
      />
    </WrapDrawerCreateForm>
  )
}

export default observer(StandCreate)
