import React from 'react'
import ReactDOM from 'react-dom/client'
import { ThemeProvider } from '@mui/material/styles'
import '@fontsource/montserrat' // Defaults to weight 400
import '@fontsource/montserrat/400.css' // Specify weight
import '@fontsource/montserrat/500.css' // Specify weight
import '@fontsource/montserrat/600.css' // Specify weight
import '@fontsource/montserrat/700.css' // Specify weight
import '@fontsource/montserrat/800.css' // Specify weight
import '@fontsource/montserrat/900.css' // Specify weight
import { CssBaseline } from '@mui/material'
import 'react-day-picker/dist/style.css'
import App from './App'
import theme from './core/theme'
import GlobalStyles from './core/GlobalStyles'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <GlobalStyles />
    <App />
  </ThemeProvider>
)
