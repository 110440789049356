export enum EActionBalance {
  WITHDRAWAL = 'withdrawal', // Снятие
  REFILL = 'refill', // Пополнение
  LOSE = 'lose',
  WIN = 'win',
}

export enum EActionGameBalance {
  GAME = 'game',
}

export const actionBalance: {
  [k: string]: string
} = {
  [EActionBalance.WITHDRAWAL]: 'Снятие',
  [EActionBalance.REFILL]: 'Пополнение',
  [EActionBalance.LOSE]: 'Проиграл',
  [EActionBalance.WIN]: 'Выиграл',
}

export const actionGameBalance: {
  [k: string]: string
} = {
  [EActionGameBalance.GAME]: 'Игра',
}

export const actionBalanceOptions = Object.keys(actionBalance).map((item) => {
  return {
    value: item,
    label: actionBalance[item],
  }
})
