import { FC } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { toast } from 'react-toastify'
import { GameData, WrapDrawerCreateForm } from '../index'
import GameStore, { gameFields, IGameStore } from '../../stores/Game.store'
import { useStore } from '../../stores'
import { EGameStatus } from '../../constants/game.status'

const GameCreate: FC = () => {
  const { gamesStore, drawerCRUDStore } = useStore()
  const game = useLocalObservable(() => {
    return new GameStore({})
  })

  const requiredFields: Array<keyof IGameStore> = [
    'gameName',
    'gameType',
    'dealerId',
    'limitId',
    'standId',
    'ipCyclops',
    'timerTechPause',
  ]

  const timerFields: Array<keyof IGameStore> = ['timerToSpin', 'timerToEndBets']

  const pickFields: Array<keyof IGameStore> = [...requiredFields, ...(game.ipCyclops.length ? [] : timerFields)]

  return (
    <WrapDrawerCreateForm
      title="Создание новой игры"
      onSave={async () => {
        try {
          if (!game.dealerId || !game.limitId || !game.standId) {
            throw new Error(
              `Missing properties ${gameFields.dealerId} or ${gameFields.standId} or ${gameFields.limitId}`
            )
          }

          await gamesStore.onCreateGame({
            acceptedDealer: game.dealerId,
            createFor: [],
            gameSettings: {
              game_name: game.gameName,
              game_type: game.gameType,
              limitId: game.limitId,
              standId: game.standId,
              ...(game.ipCyclops && {
                cyclops: true,
                ipCyclops: game.ipCyclops,
              }),
              timer: {
                techPause: game.timerTechPause,
                toEndBets: game.timerToEndBets,
                toSpin: game.timerToSpin,
              },
            },
            startsAt: new Date().toISOString(),
            status: EGameStatus.CONFIRMED,
          })

          toast.success('Игра успешно создана')
          drawerCRUDStore.resetView()
        } catch (e) {
          toast.error(`${e}`)
        }
      }}>
      <GameData game={game} pickFields={pickFields} />
    </WrapDrawerCreateForm>
  )
}

export default observer(GameCreate)
