import { FC, FormEvent, forwardRef, ReactElement, Ref, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { TransitionProps } from '@mui/material/transitions'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Slide,
  Stack,
  TextField,
} from '@mui/material'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { actionBalance, EActionBalance } from '../../constants/actionBalance'
import { formPropsDrawerCrud } from '../../constants/formPropsDrawerCrud'
import { ApiPlayers } from '../../api'
import { moneyFormat } from '../../utils/money'
import { typePaymentByOperatorOptions } from '../../constants/typePaymentByOperator'

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: ReactElement<any, any>
    },
    ref: Ref<unknown>
  ) => {
    return <Slide direction="left" ref={ref} {...props} />
  }
)

type DialogAddPlayerBalanceProps = {
  isOpen: boolean
  playerId: string
  playerLogin: string
  onClose: () => void
  onSave?: () => void
}

const formatNumber = (value: string): string => {
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

const typeOptions = [
  {
    value: EActionBalance.REFILL,
    label: actionBalance[EActionBalance.REFILL],
  },
  {
    value: EActionBalance.WITHDRAWAL,
    label: actionBalance[EActionBalance.WITHDRAWAL],
  },
]

const DialogAddPlayerBalance: FC<DialogAddPlayerBalanceProps> = observer(
  ({ isOpen, playerId, playerLogin, onSave, onClose }) => {
    const [balance, setBalance] = useState('')
    const [type, setType] = useState<string>('')
    const [typePayment, setTypePayment] = useState<string>('')
    const [comment, setComment] = useState('')
    const [disabled, setDisabled] = useState<boolean>(true)

    const handleClose = (): void => {
      onClose()
    }

    const onSubmit = async (event: FormEvent): Promise<void> => {
      event.preventDefault()
      event.stopPropagation()

      try {
        const numericValue = parseInt(balance.replace(/\s/g, ''), 10) || 0

        await ApiPlayers.patchBalance(playerId, {
          balance: type === EActionBalance.REFILL ? numericValue : -numericValue,
          typePayment,
          description: comment,
        })

        toast.success(`Успешная транзакция`)

        if (onSave) onSave()

        handleClose()
      } catch (err: any) {
        toast.error(`Ошибка транзакции: ${err}`)
      }
    }

    const { data: playerBalance, isError } = useQuery(
      `playerId: ${playerId} balance`,
      async () => {
        return ApiPlayers.getPlayerBalanceById(playerId)
      },
      {
        enabled: !!playerId,
        onError: (error) => {
          toast.error(`${error}`)
        },
      }
    )

    if (isError) return null

    const maxWithdrawal = Number(playerBalance?.balance) || 0

    useEffect(() => {
      const numericValue = parseInt(balance.replace(/\s/g, ''), 10) || 0
      setDisabled(
        !balance || !type || !typePayment || (type === EActionBalance.WITHDRAWAL && numericValue > maxWithdrawal)
      )
    }, [balance, type, typePayment])

    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isOpen}
        TransitionComponent={Transition}
        onClose={handleClose}
        component="form"
        onSubmit={onSubmit}>
        <DialogTitle>Изменение баланса для: {playerLogin}</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2}>
            <Alert severity="warning">Доступно для списания: {moneyFormat(String(maxWithdrawal))}</Alert>

            <FormControl>
              <TextField
                {...formPropsDrawerCrud}
                label="Cумма"
                autoFocus
                type="text"
                InputProps={{ inputProps: { type: 'text', min: 0 } }}
                value={formatNumber(balance)}
                onChange={(e) => {
                  const newValue = e.target.value

                  const numericValue = newValue.replace(/\s/g, '')

                  if (numericValue === '' || (Number(numericValue) > 0 && !Number.isNaN(Number(numericValue)))) {
                    setBalance(numericValue)
                  }
                }}
              />
            </FormControl>

            <FormControl component="fieldset">
              <FormLabel>Тип попонения:</FormLabel>
              <RadioGroup
                value={type}
                onChange={(e) => {
                  setType(e.target.value)
                }}>
                {typeOptions.map((item) => {
                  return <FormControlLabel key={item.value} value={item.value} label={item.label} control={<Radio />} />
                })}
              </RadioGroup>
            </FormControl>

            <FormControl component="fieldset">
              <FormLabel>Тип платежа:</FormLabel>
              <RadioGroup
                row
                value={typePayment}
                onChange={(e) => {
                  setTypePayment(e.target.value)
                }}>
                {typePaymentByOperatorOptions.map((typePaymentBalanceOption) => {
                  return (
                    <FormControlLabel
                      key={typePaymentBalanceOption.value}
                      value={typePaymentBalanceOption.value}
                      control={<Radio />}
                      label={typePaymentBalanceOption.label}
                    />
                  )
                })}
              </RadioGroup>
            </FormControl>

            <TextField
              fullWidth
              label="Комментарий"
              multiline
              rows={4}
              value={comment}
              onChange={(e) => {
                e.preventDefault()

                setComment(e.target.value)
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отменить</Button>
          <Button disabled={disabled} type="submit">
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
)

export default DialogAddPlayerBalance
