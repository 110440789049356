import { isNull, mergeWith } from 'lodash'
import { makeAutoObservable } from 'mobx'
import { ILimit } from '../api/limits'
import { EGameName } from '../constants/gameNames'
import { ELimitStatus } from '../constants/limit.status'

export const limitFields: {
  [key in keyof ILimit]: string
} = {
  id: 'ID',
  name: 'Название',
  nameGame: 'Игра',
  status: 'Статус',
  minimum: 'MIN ставка',
  maximum: 'MAX ставка',
  maximumPayout: 'MAX выплата',
  minBetColDozen: 'MIN ставка Columns, Dozen',
  maxBetColDozen: 'MAX ставка Columns, Dozen',
  minBetEvenChance: 'MIN ставка Even Chances',
  maxBetEvenChance: 'MAX ставка Even Chances',
  updatedAt: '',
  assignGame: '',
  createdAt: '',
  gameName: '',
}

export default class LimitStore implements ILimit {
  id: string = ''

  status: ELimitStatus = ELimitStatus.ACCEPTED

  name: string = ''

  minimum: number = 1

  maximum: number = 2

  maximumPayout: number = 1

  nameGame: EGameName = EGameName.ROULETTE

  gameName?: EGameName

  minBetColDozen?: number = 1

  maxBetColDozen?: number = 1

  minBetEvenChance?: number = 1

  maxBetEvenChance?: number = 1

  createdAt: string = ''

  updatedAt: string = ''

  assignGame: boolean = false

  constructor(payload: Partial<ILimit>) {
    makeAutoObservable(this)

    this.updateFields(payload)
  }

  get isBusy(): boolean {
    return Boolean(this.gameName)
  }

  get isBlocked(): boolean {
    return this.status === ELimitStatus.BLOCKED
  }

  get isRouletteGame(): boolean {
    return this.nameGame === EGameName.ROULETTE
  }

  get isCardGame(): boolean {
    return [EGameName.TEXAS_POKER, EGameName.RUSSIAN_POKER].includes(this.nameGame)
  }

  updateFields = (data: Partial<ILimit>): void => {
    const merge = mergeWith({}, this, data, (o, s) => (isNull(s) ? o : s))
    Object.assign(this, merge)
  }

  changeMinimum = (data: number): void => {
    this.minimum = Math.max(1, data)

    if (this.minimum >= this.maximum) this.maximum = data + 1
  }

  changeMaximum = (data: number): void => {
    this.maximum = Math.max(this.minimum, data)
  }
}
