import { FC } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { FullScreenRouteDialog } from '../index'
import DetailHistoryRoulette, { DetailHistoryRouletteProps } from '../DetailHistoryRoulette'

type DetailHistoryRouletteDialogParams = {
  gameId: string
  sessionId: string
}

type TLocationState = Partial<Pick<DetailHistoryRouletteProps, 'sessions' | 'dates'>>

const DetailHistoryRouletteDialog: FC = () => {
  const { gameId, sessionId } = useParams<DetailHistoryRouletteDialogParams>()
  const location = useLocation()

  const locationState = location.state as TLocationState

  if (!gameId || !sessionId) return null

  return (
    <FullScreenRouteDialog title={`Сессия: №${sessionId} `}>
      <DetailHistoryRoulette
        gameId={gameId}
        sessionId={Number(sessionId)}
        dates={locationState?.dates || []}
        sessions={locationState?.sessions || []}
      />
    </FullScreenRouteDialog>
  )
}

export default DetailHistoryRouletteDialog
