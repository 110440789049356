import { FC, forwardRef, ReactElement, ReactNode, Ref } from 'react'
import { TransitionProps } from '@mui/material/transitions'
import { Box, Button, Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material'

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: ReactElement<any, any>
    },
    ref: Ref<unknown>
  ) => {
    return <Slide direction="left" ref={ref} {...props} />
  }
)

type DialogProps = {
  open: boolean
  title: string
  tooltip?: string
  disableSave?: boolean
  children?: ReactNode
  onClose: () => void
  onSave: () => void
  saveText?: string
}

const Dialog: FC<DialogProps> = ({ open, title, children, onClose, onSave, ...rest }) => {
  const { saveText = 'Cохранить', disableSave = false, tooltip = '' } = rest

  return (
    <MuiDialog open={open} TransitionComponent={Transition} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {children}

        {tooltip && (
          <Box display="flex" justifyContent="end" width={1}>
            <small>{tooltip}</small>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Отменить</Button>
        <Button
          disabled={disableSave}
          autoFocus
          onClick={(e) => {
            e.preventDefault()

            onSave()
            onClose()
          }}>
          {saveText}
        </Button>
      </DialogActions>
    </MuiDialog>
  )
}

export default Dialog
