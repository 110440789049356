import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import LogoutIcon from '@mui/icons-material/Logout'
import { Button } from '@mui/material'

const LogOut: FC = observer(() => {
  return (
    <Button color="inherit" endIcon={<LogoutIcon />}>
      Выйти
    </Button>
  )
})

export default LogOut
