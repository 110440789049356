import { makeAutoObservable } from 'mobx'
import { ECurrency } from '@lbs/currency'
import { ICreatePlayer, IPlayerPhone } from '../api/players'

export default class CreatePlayerStore implements ICreatePlayer {
  login = 'New User'

  phones: IPlayerPhone[] = [
    {
      number: '',
      priority: 1,
    },
  ]

  currency: ECurrency = ECurrency.RUB

  rouletteRatio: number = 0

  constructor(payload: Partial<ICreatePlayer>) {
    makeAutoObservable(this)

    Object.assign(payload)
  }

  updateFields = (payload: Partial<ICreatePlayer>): void => {
    Object.assign(this, payload)
  }

  changeNumber = (tel: string, index: number): void => {
    this.phones[index] = {
      number: tel,
      priority: index + 1,
    }
  }

  addPhone = (): void => {
    this.phones.push({
      number: '',
      priority: this.phones.length + 1,
    })
  }
}
