import { FC, useEffect, useState } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { toast } from 'react-toastify'
import { useStore } from '../../stores'
import { StandData, WrapDrawerEditForm } from '../index'
import StandStore from '../../stores/Stand.store'

type StandEditProps = {
  standId: string
}

const StandEdit: FC<StandEditProps> = ({ standId }) => {
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const { standsStore, drawerCRUDStore } = useStore()

  const stand = useLocalObservable(() => {
    return new StandStore({})
  })

  useEffect(() => {
    ;(async () => {
      const data = await standsStore.getStandById(standId)

      stand.updateFields({
        ...data,
      })
    })()
    return () => {
      ;(async () => {
        await standsStore.getStands()
      })()
    }
  }, [])

  return (
    <WrapDrawerEditForm
      title={`Просмотр стойки ${stand.name}`}
      busyText="Стойка закреплена за активной игрой"
      isBusy={stand.isBusy}
      isShowEditBtn={!isEdit}
      isShowSaveBtn={isEdit}
      isShowBlockBtn={!stand.isBlockStatus}
      isShowUnBlockBtn={stand.isBlockStatus}
      onEdit={() => {
        setIsEdit(true)
      }}
      onSave={async () => {
        try {
          const data = await standsStore.onUpdateStandById(stand.id, {
            name: stand.name,
            typeGame: stand.typeGame,
            data: {
              fileLobbyPreview: stand.fileLobbyPreview,
              fileQuickGamePreview: stand.fileQuickGamePreview,
              name: stand?.data?.name,
              streamId: stand?.data?.streamId,
            },
          })
          stand.updateFields({ ...data })
          toast.success(`Стойка ${stand.name} успешно обновлена`)
        } catch (e) {
          toast.error(`${e}`)
        } finally {
          setIsEdit(false)
        }
      }}
      onDelete={async () => {
        try {
          await standsStore.onDeleteStandById(stand.id)
          toast.success(`Стойка ${stand.name} успешно удалена`)
          drawerCRUDStore.resetView()
        } catch (e) {
          toast.error(`${e}`)
        }
      }}
      onBlock={async () => {
        try {
          const data = await standsStore.onBlockStandById(stand.id)
          stand.updateFields({ ...data })
          toast.success(`Стойка ${stand.name} успешно заблокирована`)
        } catch (e) {
          toast.error(`${e}`)
        }
      }}
      onUnBlock={async () => {
        try {
          const data = await standsStore.onUnBlockStandById(stand.id)
          stand.updateFields({ ...data })
          toast.success(`Стойка ${stand.name} успешно разблокирована`)
        } catch (e) {
          toast.error(`${e}`)
        }
      }}>
      <StandData
        stand={stand}
        pickFields={[
          'id',
          'name',
          'typeGame',
          'status',
          'fileQuickGamePreview',
          'fileLobbyPreview',
          'streamName',
          'streamId',
        ]}
        disabledAll={!isEdit}
      />
    </WrapDrawerEditForm>
  )
}

export default observer(StandEdit)
