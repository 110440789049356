import { ECurrency } from '@lbs/currency'
import { EPlayerStatus } from '@lbs/status'
import http from '../../services/http'

type OperatorTransaction = {
  createdAt: Date
  amount: string
  operatorTransaction: {
    login: string
  }
}

export interface IPlayerV2 {
  id: string
  login: string
  status: EPlayerStatus
  balance: number
  currency: ECurrency
  lastOperatorRefillTransaction: OperatorTransaction | null
  lastOperatorWithdrawalTransaction: OperatorTransaction | null
  lastGameTransaction: {
    createdAt: Date
    amount: string
  } | null
  rouletteRatio: number
  sumDeposits: number
}

class PlayersV2 {
  prefix: string = '/api/v2/player'

  getPlayers = async (payload: {
    limit: number
    offset: number
  }): Promise<{
    rows: IPlayerV2[]
    count: number
  }> => {
    const response = await http.get(`${this.prefix}`, {
      params: {
        limit: payload.limit,
        offset: payload.offset,
      },
    })

    return response.data
  }
}

export default new PlayersV2()
