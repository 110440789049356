import { FC, ReactNode } from 'react'
import { observer } from 'mobx-react-lite'

interface DealerLayoutProps {
  children: ReactNode
}

const DealerLayout: FC<DealerLayoutProps> = observer(({ children }) => <div>{children}</div>)

export default DealerLayout
