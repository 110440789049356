import { FC } from 'react'
import { css, Global } from '@emotion/react'

const GlobalStyles: FC = () => {
  return (
    <Global
      styles={css`
        html,
        body,
        #root {
          width: 100%;
          height: 100%;
          min-height: 100%;
        }

        img {
          display: block;
        }
      `}
    />
  )
}

export default GlobalStyles
