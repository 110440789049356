import http from '../../services/http'

export interface IRecordingUser {
  id: string
  login: string
}

export interface IRecording {
  id: string
  startRecordTimestamp: number
  endRecordTimestamp: number
  user: IRecordingUser
  sessionId: number
  createdAt: string
}

export interface IRecordingWithEvents extends IRecording {
  events: Array<any>
}

export interface IRecordings {
  totalRows: number
  rows: IRecording[]
}

class Recordings {
  prefix: string = '/api/recordings'

  getRecordings = async (params: {
    limit?: number
    offset?: number
    sessionId?: number
    userLogin?: string
  }): Promise<IRecordings> => {
    const response = await http.get(`${this.prefix}`, {
      params: { ...params },
    })

    return response.data
  }

  getUsersInRecordings = async (): Promise<Array<IRecordingUser>> => {
    const response = await http.get(`${this.prefix}/users`)

    return response.data
  }

  getSessionIdsInRecordings = async (): Promise<Array<number>> => {
    const response = await http.get(`${this.prefix}/sessionIds`)

    return response.data
  }

  getRecordingById = async (id: string): Promise<IRecordingWithEvents> => {
    const response = await http.get(`${this.prefix}/${id}`)

    return response.data
  }

  getRecordingByPlayerIdBySessionId = async (payload: {
    playerId: string
    sessionId: number
  }): Promise<IRecording | null> => {
    const response = await http.get(`${this.prefix}/user/${payload.playerId}/session/${payload.sessionId}`)

    return response.data
  }
}

export default new Recordings()
