import { FC } from 'react'
import { DateRange } from 'react-day-picker'
import { DateRangePicker } from '../index'

const MRTDateRangeFilter: FC<{
  column: {
    getFilterValue: () => DateRange | undefined
    setFilterValue: (date: DateRange | undefined) => void
  }
}> = ({ column }) => {
  const valueFilter = column.getFilterValue() as DateRange | undefined
  const fromDate = valueFilter?.from ? new Date(valueFilter.from) : undefined
  const toDate = valueFilter?.to ? new Date(valueFilter.to) : undefined

  return (
    <DateRangePicker
      from={fromDate}
      to={toDate}
      onChangeRange={(date) => {
        column.setFilterValue(date)
      }}
    />
  )
}

export default MRTDateRangeFilter
