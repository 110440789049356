import { makeAutoObservable } from 'mobx'
import { io } from 'socket.io-client'
import { Socket } from 'socket.io-client/build/esm/socket'
import { toast } from 'react-toastify'
import { TRouletteLastBall } from '../api/game'
import { EColorLastBalls } from '../constants/colorLastBalls'
import { EGameStatus } from '../constants/game.status'
import { ESocketEvents } from '../constants/socketEvents'
import { ERouletteGameStatus } from '../constants/rouletteGameStatus'
import { colorNumberRoulette } from '../utils/is'
import { loginStore } from './index'

export interface ILastBall {
  value: TRouletteLastBall
  color: EColorLastBalls
}

const MSG = {
  GAME_FINISHED: 'Игра закончена',
  PLAYERS_IN_GAME: 'Вы не может закончить игру, т.к. ней есть игроки',
  START_GAME_FAILED: 'Игра не может быть запущена.',
  DISCONNECT_SOCKET: 'Разрыв соединения',
  CONNECTED_SOCKET: 'Оператор успешно подлючен к игре',
}

export type TPlayer = {
  id: string
  name: string
}

export default class ManageGameRouletteStore {
  gameId: string = ''

  operatorId: string = ''

  chat: any[] = []

  players: Set<TPlayer> = new Set()

  lastBalls: ILastBall[] = []

  status: EGameStatus | null = null

  gamePlayStatus: ERouletteGameStatus | null | undefined = undefined

  socket: Socket | null = null

  isConnectedSocket: boolean = false

  remainingTime: number = 0

  limitMin: number = 0

  limitMax: number = 0

  accessToken: string = ''

  constructor(data: { gameId: string; operatorId: string }) {
    makeAutoObservable(this)

    this.gameId = data.gameId
    this.operatorId = data.operatorId
  }

  get isNotStartedGame(): boolean {
    return this.status === EGameStatus.CONFIRMED
  }

  get isSpin(): boolean {
    return this.gamePlayStatus === ERouletteGameStatus.SPIN
  }

  get isTimer(): boolean {
    return this.remainingTime > 1 && this.gamePlayStatus === ERouletteGameStatus.PREPARING
  }

  connectIo = (payload: { gameId: string; accessToken: string }): void => {
    this.accessToken = payload.accessToken

    this.socket = io(`${process.env.REACT_APP_API_URL}/operator`, {
      query: {
        game: payload.gameId,
        role: 'operator',
        accessToken: payload.accessToken,
      },
      secure: true,
      transports: ['websocket'],
      forceNew: true,
    })

    this.socket.on(ESocketEvents.START_GAME, (data: { status: number }) => {
      if (data.status !== 200)
        toast('Failed start game', {
          type: 'error',
        })
      if (data.status === 200) {
        toast('Игра успешно запустилась', {
          type: 'success',
        })
        this.changeGamePlayStatus(ERouletteGameStatus.START)
      }
    })
    this.socket.on(ESocketEvents.STOP_GAME, () => {})
    this.socket.on(ESocketEvents.SESSION_NEW, (data: { status: number }) => {
      this.changeGamePlayStatus(ERouletteGameStatus.PREPARING)

      if (this.accessToken !== loginStore.accessToken) {
        this.connectIo({
          gameId: this.gameId,
          accessToken: loginStore.accessToken,
        })
      }

      if (data.status !== 200) {
        toast('Игра не может быть запущена', {
          type: 'error',
        })
      }
    })
    this.socket.on(ESocketEvents.SESSION_END, () => {
      this.changeGamePlayStatus(ERouletteGameStatus.END)

      if (this.accessToken !== loginStore.accessToken) {
        this.connectIo({
          gameId: this.gameId,
          accessToken: loginStore.accessToken,
        })
      }
    })
    this.socket.on(ESocketEvents.SESSION_STATUS, (data: { status: ERouletteGameStatus | null }) => {
      this.changeGamePlayStatus(data.status)
    })
    this.socket.on(ESocketEvents.SPIN, (data: { status: number }) => {
      if (data.status !== 200) return
      this.changeGamePlayStatus(ERouletteGameStatus.SPIN)
      this.changeRemainingTime(0)

      toast('Крутите рулетку', {
        type: 'info',
      })
    })
    this.socket.on(ESocketEvents.NO_SPIN, (data: { status: number }) => {
      if (data.status === 200) {
        toast('Ставки успешно отменены', {
          type: 'success',
        })

        this.addLastBall({
          value: 'no spin',
          color: EColorLastBalls.NO_SPIN,
        })
      }
    })
    this.socket.on(ESocketEvents.SELECT_NUMBER_ROULETTE, (data: { status: number; ball: number }) => {
      if (data.status === 200) {
        this.addLastBall({
          value: data.ball,
          color: colorNumberRoulette(data.ball),
        })
        toast(`Выпало ${data.ball}`, {
          type: 'success',
        })
      }
    })
    this.socket.on(ESocketEvents.RESULT, () => {
      toast('Все игроки получили свои результаты.', {
        type: 'success',
      })
    })
    this.socket.on(ESocketEvents.PAUSE, () => {})
    this.socket.on(ESocketEvents.TIMER, (data: { remainingTime: number }) => {
      this.changeRemainingTime(data.remainingTime)
    })
    this.socket.on(ESocketEvents.CONNECTED, () => {
      toast(MSG.CONNECTED_SOCKET, {
        type: 'success',
      })

      this.setIsConnectedSocket(true)
    })
    this.socket.on(ESocketEvents.DISCONNECT, () => {
      toast(MSG.DISCONNECT_SOCKET, {
        type: 'warning',
      })

      this.setIsConnectedSocket(false)
    })
    this.socket.on(ESocketEvents.GAME_ERROR, () => {})
    this.socket.on(ESocketEvents.CONNECT_ERROR, () => {
      this.setIsConnectedSocket(false)
    })
    this.socket.on(ESocketEvents.NEW_OBSERVER, (data: { id: string; name: string; status: number }) => {
      if (data.status === 200) {
        toast(`Игрок ${data.name} присоединился к игре!`)
        this.players.add({
          id: data.id,
          name: data.name,
        })
      }
    })
    this.socket.on(ESocketEvents.REMOVE_PLAYER, () => {})
    this.socket.on(ESocketEvents.LIST_PLAYERS, () => {})
  }

  destroySocket = (): void => {
    this.socket?.removeAllListeners()
    this.socket?.disconnect()

    this.socket = null

    this.isConnectedSocket = false
  }

  setLastBalls = (data: ILastBall[]): void => {
    this.lastBalls = [...data]
  }

  addLastBall = (data: ILastBall): void => {
    this.lastBalls.unshift(data)

    if (this.lastBalls.length > 10) {
      this.lastBalls.length = 10
    }
  }

  setStatus = (data: EGameStatus): void => {
    this.status = data
  }

  onStartGame = (): void => {
    this.status = EGameStatus.STARTED

    this.socket?.emit(ESocketEvents.START_GAME, {
      operatorId: this.operatorId,
      gameId: this.gameId,
    })
  }

  onSelectNumber = (data: TRouletteLastBall): void => {
    if (this.gamePlayStatus === ERouletteGameStatus.SPIN) {
      this.socket?.emit(ESocketEvents.SELECT_NUMBER_ROULETTE, data)

      toast(`Выбрано число: ${data}`, {
        type: 'info',
      })
    } else {
      toast('Действие недоступно', {
        type: 'warning',
      })
    }
  }

  onNoSpin = (): void => {
    this.socket?.emit(ESocketEvents.NO_SPIN, {
      gameId: this.gameId,
    })
  }

  changeGamePlayStatus = (data: ERouletteGameStatus | null): void => {
    this.gamePlayStatus = data
  }

  changeRemainingTime = (data: number): void => {
    this.remainingTime = data
  }

  changeLimitMin = (data: number): void => {
    this.limitMin = data
  }

  changeLimitMax = (data: number): void => {
    this.limitMax = data
  }

  setIsConnectedSocket = (data: boolean): void => {
    this.isConnectedSocket = data
  }
}
