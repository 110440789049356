import { FC } from 'react'
import { Drawer, Toolbar } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { SideAdminMenu } from '../index'
import { useStore } from '../../stores'

const DrawerAdmin: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { drawerMainNavStore } = useStore()
  return (
    <Drawer
      variant="permanent"
      PaperProps={{
        sx: {
          width: drawerMainNavStore.view,
        },
      }}>
      <Toolbar />
      <SideAdminMenu isFull={drawerMainNavStore.isFullView} />
    </Drawer>
  )
}

export default observer(DrawerAdmin)
