import { FC, useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { EGameName, gameNames } from '../../constants/gameNames'

const options = [
  {
    value: EGameName.ROULETTE,
    label: gameNames[EGameName.ROULETTE],
  },
  {
    value: EGameName.TEXAS_POKER,
    label: gameNames[EGameName.TEXAS_POKER],
  },
  {
    value: EGameName.RUSSIAN_POKER,
    label: gameNames[EGameName.RUSSIAN_POKER],
  },
]

type SelectGameProps = {
  onChange?: (value: EGameName) => void
}

const SelectGame: FC<SelectGameProps> = ({ onChange }) => {
  const [value, setValue] = useState(options[0].value)

  return (
    <FormControl
      size="small"
      sx={{
        minWidth: 140,
      }}>
      <InputLabel id="select-game-label">Тип игры</InputLabel>
      <Select
        value={value}
        onChange={(e) => {
          const newVal = e.target.value as EGameName

          setValue(newVal)
          if (onChange) onChange(newVal)
        }}
        labelId="select-game-label"
        label="игры">
        {options.map((item) => {
          return (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default SelectGame
