import { FC } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { toast } from 'react-toastify'
import { WrapDrawerCreateForm } from '../index'
import { EGameName } from '../../constants/gameNames'
import LimitStore from '../../stores/Limit.store'
import { ELimitStatus } from '../../constants/limit.status'
import { limitsStore, useStore } from '../../stores'
import LimitData from '../LimitData'
import { EDrawerView } from '../../stores/DrawerCRUD.store'

export const pickFieldsCard: Array<keyof LimitStore> = ['maximumPayout']
export const pickFieldsRoulette: Array<keyof LimitStore> = [
  'minBetEvenChance',
  'maxBetEvenChance',
  'minBetColDozen',
  'maxBetColDozen',
]

export const pickFieldsLimit = (isCard?: boolean, isRoulette?: boolean): Array<keyof LimitStore> => {
  return [
    'name',
    'nameGame',
    'minimum',
    'maximum',
    ...(isCard ? pickFieldsCard : []),
    ...(isRoulette ? pickFieldsRoulette : []),
  ]
}

const LimitCreate: FC = observer(() => {
  const { drawerCRUDStore } = useStore()
  const limit = useLocalObservable(() => {
    return new LimitStore({
      id: '',
      minimum: 1,
      maximum: 2,
      name: 'Новый лимит',
      nameGame: EGameName.ROULETTE,
      status: ELimitStatus.ACCEPTED,
      updatedAt: '',
      assignGame: false,
      createdAt: '',
      maximumPayout: 1,
      minBetEvenChance: 1,
      maxBetColDozen: 1,
      minBetColDozen: 1,
      maxBetEvenChance: 1,
    })
  })

  const pickFields = pickFieldsLimit(limit.isCardGame, limit.isRouletteGame)

  return (
    <WrapDrawerCreateForm
      title="Создание нового лимита"
      onSave={async () => {
        try {
          const {
            name,
            nameGame,
            minimum,
            maximum,
            maximumPayout,
            minBetEvenChance,
            maxBetEvenChance,
            minBetColDozen,
            maxBetColDozen,
          } = limit
          const response = await limitsStore.onCreate({
            name,
            nameGame,
            minimum,
            maximum,
            ...((limit.isRouletteGame && {
              minBetEvenChance,
              maxBetEvenChance,
              minBetColDozen,
              maxBetColDozen,
            }) ||
              (limit.isCardGame && {
                maximumPayout,
              })),
          })
          toast.success('Лимит успешно создан')

          drawerCRUDStore.changeView(EDrawerView.LIMIT_EDIT, response.id)
        } catch (e) {
          toast.error(`${e}`)
        }
      }}>
      <LimitData limit={limit} pickFields={pickFields} />
    </WrapDrawerCreateForm>
  )
})

export default LimitCreate
