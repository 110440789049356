export enum EGameVisibility {
  PUBLIC = 'public',
  PRIVATE = 'private',
}

export const gameVisibility: {
  [key: string]: string
} = {
  [EGameVisibility.PUBLIC]: 'Открытая',
  [EGameVisibility.PRIVATE]: 'Закрытая',
}

export const gameVisibilityOptions = Object.keys(gameVisibility).map((item) => {
  return {
    value: item,
    label: gameVisibility[item],
  }
})
