import { FC, useEffect, useState } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { toast } from 'react-toastify'
import { OperatorData, WrapDrawerEditForm, ChangePasswordBtn, Dialog } from '../index'
import { useStore } from '../../stores'
import OperatorStore from '../../stores/Operator.store'
import Operators from '../../api/operators'

type OperatorEditProps = {
  operatorId: string
}

const OperatorEdit: FC<OperatorEditProps> = observer(({ operatorId }) => {
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [passwordDialog, setPasswordDialog] = useState(false)
  const { operatorsStore, drawerCRUDStore } = useStore()
  const operator = useLocalObservable(() => {
    return new OperatorStore(null)
  })

  useEffect(() => {
    ;(async () => {
      const data = await operatorsStore.getOperatorById(operatorId)

      operator.updateFields({
        ...data,
      })
    })()
    return () => {
      ;(async () => {
        await operatorsStore.getOperators()
      })()
    }
  }, [])

  return (
    <>
      <WrapDrawerEditForm
        title={`Просмотр оператора ${operator.login}`}
        busyText=""
        isBusy={false}
        isShowEditBtn={!isEdit}
        isShowSaveBtn={isEdit}
        isShowBlockBtn={!operator.isBlockStatus}
        isShowUnBlockBtn={operator.isBlockStatus}
        onEdit={() => {
          setIsEdit(true)
        }}
        onSave={async () => {
          try {
            await operator.onUpdateFields(operator.id)
            toast.success(`Оператор ${operator.login} успешно отредактирован`)

            const data = await operatorsStore.getOperatorById(operatorId)

            operator.updateFields({
              ...data,
            })

            setIsEdit(false)
          } catch (e) {
            toast.error(`${e}`)
          }
        }}
        onDelete={async () => {
          try {
            await operator.onDelete(operator.id)

            toast.success(`Оператор ${operator.login} успешно удален`)

            drawerCRUDStore.resetView()
          } catch (e) {
            toast.error(`${e}`)
          }
        }}
        onBlock={async () => {
          try {
            await operator.onBlockStatusOperator(operator.id)
            toast.success(`Оператор ${operator.login} успешно заблокирован`)
          } catch (e) {
            toast.error(`${e}`)
          } finally {
            const data = await operatorsStore.getOperatorById(operatorId)

            operator.updateFields({
              ...data,
            })
          }
        }}
        onUnBlock={async () => {
          try {
            await operator.onUnBlockStatusOperator(operator.id)
            toast.success(`Оператор ${operator.login} успешно разблокирован`)
          } catch (e) {
            toast.error(`${e}`)
          } finally {
            const data = await operatorsStore.getOperatorById(operatorId)

            operator.updateFields({
              ...data,
            })
          }
        }}
        additionalButtons={
          <ChangePasswordBtn
            onClick={() => {
              setPasswordDialog(true)
            }}
          />
        }>
        <OperatorData
          operator={operator}
          pickFields={['login', 'firstName', 'lastName', 'role', 'status']}
          disabledFields={!isEdit}
        />
      </WrapDrawerEditForm>

      <Dialog
        open={passwordDialog}
        title={`Изменения пароля для ${operator.login}`}
        onClose={() => {
          setPasswordDialog(false)
        }}
        onSave={async () => {
          try {
            await Operators.updatePasswordOperator(operatorId, {
              newPassword: operator.confirmPassword,
            })

            toast.success(`Пароль успешно изменен`)
          } catch (e) {
            toast.error(`${e}`)
          } finally {
            setPasswordDialog(false)
          }
        }}>
        <OperatorData operator={operator} pickFields={['password', 'confirmPassword']} />
      </Dialog>
    </>
  )
})

export default OperatorEdit
