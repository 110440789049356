import { makeAutoObservable, runInAction } from 'mobx'
import security, { ILoginOperatorResponse, ILoginPayload, ILoginResponse } from '../api/security'
import storage, { STORAGE_KEYS } from '../services/storage'
import { ERole } from '../constants/role'

interface ITokenData {
  accessToken: ILoginResponse['access_token']
  refreshToken: ILoginResponse['refresh_token']
  tokenLife: ILoginResponse['token_life']
}

export class LoginStore {
  accessToken: string = ''

  refreshToken: string = ''

  operator: ILoginOperatorResponse | null = null

  tokenLife: number = 0

  constructor() {
    makeAutoObservable(this)

    if (!storage.has(STORAGE_KEYS.TOKEN)) return

    const tokenData = storage.get(STORAGE_KEYS.TOKEN) as ITokenData

    this.setToken(tokenData)

    if (!storage.has(STORAGE_KEYS.OPERATOR)) return

    const operatorData = storage.get(STORAGE_KEYS.OPERATOR) as ILoginOperatorResponse

    this.setOperator(operatorData)
  }

  get isAuthenticated(): boolean {
    return Boolean(this.operator)
  }

  get role(): ERole {
    return this.operator?.role || ERole.UNKNOWN
  }

  get isRoleAM(): boolean {
    // AM = ADMINISTRATOR, MANAGER
    return [ERole.ADMINISTRATOR, ERole.MANAGER].includes(this.role)
  }

  get isAdministrator(): boolean {
    return this.role === ERole.ADMINISTRATOR
  }

  get isDealer(): boolean {
    return this.role === ERole.DEALER
  }

  login = async (data: ILoginPayload): Promise<void> => {
    try {
      const response = await security.login(data)
      // eslint-disable-next-line camelcase
      const { access_token, refresh_token, token_life, operator } = response

      const tokenData: ITokenData = {
        // eslint-disable-next-line camelcase
        accessToken: access_token,
        // eslint-disable-next-line camelcase
        refreshToken: refresh_token,
        // eslint-disable-next-line camelcase
        tokenLife: token_life,
      }
      const operatorData: ILoginOperatorResponse = { ...operator }

      runInAction(() => {
        this.setToken(tokenData)
        this.setOperator(operatorData)

        storage.set(STORAGE_KEYS.TOKEN, tokenData)

        storage.set(STORAGE_KEYS.OPERATOR, operatorData)
      })
    } catch (err) {
      throw new Error(`Failed login ${err}`)
    }
  }

  logout = async (): Promise<void> => {
    const { refreshToken } = this
    const token = refreshToken

    this.clear()

    await security.logout({
      token,
    })
  }

  refreshTokens = async (): Promise<void> => {
    const localTokenData = storage.get(STORAGE_KEYS.TOKEN) as ITokenData

    const response = await security.refreshToken({
      refreshToken: localTokenData.refreshToken,
    })

    // eslint-disable-next-line camelcase
    const { access_token, refresh_token, token_life } = response

    const tokenData: ITokenData = {
      // eslint-disable-next-line camelcase
      accessToken: access_token,
      // eslint-disable-next-line camelcase
      refreshToken: refresh_token,
      // eslint-disable-next-line camelcase
      tokenLife: token_life,
    }

    runInAction(() => {
      this.setToken(tokenData)

      storage.set(STORAGE_KEYS.TOKEN, tokenData)
    })
  }

  clear = (): void => {
    storage.remove(STORAGE_KEYS.TOKEN)
    storage.remove(STORAGE_KEYS.OPERATOR)

    this.setToken({
      accessToken: '',
      refreshToken: '',
      tokenLife: 0,
    })
    this.setOperator(null)
  }

  setToken = (data: ITokenData): void => {
    const { accessToken, tokenLife, refreshToken } = data

    this.accessToken = accessToken
    this.refreshToken = refreshToken
    this.tokenLife = tokenLife
  }

  setOperator = (data: ILoginOperatorResponse | null): void => {
    this.operator = data
  }
}
