import { FC } from 'react'
import { FormControl, InputLabel, MenuItem, Select as MuiSelect, TextFieldProps } from '@mui/material'

export type OptionValue = number | string

export type Option<Type extends OptionValue> = {
  value: Type
  label: string
}

type SelectProps<Type extends OptionValue> = {
  id: string
  label: string
  value: OptionValue
  options: Option<Type>[]
  // defaultValue?: OptionValue
  onChange: (value: Type) => void
  variant?: TextFieldProps['variant']
  size?: TextFieldProps['size']
  fullWidth?: TextFieldProps['fullWidth']
  disabled?: TextFieldProps['disabled']
}

const Select: FC<SelectProps<OptionValue>> = ({
  value,
  id,
  label,
  options,
  onChange,
  variant = 'standard',
  size = 'small',
  fullWidth,
  disabled,
}) => {
  // const [value, setValue] = useState<OptionValue>(defaultValue || options[0].value)

  return (
    <FormControl fullWidth={fullWidth} disabled={disabled} variant={variant} size={size}>
      <InputLabel id={`select-${id}`}>{label}</InputLabel>

      <MuiSelect
        sx={{
          minWidth: '120px',
        }}
        value={value}
        onChange={(e) => {
          const newVal = e.target.value as OptionValue

          onChange(newVal)
        }}
        labelId={`select-${id}`}
        label={label}>
        {options.map((item) => {
          return (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          )
        })}
      </MuiSelect>
    </FormControl>
  )
}

export default Select
