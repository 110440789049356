import { ChangeEvent, FC, MouseEvent, useState } from 'react'
import { DateRange, DayPicker } from 'react-day-picker'
// eslint-disable-next-line import/no-duplicates
import { ru } from 'date-fns/locale'
import { Box, Divider, Menu, Stack, TextField, Typography } from '@mui/material'
import TodayIcon from '@mui/icons-material/Today'
import EventIcon from '@mui/icons-material/Event'
// eslint-disable-next-line import/no-duplicates
import { endOfDay, format, startOfDay } from 'date-fns'
import { EDateFormat } from '../../utils/date'

type DateRangePickerProps = {
  from?: Date
  to?: Date
  previewIcons?: boolean
  onChangeRange: (data: DateRange | undefined) => void
}

const DateRangePicker: FC<DateRangePickerProps> = ({ from, to, previewIcons, onChangeRange }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: MouseEvent<HTMLDivElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const handleTimeChange = (e: ChangeEvent<HTMLInputElement>, isFrom: boolean): void => {
    const [hours, minutes] = e.target.value.split(':').map(Number)

    if (isFrom && from) {
      const updatedFrom = new Date(from.getFullYear(), from.getMonth(), from.getDate(), hours, minutes)
      onChangeRange({ from: updatedFrom, to })
    } else if (!isFrom && to) {
      const updatedTo = new Date(to.getFullYear(), to.getMonth(), to.getDate(), hours, minutes)
      onChangeRange({ from, to: updatedTo })
    }
  }

  const dateFrom = from ? format(from, EDateFormat.dd_MM_yyy) : '-'
  const dateTo = to ? format(to, EDateFormat.dd_MM_yyy) : '-'

  const timeFrom = from ? format(from, EDateFormat.HH_mm) : '00:00'
  const timeTo = to ? format(to, EDateFormat.HH_mm) : '23:59'

  const footer = (
    <div id="time">
      <Stack flexDirection="row" gap={1}>
        <Typography variant="body1">От:</Typography>
        <Box>
          <input type="time" value={timeFrom} onChange={(e) => handleTimeChange(e, true)} />
        </Box>
        <Box>{dateFrom}</Box>
      </Stack>
      <Divider
        sx={{
          mt: 1,
          mb: 1,
        }}
      />
      <Stack flexDirection="row" gap={1}>
        <Typography variant="body1">До:</Typography>
        <Box>
          <input type="time" value={timeTo} onChange={(e) => handleTimeChange(e, false)} />
        </Box>
        <Box>{dateTo}</Box>
      </Stack>
    </div>
  )

  const start = previewIcons ? (
    <TodayIcon
      color="info"
      sx={{
        mt: 1,
        mb: 1,
      }}
    />
  ) : (
    <TextField
      size="small"
      value={dateFrom}
      label="От"
      variant="standard"
      inputProps={{
        readOnly: true,
      }}
      sx={{
        width: '90px',
      }}
    />
  )

  const end = previewIcons ? (
    <EventIcon
      color="info"
      sx={{
        mt: 1,
        mb: 1,
      }}
    />
  ) : (
    <TextField
      size="small"
      label="До"
      variant="standard"
      value={dateTo}
      inputProps={{
        readOnly: true,
      }}
      sx={{
        width: '90px',
      }}
    />
  )

  return (
    <>
      <Box
        onClick={handleClick}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          cursor: 'pointer',
        }}>
        {start}
        &nbsp;&nbsp;
        {end}
      </Box>
      <Menu id="dateRangePicker" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <DayPicker
          locale={ru}
          mode="range"
          defaultMonth={from}
          fixedWeeks
          selected={{
            from,
            to,
          }}
          onSelect={(dateSelect) => {
            if (!dateSelect) return onChangeRange(undefined)

            return onChangeRange({
              from: dateSelect?.from ? startOfDay(dateSelect?.from) : undefined,
              to: dateSelect?.to ? endOfDay(dateSelect.to) : undefined,
            })
          }}
          footer={footer}
        />
      </Menu>
    </>
  )
}

export default DateRangePicker
