import { observer } from 'mobx-react-lite'
import { FC, useEffect, useMemo, useState } from 'react'
import { Box, Chip, IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import TableBarIcon from '@mui/icons-material/TableBar'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import SportsEsportsIcon from '@mui/icons-material/SportsEsports'
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_VisibilityState,
} from 'material-react-table'
import { MRT_Localization_RU } from 'material-react-table/locales/ru'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useQuery } from 'react-query'
import ShieldIcon from '@mui/icons-material/Shield'
import { useLocalStorage } from 'react-use'
import { useStore } from '../../stores'
import { FilterBar } from '../../components'
import { EGameStatus, gameStatusesOptions, gameStatusName } from '../../constants/game.status'
import { EGameVisibility, gameVisibility } from '../../constants/gameVisibility'
import { EGameName, gameNames } from '../../constants/gameNames'
import { EDrawerView } from '../../stores/DrawerCRUD.store'
import { EDateFormat, formatDateFallback, getCurrentDay, getDateFormat } from '../../utils/date'
import routes from '../../router/routes'
import ApiGames, { IGame } from '../../api/games'
import { ELocalstorageKeys } from '../../constants/localstorageKeys'

const Games: FC = observer(() => {
  const navigate = useNavigate()
  const { drawerCRUDStore, loginStore } = useStore()
  const [columnVisibilityLocalStorage, setColumnVisibilityLocalStorage] = useLocalStorage<MRT_VisibilityState>(
    ELocalstorageKeys.TABLE_GAMES_COLUMN_VISIBILITY,
    {}
  )
  const [columnFiltersLocalStorage, setColumnFiltersLocalStorage] = useLocalStorage<MRT_ColumnFiltersState>(
    ELocalstorageKeys.TABLE_GAMES_COLUMN_FILTERS,
    []
  )
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    columnFiltersLocalStorage as MRT_ColumnFiltersState
  )
  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(
    columnVisibilityLocalStorage as MRT_VisibilityState
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams({})

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 15,
  })

  const {
    data: games,
    isError,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    ['getGames list', pagination.pageIndex, pagination.pageSize, columnFilters],
    () => {
      const filters = columnFilters.reduce((acc, item) => {
        acc[item.id] = String(item.value)
        return acc
      }, {} as Record<string, string>)

      setSearchParams(filters)

      return ApiGames.getGames({
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,

        ...filters,
      })
    },
    {
      select: (data) => data,
      onError: (err) => toast.error(`${err}`),
      enabled: true,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )

  useEffect(() => {
    if (!drawerCRUDStore.isOpen) refetch()
  }, [drawerCRUDStore.isOpen])

  useEffect(() => {
    setColumnVisibilityLocalStorage(columnVisibility)
  }, [columnVisibility])

  useEffect(() => {
    setColumnFiltersLocalStorage(columnFilters)
  }, [columnFilters])

  const columns = useMemo<MRT_ColumnDef<IGame>[]>(
    () => [
      {
        accessorFn: (row) => row?.gameSettings?.game_name,
        id: 'gameName',
        header: 'Игра',
        enableColumnFilter: false,
        Cell: ({ cell, row }) => {
          return (
            <Chip
              color="info"
              variant="outlined"
              icon={<SportsEsportsIcon />}
              label={gameNames[cell.getValue<EGameName>()]}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()

                const gameId = row.original.id

                if (!gameId) return

                drawerCRUDStore.changeView(EDrawerView.GAME_EDIT, gameId)
              }}
            />
          )
        },
      },
      {
        accessorKey: 'status',
        header: 'Статус',
        Cell: ({ cell }) => {
          let color: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
          switch (cell.getValue<EGameStatus>()) {
            case EGameStatus.STARTED:
              color = 'success'
              break
            case EGameStatus.CONFIRMED:
              color = 'warning'
              break
            default:
              color = 'default'
          }
          return <Chip color={color} label={gameStatusName(cell.getValue<EGameStatus>())} />
        },
        filterVariant: 'select',
        filterSelectOptions: gameStatusesOptions.map((item) => ({ text: item.label, value: item.value })),
      },
      {
        accessorFn: (row) => {
          return row?.gameSettings?.limit?.name || ''
        },
        id: 'limit',
        header: 'Лимит',
        enableColumnFilter: false,
        Cell: ({ cell, row }) => {
          return (
            <Chip
              disabled={loginStore.isDealer}
              icon={<LowPriorityIcon />}
              color="default"
              label={cell.getValue<string>()}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                const limitId = row.original.gameSettings.limit?.id

                if (!limitId) return

                drawerCRUDStore.changeView(EDrawerView.LIMIT_EDIT, limitId)
              }}
            />
          )
        },
      },
      {
        accessorFn: (row) => row?.gameSettings?.stand?.name || '',
        id: 'stand',
        header: 'Стойка',
        enableColumnFilter: false,
        Cell: ({ cell, row }) => {
          return (
            <Chip
              disabled={loginStore.isDealer}
              icon={<TableBarIcon />}
              color="default"
              label={cell.getValue<string>()}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                const standId = row.original.gameSettings?.stand?.id

                if (!standId) return

                drawerCRUDStore.changeView(EDrawerView.STAND_EDIT, standId)
              }}
            />
          )
        },
      },
      {
        id: 'dealerLogin',
        accessorFn: (row) => row.operator?.login,
        header: 'Дилер',
        enableColumnFilter: false,
        Cell: ({ cell, row }) => {
          return (
            <Chip
              disabled={loginStore.isDealer}
              icon={<ShieldIcon />}
              color="default"
              label={cell.getValue<string>()}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()

                drawerCRUDStore.changeView(EDrawerView.OPERATOR_EDIT, row.original.operator?.id || '')
              }}
            />
          )
        },
      },
      {
        accessorFn: (row) => row?.gameSettings?.game_type,
        id: 'gameType',
        header: 'Тип',
        enableColumnFilter: false,
        Cell: ({ cell }) => {
          return gameVisibility[cell.getValue<EGameVisibility>()]
        },
      },
      {
        accessorKey: 'startsAt',
        header: 'Дата',
        enableColumnFilter: false,
        Cell: ({ cell }) => {
          return getDateFormat(new Date(cell.getValue<string>()))
        },
      },
    ],
    []
  )

  return (
    <Box>
      <FilterBar>
        <IconButton
          onClick={(e) => {
            e.preventDefault()

            drawerCRUDStore.changeView(EDrawerView.GAME_CREATE)
          }}>
          <AddIcon />
        </IconButton>
      </FilterBar>

      <MaterialReactTable
        localization={MRT_Localization_RU}
        enableFullScreenToggle={false}
        enableGlobalFilter={false}
        enableFilterMatchHighlighting={false}
        initialState={{
          showColumnFilters: true,
          density: 'comfortable',
        }}
        state={{
          pagination,
          isLoading,
          showAlertBanner: isError,
          showProgressBars: isFetching,
          columnFilters,
          columnVisibility,
        }}
        manualFiltering
        onColumnFiltersChange={setColumnFilters}
        manualPagination
        onPaginationChange={setPagination}
        columns={columns}
        data={games?.rows || []}
        rowCount={games?.countAll ?? 0}
        onColumnVisibilityChange={setColumnVisibility}
        muiTableBodyRowProps={({ row }) => ({
          onClick: (event) => {
            event.preventDefault()

            if (loginStore.isDealer) {
              navigate(`${routes.manageGameRoulette}/${row.original.id}`)
            } else {
              const formatDate = EDateFormat.yyy_MM_dd_HH_mm

              const dateFrom = formatDateFallback(new Date(row.original.createdAt).toISOString(), formatDate)
              const dateTo = getCurrentDay(formatDate)

              navigate(`${routes.historyRoulette}/${row.original.id}?dateFrom=${dateFrom}&dateTo=${dateTo}`)
            }
          },
          sx: {
            cursor: 'pointer',
          },
        })}
      />
    </Box>
  )
})

export default Games
