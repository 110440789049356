import { addDays, addSeconds, endOfDay, format, isValid, parseISO } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'

export const dateFormatSql = 'yyy-MM-dd HH:mm:ss.SSSS'

export enum EDateFormat {
  HH_mm = 'HH:mm',
  HH_mm_ss = 'HH:mm:ss',
  dd_MM_yyy = 'dd-MM-yyy',
  dd_MM_yyy_HH_mm = 'dd-MM-yyy HH:mm',
  dd_MM_yyy_HH_mm_ss = 'dd-MM-yyy HH:mm:ss',
  yyy_MM_dd_HH_mm_ss = 'yyy-MM-dd HH:mm:ss',
  yyy_MM_dd_HH_mm = 'yyy-MM-dd HH:mm',
  yyy_MM_dd = 'yyy-MM-dd',
}

export const getDateFormat = (date: number | Date): string => format(date, EDateFormat.dd_MM_yyy_HH_mm_ss)
export const formatDateString = (dateString: string, dateFormat: EDateFormat = EDateFormat.yyy_MM_dd): string => {
  try {
    const date = parseISO(dateString)

    if (!isValid(date)) return '—'

    return format(date, dateFormat)
  } catch {
    return '—'
  }
}
export const formatDate = (date: Date | null, dateFormat: EDateFormat = EDateFormat.yyy_MM_dd): string => {
  if (!date) return '—'

  return format(date, dateFormat)
}
export const formatDateFallback = (
  dateString: string | undefined,
  dateFormat: EDateFormat = EDateFormat.yyy_MM_dd,
  offset: number = 0
): string => {
  if (!dateString) return format(addDays(endOfDay(new Date()), offset), dateFormat)

  const date = parseISO(dateString)

  if (!isValid(date)) return format(addDays(endOfDay(new Date()), offset), dateFormat)

  return format(date, dateFormat)
}

export const dateFallback = (date: Date | string | undefined | null): Date => {
  let resultDate: Date

  if (typeof date === 'string') {
    resultDate = new Date(date)
  } else if (date instanceof Date) {
    resultDate = date
  } else {
    resultDate = new Date()
  }

  if (!isValid(resultDate)) {
    resultDate = new Date()
  }

  return resultDate
}
export const getCurrentDay = (dateFormat: EDateFormat = EDateFormat.yyy_MM_dd): string => {
  return format(endOfDay(new Date()), dateFormat)
}
export const getYesterday = (dateFormat: EDateFormat = EDateFormat.yyy_MM_dd): string => {
  return format(addDays(endOfDay(new Date()), -1), dateFormat)
}
export const getTomorrow = (dateFormat: EDateFormat = EDateFormat.yyy_MM_dd): string => {
  return format(addDays(endOfDay(new Date()), 1), dateFormat)
}
export const getDateFormatSql = (date: number | Date): string => format(date, dateFormatSql)
export const getDateAddSeconds = (num: number): string =>
  format(addSeconds(new Date(), num), EDateFormat.dd_MM_yyy_HH_mm_ss)
export const getTimestampTokenExpired = (tokenLife: number): number => {
  const timestampToken = Date.now()
  return timestampToken + (tokenLife - 10) * 1000
}
export const moscowTimeZone = 'Europe/Moscow'

export const formatToMoscowTZ = (date: Date | string | number): string => {
  return formatInTimeZone(date, moscowTimeZone, EDateFormat.dd_MM_yyy_HH_mm)
}
