import { QueryOptions, useQuery, UseQueryResult } from 'react-query'
import { ApiPlayers } from '../../api'
import { IPlayer } from '../../api/players'

const useQueryPlayerById = (
  playerId: string,
  options?: QueryOptions<IPlayer, Error>
): UseQueryResult<IPlayer, Error> => {
  return useQuery(
    ['player', playerId],
    async () => {
      return ApiPlayers.getPlayerById(playerId)
    },
    { ...options, enabled: !!playerId, staleTime: 1000 * 60 * 15, refetchOnWindowFocus: false }
  )
}

export default useQueryPlayerById
