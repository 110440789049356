import store from 'store2'

export enum STORAGE_KEYS {
  TOKEN = 'token',
  OPERATOR = 'operator',
  TIME_AUTH = 'timeAuth',
  TIME_EXPIRED_TOKEN = 'timeExpiredToken',
}

const storage = store.namespace('dl')

export default storage
