import { FC, Fragment, ReactNode, useEffect, useState } from 'react'
import GameStore, { gameFields, IGameStore } from 'stores/Game.store'
import { TextField } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useMountedState } from 'react-use'
import { toast } from 'react-toastify'
import { formPropsDrawerCrud } from '../../constants/formPropsDrawerCrud'
import { EGameName, gameNamesOptions } from '../../constants/gameNames'
import { Select } from '../index'
import { EGameVisibility, gameVisibilityOptions } from '../../constants/gameVisibility'
import { EGameStatus, gameStatusesOptions } from '../../constants/game.status'
import { useStore } from '../../stores'

type GameDataProps = {
  game: GameStore
  disabledAll?: boolean
  pickFields: Array<keyof IGameStore>
}

const GameData: FC<GameDataProps> = ({ game, pickFields, disabledAll }) => {
  const { operatorsStore, standsStore, limitsStore } = useStore()
  const isMounted = useMountedState()
  const [loadingDealers, setLoadingDealers] = useState(true)
  const [loadingLimits, setLoadingLimits] = useState(true)
  const [loadingStands, setLoadingStands] = useState(true)
  const [dealers, setDealers] = useState<Array<{ value: string; label: string }>>([])
  const [limits, setLimits] = useState<Array<{ value: string; label: string }>>([])
  const [stands, setStands] = useState<Array<{ value: string; label: string }>>([])

  const fieldProps = {
    ...formPropsDrawerCrud,
    ...(disabledAll && { disabled: disabledAll }),
  }

  useEffect(() => {
    ;(async () => {
      try {
        const dealersList = await operatorsStore.getDealers()

        if (!isMounted()) return

        setDealers(
          dealersList.rows.map((item) => {
            return {
              value: item.id,
              label: item.login,
            }
          })
        )
      } catch (e) {
        toast.error(`${e}`)
      } finally {
        setLoadingDealers(false)
      }

      try {
        const limitsList = await limitsStore.getLimitsRoulette()

        if (!isMounted()) return

        setLimits(
          limitsList
            .filter((item) => !item.assignGame || game.limitId === item.id)
            .map((item) => {
              return {
                value: item.id,
                label: item.name,
              }
            })
        )
      } catch (e) {
        toast.error(`${e}`)
      } finally {
        setLoadingLimits(false)
      }

      try {
        const standList = await standsStore.geStandsForRoulette()

        if (!isMounted()) return

        const standOptions = standList
          .filter((item) => !item.assignGame || game.standId === item.id)
          .map((item) => {
            return {
              value: item.id,
              label: item.name,
            }
          })

        setStands(standOptions)
      } catch (e) {
        toast.error(`${e}`)
      } finally {
        setLoadingStands(false)
      }
    })()
  }, [])

  const fieldComponents: Partial<Record<keyof IGameStore, ReactNode>> = {
    id: (
      <TextField
        {...fieldProps}
        value={game.id}
        onChange={(e) => game.updateFields({ id: e.target.value })}
        label={gameFields.id}
      />
    ),
    gameName: (
      <Select
        id="select-game"
        options={gameNamesOptions}
        {...fieldProps}
        disabled
        value={game.gameName}
        onChange={(e) => game.updateFields({ gameName: e as EGameName })}
        label={gameFields.gameName}
      />
    ),
    gameType: (
      <Select
        id="select-type-game"
        options={gameVisibilityOptions}
        {...fieldProps}
        disabled
        value={game.gameType}
        onChange={(e) => game.updateFields({ gameType: e as EGameVisibility })}
        label={gameFields.gameType}
      />
    ),
    ipCyclops: (
      <TextField
        {...fieldProps}
        value={game.ipCyclops}
        onChange={(e) => game.updateFields({ ipCyclops: e.target.value })}
        label={gameFields.ipCyclops}
      />
    ),
    status: (
      <Select
        id="select-status"
        options={gameStatusesOptions}
        {...fieldProps}
        disabled
        value={game.status}
        onChange={(e) => game.updateFields({ status: e as EGameStatus })}
        label={gameFields.status}
      />
    ),
    timerToEndBets: (
      <TextField
        {...fieldProps}
        value={game.timerToEndBets}
        type="number"
        onChange={(e) => game.changeTimerToEndBets(Number(e.target.value))}
        label={gameFields.timerToEndBets}
      />
    ),
    timerTechPause: (
      <TextField
        {...fieldProps}
        value={game.timerTechPause}
        type="number"
        onChange={(e) => game.changeTimerTechPause(Number(e.target.value))}
        label={gameFields.timerTechPause}
      />
    ),
    timerToSpin: (
      <TextField
        {...fieldProps}
        value={game.timerToSpin}
        type="number"
        onChange={(e) => game.changeTimerToSpin(Number(e.target.value))}
        label={gameFields.timerToSpin}
      />
    ),
    dealerId: (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {!loadingDealers && (
          <Select
            {...fieldProps}
            id="select-dealer"
            label={gameFields.dealerId}
            value={game.dealerId}
            options={dealers}
            onChange={(e) => {
              game.updateFields({
                dealerId: e as string,
              })
            }}
          />
        )}
      </>
    ),
    limitId: (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {!loadingLimits && (
          <Select
            {...fieldProps}
            id="select-limit"
            label={gameFields.limitId}
            value={game.limitId}
            options={limits}
            onChange={(e) => {
              game.updateFields({
                limitId: e as string,
              })
            }}
          />
        )}
      </>
    ),
    standId: (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {!loadingStands && (
          <Select
            {...fieldProps}
            id="select-stand"
            label={gameFields.standId}
            value={game.standId}
            options={stands}
            onChange={(e) => {
              game.updateFields({
                standId: e as string,
              })
            }}
          />
        )}
      </>
    ),
  }
  return (
    <>
      {pickFields.map((item) => {
        return <Fragment key={item}>{fieldComponents[item]}</Fragment>
      })}
    </>
  )
}

export default observer(GameData)
