import { FC } from 'react'
import { Box, Typography } from '@mui/material'

const NoMatch: FC = () => {
  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <div>
        <Typography textAlign="center" variant="h2">
          404
        </Typography>
        <Typography textAlign="center" variant="h2">
          Страница не найдена
        </Typography>
      </div>
    </Box>
  )
}

export default NoMatch
