import { FC, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { FullScreenDialog } from '../index'

type FullScreenRouteDialogProps = {
  title: string
  children?: ReactNode
}

const FullScreenRouteDialog: FC<FullScreenRouteDialogProps> = (props) => {
  const { title, children } = props
  const navigate = useNavigate()

  const handleClose = (): void => {
    navigate(-1)
  }

  return (
    <FullScreenDialog open onClose={handleClose} title={title}>
      {children}
    </FullScreenDialog>
  )
}

export default FullScreenRouteDialog
