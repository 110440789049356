import { forwardRef } from 'react'
import { Box, Button, Typography } from '@mui/material'

type LoadMoreRowsProps = {
  rows: number | string
  totalRows: number | string
  onLoad: () => void
  isLoading?: boolean
}

const LoadMoreRows = forwardRef<HTMLDivElement, LoadMoreRowsProps>(({ totalRows, rows, isLoading, onLoad }, ref) => {
  return (
    <Box
      ref={ref}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
        p: 2,
      }}>
      <Typography fontSize={14} variant="caption" display="block" gutterBottom>
        Показано {rows} из {totalRows}
      </Typography>
      {isLoading && (
        <Typography fontSize={14} variant="caption" display="block" gutterBottom>
          Загружается...
        </Typography>
      )}
      {rows !== totalRows && (
        <Button
          variant="outlined"
          size="small"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()

            onLoad()
          }}>
          Показать еще
        </Button>
      )}
    </Box>
  )
})

export default LoadMoreRows
