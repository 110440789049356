import { FC } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../stores'
import { EDrawerView } from '../../stores/DrawerCRUD.store'

type ActionBtnProps = {
  view?: EDrawerView
  onClick?: () => void
}

const ActionBtn: FC<ActionBtnProps> = ({ view, onClick }) => {
  const { drawerCRUDStore } = useStore()
  return (
    <Tooltip title="Редактировать">
      <IconButton
        onClick={(e) => {
          e.preventDefault()

          if (view) drawerCRUDStore.changeView(view)
          if (onClick) onClick()
        }}>
        <MoreHorizIcon />
      </IconButton>
    </Tooltip>
  )
}

export default observer(ActionBtn)
