import http from '../../services/http'
import { EStandStatus } from '../../constants/stand.status'
import { EGameName } from '../../constants/gameNames'

export interface IStand {
  id: string // "6235-6700-cb47-d807",
  status: EStandStatus // 200,
  name: string // "for rul",
  typeGame: EGameName // "roulette",
  gameName?: EGameName // "roulette" Используется для обозначения если стойка за активной игрой // TODO: need fix
  data: { name?: string; streamId?: string; fileLobbyPreview?: string | null; fileQuickGamePreview?: string | null }
  grid: any // null,
  createdAt: string // "2023-06-19T21:42:31.469Z",
  updatedAt: string // "2023-06-19T21:42:31.469Z",
  assignGame: boolean // true
}

export interface IStands {
  count: number
  rows: Array<IStand>
}

export interface IStandCreate {
  name: string
  typeGame: EGameName
  fileLobbyPreview?: string
  fileQuickGamePreview?: string
  streamName?: string
  streamId?: string
}

class Stand {
  prefix: string = '/api/stand'

  getStands = async (): Promise<IStands> => {
    const response = await http.get(this.prefix)

    return response.data
  }

  getStandById = async (id: string): Promise<IStand> => {
    const response = await http.get(`${this.prefix}/${id}`)

    return response.data
  }

  updateStandById = async (idStand: string, payload: Partial<IStand>): Promise<IStand> => {
    const response = await http.put(`${this.prefix}/${idStand}`, {
      ...payload,
    })

    return response.data
  }

  createStand = async (payload: IStandCreate): Promise<IStand> => {
    const { name, typeGame, fileQuickGamePreview, fileLobbyPreview, streamName, streamId } = payload
    const response = await http.post(`${this.prefix}`, {
      name,
      typeGame,
      data: {
        fileLobbyPreview,
        fileQuickGamePreview,
        name: streamName,
        streamId,
      },
    })

    return response.data
  }

  deleteStand = async (id: string): Promise<void> => {
    await http.delete(`${this.prefix}/${id}`)
  }
}

export default new Stand()
