import { FC } from 'react'
import { ECurrency, symbolCurrency } from '@lbs/currency'

type CurrencyProps = {
  currency: ECurrency
}

const Currency: FC<CurrencyProps> = (props) => {
  const { currency } = props

  return <span>{symbolCurrency[currency]}</span>
}

export default Currency
