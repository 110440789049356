import { makeAutoObservable } from 'mobx'

export enum EDrawerView {
  UNKNOWN,
  STAND_CREATE,
  STAND_EDIT,
  LIMIT_CREATE,
  LIMIT_EDIT,
  PLAYER_CREATE,
  PLAYER_EDIT,
  OPERATOR_CREATE,
  OPERATOR_EDIT,
  GAME_CREATE,
  GAME_EDIT,
}

// RUD - create, read, update, delete
export class DrawerCRUDStore {
  view: EDrawerView = EDrawerView.UNKNOWN

  id: string = ''

  constructor() {
    makeAutoObservable(this)
  }

  get isOpen(): boolean {
    return this.view !== EDrawerView.UNKNOWN
  }

  changeView = (payload: EDrawerView, id = ''): void => {
    this.view = payload
    this.id = id
  }

  resetView = (): void => {
    this.view = EDrawerView.UNKNOWN
    this.id = ''
  }
}
