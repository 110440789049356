import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { useMountedState } from 'react-use'
import { Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { FilterBar } from '../../components'
import { EDrawerView } from '../../stores/DrawerCRUD.store'
import { useStore } from '../../stores'
import { gameNames } from '../../constants/gameNames'
import { limitStatusNames } from '../../constants/limit.status'

const tHead = ['Название', 'MIN лимит', 'MAX лимит', 'MAX выплата', 'Игра', 'Статус']

const Limits: FC = observer(() => {
  const isMounted = useMountedState()
  const { limitsStore, drawerCRUDStore } = useStore()

  useEffect(() => {
    ;(async () => {
      if (!isMounted()) return

      await limitsStore.getLimits()
    })()
  }, [])

  return (
    <Box>
      <FilterBar>
        <IconButton
          onClick={(e) => {
            e.preventDefault()

            drawerCRUDStore.changeView(EDrawerView.LIMIT_CREATE)
          }}>
          <AddIcon />
        </IconButton>
      </FilterBar>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {tHead.map((item) => {
                return <TableCell key={item}>{item}</TableCell>
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {limitsStore.rows.map((item) => {
              return (
                <TableRow
                  sx={{
                    cursor: 'pointer',
                  }}
                  key={item.id}
                  hover
                  onClick={async (e) => {
                    e.preventDefault()

                    drawerCRUDStore.changeView(EDrawerView.LIMIT_EDIT, item.id)
                  }}>
                  <TableCell component="th" scope="row">
                    {item.name}
                  </TableCell>
                  <TableCell>{item.minimum}</TableCell>
                  <TableCell>{item.maximum}</TableCell>
                  <TableCell>{item.maximumPayout}</TableCell>
                  <TableCell>{gameNames[item.nameGame]}</TableCell>
                  <TableCell>{limitStatusNames[item.status]}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
})

export default Limits
