import { FC, ReactNode } from 'react'
import { Paper, Stack, Toolbar } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../stores'
import { ERole } from '../../constants/role'

type FilterBarProps = {
  children: ReactNode
}

const FilterBar: FC<FilterBarProps> = ({ children }) => {
  const { loginStore } = useStore()

  if (loginStore.role === ERole.DEALER) return null

  return (
    <Toolbar
      component={Paper}
      sx={{
        marginBottom: 2,
        gap: 1,
        pb: 1,
        pt: 1,
      }}>
      <Stack flexWrap="wrap">{children}</Stack>
    </Toolbar>
  )
}

export default observer(FilterBar)
