import { FC } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

type DeleteBtnProps = {
  disabled?: boolean
  onClick?: () => void
}

const DeleteBtn: FC<DeleteBtnProps> = ({ onClick, disabled }) => {
  return (
    <IconButton
      color="error"
      disabled={disabled}
      onClick={(e) => {
        e.preventDefault()

        if (onClick) onClick()
      }}>
      <Tooltip title="Удалить">
        <DeleteForeverIcon />
      </Tooltip>
    </IconButton>
  )
}

export default DeleteBtn
