export enum ESortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}
export type SortDirection = 'asc' | 'desc'
export type TOrder = [string, ESortDirection]
export type TFilter = [string, any]

export interface IQueryParams {
  limit: number
  offset: number
  from: string
  to: string
  order: TOrder[]
  filter: TFilter[]
}
