import {
  InfiniteData,
  QueryFunctionContext,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from 'react-query'
import { ApiPlayers } from '../../api'
import { IPlayerTransaction } from '../../api/players'

interface IQueryParams {
  limit: number
  offset: number
  from?: string // 2024-06-15 14:24
  to?: string // 2024-07-15 23:59
}

interface IGetTransactionsResponse {
  count: number
  rows: Array<IPlayerTransaction>
}

const getTransactions = async (context: QueryFunctionContext): Promise<IGetTransactionsResponse> => {
  const { queryKey, pageParam = 0 } = context
  const [, playerId, queryParams] = queryKey as [string, string, IQueryParams]
  const { limit, from, to } = queryParams

  const payload = {
    playerId,
    limit,
    offset: pageParam,
    from,
    to,
  }

  return ApiPlayers.getTransactionsByPlayerId(payload)
}

const useInfiniteQueryPlayerTransactions = (
  playerId: string,
  queryParams: IQueryParams,
  config?: UseInfiniteQueryOptions<IGetTransactionsResponse>
): UseInfiniteQueryResult<IGetTransactionsResponse, unknown> => {
  return useInfiniteQuery<IGetTransactionsResponse>(
    ['get transactions player by id', playerId, queryParams],
    getTransactions,
    {
      getNextPageParam: (lastPage, allPages) => {
        const { count } = lastPage
        const totalLoadedItems = allPages.flatMap((page) => page.rows).length
        if (totalLoadedItems < count) {
          return totalLoadedItems
        }
        return undefined
      },
      select: (data: InfiniteData<IGetTransactionsResponse>) => {
        return {
          pageParams: data.pageParams,
          pages: data.pages,
          totalCount: data.pages[0]?.count || 0,
        }
      },
      ...config,
    }
  )
}

export default useInfiniteQueryPlayerTransactions
