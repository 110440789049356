import { FC, useEffect, useState } from 'react'
import { Box, Button } from '@mui/material'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'

type FileInputProps = {
  id: string
  label: string
  value?: string
  onChange?: (file: string) => void
  disabled?: boolean
}

const FileInput: FC<FileInputProps> = ({ id, label, value, onChange, disabled }) => {
  const [base64Image, setBase64Image] = useState<string>('')

  const getBase64 = (file: Blob): void => {
    const reader = new FileReader()

    reader.readAsDataURL(file)

    reader.onload = () => {
      setBase64Image(reader.result as string)
      if (onChange) onChange(reader.result as string)
    }
  }

  useEffect(() => {
    if (value) setBase64Image(value)
  }, [value])

  return (
    <Box>
      <input
        disabled={disabled}
        accept="image/*"
        type="file"
        id={`select-image-${id}`}
        style={{ display: 'none' }}
        onChange={(e) => {
          const { files } = e.target as HTMLInputElement

          if (files) {
            getBase64(files[0])
          }
        }}
      />
      <label htmlFor={`select-image-${id}`}>
        <Button
          disabled={disabled}
          startIcon={<CloudDownloadIcon />}
          size="small"
          variant="contained"
          color="primary"
          component="span"
          fullWidth>
          {label}
        </Button>
      </label>

      {base64Image && (
        <Box
          sx={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 1,
          }}>
          <div>Превью:</div>
          <img src={base64Image} alt="" height="50px" />
        </Box>
      )}
    </Box>
  )
}

export default FileInput
