import { FC } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import LockClockIcon from '@mui/icons-material/LockClock'

type BlockBtnProps = {
  disabled?: boolean
  onClick?: () => void
}

const BlockBtn: FC<BlockBtnProps> = ({ onClick, disabled }) => {
  return (
    <IconButton
      color="secondary"
      disabled={disabled}
      onClick={(e) => {
        e.preventDefault()

        if (onClick) onClick()
      }}>
      <Tooltip title="Заблокировать">
        <LockClockIcon />
      </Tooltip>
    </IconButton>
  )
}

export default BlockBtn
