import http from '../../services/http'
import { EOperatorStatus } from '../../constants/operator.status'
import { ERole } from '../../constants/role'

export interface IOperatorData {
  techCode?: string
}

export interface IOperator {
  id: string
  login: string
  password: string
  firstName: string
  lastName: string
  role: ERole
  status: EOperatorStatus
  data: IOperatorData
  countLoginError: number
  createdAt: string
  updatedAt: string
}

export interface IOperators {
  count: number
  rows: IOperator[]
}

class Operators {
  prefix: string = '/api/operator'

  getOperators = async (params: { role?: ERole }): Promise<IOperators> => {
    const response = await http.get(this.prefix, {
      params: {
        ...params,
      },
    })

    return response.data
  }

  getOperatorById = async (id: string): Promise<IOperator> => {
    const response = await http.get(`${this.prefix}/${id}`)

    return response.data
  }

  createOperator = async (payload: Partial<IOperator>): Promise<IOperator> => {
    const response = await http.post(this.prefix, {
      ...payload,
    })

    return response.data
  }

  deleteOperator = async (id: string): Promise<void> => {
    await http.delete(`${this.prefix}/${id}`)
  }

  updateFieldsOperator = async (id: string, payload: Partial<IOperator>): Promise<void> => {
    await http.put(`${this.prefix}/${id}`, {
      ...payload,
      action: 'updateFields',
    })
  }

  updatePasswordOperator = async (
    id: string,
    payload: {
      newPassword: string
    }
  ): Promise<void> => {
    await http.put(`${this.prefix}/${id}`, {
      ...payload,
      action: 'change-password',
    })
  }

  updateBlockOperator = async (id: string): Promise<void> => {
    await http.put(`${this.prefix}/${id}`, {
      action: 'block',
    })
  }

  updateUnBlockOperator = async (id: string): Promise<void> => {
    await http.put(`${this.prefix}/${id}`, {
      action: 'activate',
    })
  }
}

export default new Operators()
