import { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useInView } from 'react-intersection-observer'
import { DateRange } from 'react-day-picker'
import { endOfDay, format, startOfMonth } from 'date-fns'
import { useInfiniteQuery } from 'react-query'
import { toast } from 'react-toastify'
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table'
import { Box, Button, Stack } from '@mui/material'
import { MRT_Localization_RU } from 'material-react-table/locales/ru'
import { Link } from 'react-router-dom'
import { Currency, DateRangePicker, FilterBar, LoadMoreRows } from '../index'
import { ApiPlayers } from '../../api'
import { IPlayer, IPlayerGameSessionResult } from '../../api/players'
import { dateFallback, EDateFormat, formatDate } from '../../utils/date'
import { mrtDateTimeFilter } from '../../services/mrtFilters'
import { moneyFormat } from '../../utils/money'
import { playerTransactionEntityAction } from '../../constants/playerTransactionEntity'

type PlayerGameSessionResultsTableProps = Pick<IPlayer, 'id' | 'login' | 'currency'>

const fetchSize = 15

const dateFormat = EDateFormat.yyy_MM_dd_HH_mm

const PlayerGameSessionResultsTable: FC<PlayerGameSessionResultsTableProps> = observer((props) => {
  const { id, currency } = props
  // const { drawerCRUDStore } = useStore()

  const { ref, inView } = useInView()

  const [range, setRange] = useState<DateRange>({
    from: dateFallback(startOfMonth(new Date())),
    to: dateFallback(endOfDay(new Date())),
  })

  const { data, isFetching, isError, isLoading, isFetchingNextPage, fetchNextPage } = useInfiniteQuery(
    ['player game session results history', range.from, range.to, id],
    async ({ pageParam = 0 }) => {
      const { from, to } = range

      const queryDateFilters = {
        ...(from &&
          to && {
            from: format(from, dateFormat),
            to: format(to, dateFormat),
          }),
      }

      return ApiPlayers.getGameSessionResultsByPlayerId({
        playerId: id || '',
        limit: fetchSize,
        offset: pageParam * fetchSize,
        ...queryDateFilters,
      })
    },
    {
      enabled: !!id && !!range.from && !!range.to,
      getNextPageParam: (_lastGroup, groups) => groups.length,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => toast.error(`${err}`),
    }
  )

  const history = data?.pages.flatMap((item) => item.rows ?? []) || []
  const historyRows = history.length
  const historyTotalRows = data?.pages?.[0]?.count || 0

  useEffect(() => {
    if (inView && historyRows < historyTotalRows) {
      fetchNextPage()
    }
  }, [inView])

  const columns: MRT_ColumnDef<IPlayerGameSessionResult>[] = [
    {
      accessorKey: 'createdAt',
      header: 'Время',
      sortingFn: 'datetime',
      enableColumnFilter: false,
      Cell: ({ cell }) => {
        const date: Date | null = cell.getValue<Date>()
        return formatDate(new Date(date), EDateFormat.dd_MM_yyy_HH_mm)
      },
    },
    {
      accessorFn: (row) => {
        if (row?.gameId) return row.gameId

        if (row.detail?.gameId) return `Слот: ${row.detail.gameId}`

        return '-'
      },
      header: 'Игра',
      // Cell: ({ cell, row }) => {
      //   const gameId = cell.getValue<string>()
      //
      //   if (!row.original?.gameId) return gameId
      //
      //   return (
      //     <a
      //       href={`/game/edit/${gameId}`}
      //       onClick={(e) => {
      //         e.preventDefault()
      //         e.stopPropagation()
      //
      //         drawerCRUDStore.changeView(EDrawerView.GAME_EDIT, gameId)
      //       }}>
      //       {gameId}
      //     </a>
      //   )
      // },
    },
    {
      accessorFn: (row) => {
        if (row?.sessionId) return row.sessionId

        if (row.detail?.sessionId) return `${row.detail.sessionId}`

        return '-'
      },
      header: 'Сессия',
      Cell: ({ cell, row }) => {
        const sessionId = cell.getValue<string>()

        if (!row.original?.sessionId) return sessionId

        const { gameId } = row.original

        return <Link to={`/game/${gameId}/session/${sessionId}/detail`}>{sessionId}</Link>
      },
    },
    {
      accessorKey: 'amount',
      accessorFn: (row) => {
        return moneyFormat(row?.amount || '0')
      },
      header: 'Сумма',
      Cell: ({ cell }) => {
        const value = cell.getValue<string>()

        return (
          <>
            {value} <Currency currency={currency} />
          </>
        )
      },
    },
    {
      accessorFn: (row) => {
        return playerTransactionEntityAction[row.action]
      },
      header: 'Результат',
    },
  ]

  return (
    <Box>
      <FilterBar>
        <Stack gap={2} direction="row" alignItems="center">
          <DateRangePicker
            from={range.from}
            to={range.to}
            onChangeRange={(rangeDate) => {
              setRange({
                from: rangeDate?.from,
                to: rangeDate?.to ? endOfDay(rangeDate?.to) : undefined,
              })
            }}
          />
        </Stack>
      </FilterBar>

      <MaterialReactTable
        localization={MRT_Localization_RU}
        enableFullScreenToggle={false}
        enableGlobalFilter={false}
        enableFilterMatchHighlighting={false}
        renderDetailPanel={({ row }) => {
          const detail = JSON.stringify(row.original.detail, null, 2)
          return (
            <code>
              <pre>{detail}</pre>
            </code>
          )
        }}
        filterFns={{
          mrtDateTimeFilter,
        }}
        state={{
          isLoading,
          showAlertBanner: isError,
          showProgressBars: isFetching,
        }}
        initialState={{
          showColumnFilters: true,
          density: 'comfortable',
        }}
        columns={columns}
        data={history}
        enablePagination={false}
        renderTopToolbarCustomActions={({ table }) => {
          return (
            <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}>
              <Button size="small" variant="outlined" onClick={() => table.resetColumnFilters()}>
                Сбросить фильтры
              </Button>
            </Box>
          )
        }}
        renderBottomToolbarCustomActions={() => {
          return (
            <LoadMoreRows
              ref={ref}
              rows={historyRows}
              totalRows={historyTotalRows}
              isLoading={isFetchingNextPage}
              onLoad={() => {
                fetchNextPage()
              }}
            />
          )
        }}
      />
    </Box>
  )
})

export default PlayerGameSessionResultsTable
