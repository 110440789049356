type TRow = {
  num: number
  color: 'red' | 'black'
}

type TCells = {
  row1: TRow[]
  row2: TRow[]
  row3: TRow[]
}

export const rouletteCells: TCells = {
  row1: [
    {
      num: 3,
      color: 'red',
    },
    {
      num: 6,
      color: 'black',
    },
    {
      num: 9,
      color: 'red',
    },
    {
      num: 12,
      color: 'red',
    },
    {
      num: 15,
      color: 'black',
    },
    {
      num: 18,
      color: 'red',
    },
    {
      num: 21,
      color: 'red',
    },
    {
      num: 24,
      color: 'black',
    },
    {
      num: 27,
      color: 'red',
    },
    {
      num: 30,
      color: 'red',
    },
    {
      num: 33,
      color: 'black',
    },
    {
      num: 36,
      color: 'red',
    },
  ],
  row2: [
    {
      num: 2,
      color: 'black',
    },
    {
      num: 5,
      color: 'red',
    },
    {
      num: 8,
      color: 'black',
    },
    {
      num: 11,
      color: 'black',
    },
    {
      num: 14,
      color: 'red',
    },
    {
      num: 17,
      color: 'black',
    },
    {
      num: 20,
      color: 'black',
    },
    {
      num: 23,
      color: 'red',
    },
    {
      num: 26,
      color: 'black',
    },
    {
      num: 29,
      color: 'black',
    },
    {
      num: 32,
      color: 'red',
    },
    {
      num: 35,
      color: 'black',
    },
  ],
  row3: [
    {
      num: 1,
      color: 'red',
    },
    {
      num: 4,
      color: 'black',
    },
    {
      num: 7,
      color: 'red',
    },
    {
      num: 10,
      color: 'black',
    },
    {
      num: 13,
      color: 'black',
    },
    {
      num: 16,
      color: 'red',
    },
    {
      num: 19,
      color: 'red',
    },
    {
      num: 22,
      color: 'black',
    },
    {
      num: 25,
      color: 'red',
    },
    {
      num: 28,
      color: 'black',
    },
    {
      num: 31,
      color: 'black',
    },
    {
      num: 34,
      color: 'black',
    },
  ],
}
