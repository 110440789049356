import { MRT_Localization_RU } from 'material-react-table/locales/ru'
import { MRT_Localization } from 'material-react-table'

const mrtLocalization: {
  localization?: Partial<MRT_Localization>
} = {
  localization: MRT_Localization_RU,
}

export default mrtLocalization
