import { FC, Fragment, ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import { TextField } from '@mui/material'
import StandStore, { standFields } from '../../stores/Stand.store'
import { formPropsDrawerCrud } from '../../constants/formPropsDrawerCrud'
import { FileInput, Select } from '../index'
import { EGameName, gameNamesOptions } from '../../constants/gameNames'
import { EStandStatus, standStatusNamesOptions } from '../../constants/stand.status'
import { useStore } from '../../stores'

type StandDataProps = {
  stand: StandStore
  disabledAll?: boolean
  pickFields: Array<keyof StandStore>
}

const StandData: FC<StandDataProps> = observer(({ stand, disabledAll, pickFields }) => {
  const { antMediaStore } = useStore()

  const fieldProps = {
    ...formPropsDrawerCrud,
    ...(disabledAll && { disabled: disabledAll }),
  }

  const fieldComponents: Partial<Record<keyof StandStore, ReactNode>> = {
    id: (
      <TextField
        {...fieldProps}
        disabled
        value={stand.id}
        onChange={(e) => stand.updateFields({ id: e.target.value })}
        label={standFields.id}
      />
    ),
    name: (
      <TextField
        {...fieldProps}
        value={stand.name}
        onChange={(e) => stand.updateFields({ name: e.target.value })}
        label={standFields.name}
      />
    ),
    typeGame: (
      <Select
        id="select-game-stand"
        disabled
        options={gameNamesOptions}
        {...fieldProps}
        value={stand.typeGame}
        onChange={(e) => stand.updateFields({ typeGame: e as EGameName })}
        label={standFields.typeGame}
      />
    ),
    status: (
      <Select
        id="select-status-stand"
        options={standStatusNamesOptions}
        {...fieldProps}
        disabled
        value={stand.status}
        onChange={(e) => stand.updateFields({ status: e as EStandStatus })}
        label={standFields.status}
      />
    ),
    fileLobbyPreview: (
      <FileInput
        disabled={disabledAll}
        id="fileLobbyPreview"
        label="Превью для лобби (160x204)"
        value={stand.fileLobbyPreview}
        onChange={(file) => {
          stand.updateFields({
            data: {
              ...stand.data,
              fileLobbyPreview: file,
            },
          })
        }}
      />
    ),
    fileQuickGamePreview: (
      <FileInput
        disabled={disabledAll}
        id="fileQuickGamePreview"
        label="Превью для быстрого перехода (80x60)"
        value={stand.fileQuickGamePreview}
        onChange={(file) => {
          stand.updateFields({
            data: {
              ...stand.data,
              fileQuickGamePreview: file,
            },
          })
        }}
      />
    ),
    streamName: (
      <Select
        id="select-stream-stand"
        options={antMediaStore.streamsOptions}
        {...fieldProps}
        value={stand.streamId}
        onChange={(e) => {
          const streamId = e as string

          stand.updateFields({
            data: {
              ...stand.data,
              streamId,
              name: antMediaStore.findStreamNameById(streamId),
            },
          })
        }}
        label="Видеопоток"
      />
    ),
  }

  return (
    <>
      {pickFields.map((item) => {
        return <Fragment key={item}>{fieldComponents[item]}</Fragment>
      })}
    </>
  )
})

export default StandData
