import http from '../../services/http'
import { EGameName } from '../../constants/gameNames'
import { ESort } from '../../constants/sort'
import { ERouletteGameStatus } from '../../constants/rouletteGameStatus'

export interface Player {
  id: string
  name: string
}

export interface IGameHistory {
  session_id: number
  players: Player[]
  dealer_id: string
  dealer_login: string
  time_session: string
  total_count: string
}

export interface IGameHistoryPlayer {
  id: string
  login: string
}

export interface IGameHistoryDealer {
  ball: string
  id: string
  login: string
}

export interface PlayerBets {
  [time: string]: Record<string, number>
}
export interface IRecordPlayerBetsDetail {
  serverTime: string
  [k: string]: number | string
}

export type TRecordPlayerBets = Record<string, IRecordPlayerBetsDetail>

// const responseDetail = {
//   "name": "John Gold",
//   "id": "5c43-963b-09c9-4b07",
//   "balance_before": "100710",
//   "balance_after": "100760",
//   "playerbets": {
//     "1693217250189": {
//       "field:11": 1,
//       "serverTime": "2023-08-28T10:07:30.201Z"
//     },
//     "1693217251675": {
//       "field:20": 1,
//       "serverTime": "2023-08-28T10:07:31.685Z"
//     },
//     "1693217253477": {
//       "field:13": 1,
//       "serverTime": "2023-08-28T10:07:33.485Z"
//     },
//     "1693217255569": {
//       "split:11:12": 1,
//       "serverTime": "2023-08-28T10:07:35.578Z"
//     }
//   },
//   "playerresult": {
//     "bets": {
//       "win": {
//         "field:11": {
//           "bet": 1,
//           "win": 35
//         },
//         "split:11:12": {
//           "bet": 1,
//           "win": 17
//         }
//       },
//       "lose": {
//         "field:13": {
//           "bet": 1
//         },
//         "field:20": {
//           "bet": 1
//         }
//       }
//     },
//     "deposit": 1050,
//     "resultStatus": "win",
//     "amountRelativeGame": 50,
//     "amountRelativeNumber": 52
//   }
// }

export interface IPlayerResultBet {
  [k: string]: {
    bet: number
  }
}

export interface IPlayerResultWinBet {
  [k: string]: {
    bet: number
    win: number
  }
}
export interface IPlayerResult {
  amountRelativeGame: number
  amountRelativeNumber: number
  bets: {
    win: IPlayerResultWinBet
    lose: IPlayerResultBet
  }
  deposit: number
  resultStatus: 'win' | 'lose'
}

export interface IDetailHistoryByGameIdBySessionIdByPlayerId {
  balance_after: string | null
  balance_before: string | null
  id: string
  name: string
  playerbets: TRecordPlayerBets
  playerresult: IPlayerResult | null
}

class GameHistory {
  prefix: string = '/api/gameHistory'

  getHistoryById = async (payload: {
    id: string
    limit: number
    offset: number
    dateFrom?: string
    dateTo?: string
    playerId?: string
  }): Promise<IGameHistory[]> => {
    const { dateFrom, dateTo, playerId } = payload
    const response = await http.get(`${this.prefix}/${payload.id}`, {
      params: {
        game: EGameName.ROULETTE,
        limit: payload.limit,
        offset: payload.offset,
        sortByDate: ESort.DESC,
        dateFrom,
        dateTo,
        playerId,
      },
    })

    return response.data
  }

  getDetailHistoryByGameIdBySessionIdByPlayerId = async (
    gameId: string,
    sessionId: number,
    playerId: string
  ): Promise<IDetailHistoryByGameIdBySessionIdByPlayerId> => {
    // 6648322490492237063
    // http://127.0.0.1:3000/api/gameHistory/627e-02ee-b5d6-cd07/66/player/5c43-9614-ae07-b507?game=roulette

    // 6648322490492237000 not right
    // http://localhost:3000/api/gameHistory/627e-02ee-b5d6-cd07/66/player/5c43-9614-ae07-b400?game=roulette

    const response = await http.get(`${this.prefix}/${gameId}/${sessionId}/player/${playerId}`, {
      params: {
        game: EGameName.ROULETTE,
      },
    })

    return response.data
  }

  getDetailHistoryPlayersByGameId = async (gameId: string): Promise<IGameHistoryPlayer[]> => {
    const response = await http.get(`${this.prefix}/${gameId}/players`, {
      params: {
        game: EGameName.ROULETTE,
      },
    })

    return response.data
  }

  getDetailHistoryPlayersByGameIdBysSessionId = async (
    gameId: string,
    sessionId: number
  ): Promise<IGameHistoryPlayer[]> => {
    const response = await http.get(`${this.prefix}/${gameId}/${sessionId}/players`, {
      params: {
        game: EGameName.ROULETTE,
      },
    })

    return response.data
  }

  getDetailHistoryDealerByGameIdBysSessionId = async (
    gameId: string,
    sessionId: number
  ): Promise<IGameHistoryDealer> => {
    const response = await http.get(`${this.prefix}/${gameId}/${sessionId}/dealer`, {
      params: {
        game: EGameName.ROULETTE,
      },
    })

    return response.data
  }

  getSessionStatuses = async (
    gameId: string,
    sessionId: number
  ): Promise<
    Array<{
      at: string
      value: ERouletteGameStatus
    }>
  > => {
    const response = await http.get(`${this.prefix}/${gameId}/${sessionId}/statuses`)

    return response.data
  }
}

export default new GameHistory()
