import { FC } from 'react'
import { useParams } from 'react-router-dom'
import useQueryPlayerById from '../../hooks/useQueryPlayerById'
import { FullScreenRouteDialog, PlayerGameSessionResultsTable } from '../index'

const PlayerGameSessionResultsDialog: FC = () => {
  const { playerId } = useParams()

  const { data: player } = useQueryPlayerById(playerId as string)

  if (!player) return null

  return (
    <FullScreenRouteDialog title={`Результаты игр: ${player.login}`}>
      <PlayerGameSessionResultsTable id={player.id} login={player.login} currency={player.currency} />
    </FullScreenRouteDialog>
  )
}

export default PlayerGameSessionResultsDialog
