import { ECurrency } from '@lbs/currency'
import { EPlayerStatus } from '@lbs/status'
import qs from 'qs'
import http from '../../services/http'
import { IQueryParams } from '../../constants/queryParams'

export interface IPlayerPhone {
  number: string
  priority: number
}

export interface IPlayerBalance {
  balance: {
    playerId: string
    balance: number
    inGame: {
      [k: string]: {
        deposit: number
        bets: number
      }
    }
  }
}

export interface IPlayerCoupon {
  id: string
  data: {
    code: number
    message: string
    playerPhone: string
  }
  created: any
  createdFor: string
  used: any
  type: string
  isCheckedForSending: number
  expiresIn: any
  sendAt: string
  status: number
  createdAt: string
  updatedAt: string
}

export interface IPlayersRow {
  id: string
  login: string
  password: string
  phones: IPlayerPhone[]
  status: EPlayerStatus
  activatedAt: string | null
  createdAt: string
  updatedAt: string
  coupons: IPlayerCoupon[]
}

export interface IPlayers {
  count: number
  rows: IPlayersRow[]
}

export interface IPlayer {
  id: string
  login: string
  phones: IPlayerPhone[]
  status: EPlayerStatus
  balance: string
  availableBalance: string
  currency: ECurrency
  rouletteRatio: number
  activatedAt: string | null
  createdAt: string
  updatedAt: string
}

export interface ICreatePlayer extends Pick<IPlayer, 'login' | 'phones' | 'currency' | 'rouletteRatio'> {}

export type TPlayerTransactionEntityType = 'game' | 'operator' | 'slot'
export type TPlayerTransactionAction = 'refill' | 'withdrawal' | 'win' | 'lose' | 'in' | 'out' | 'winOutOfPlay'

export interface IPlayerTransaction {
  id: number
  playerId: string
  amount: string
  entityType: TPlayerTransactionEntityType
  entityId: string
  action: TPlayerTransactionAction
  description: string
  detail: {
    typePayment: 'drop' | 'rollback' | 'credit' | 'gift'
  }
  createdAt: Date
  sessionId?: number
  operatorTransaction: null | {
    login: string
  }
}

export interface IPlayerGameSessionResult {
  id: number
  gameId: string | null
  sessionId: number | null
  action: TPlayerTransactionAction
  amount: string
  detail: any
  createdAt: Date
}

export interface IGetPlayerFinances {
  count: number
  rows: IPlayerFinance[]
}

export interface IPlayerFinance {
  id: string
  playerId: string
  financeBefore: string
  financeAfter: string
  amount: string
  balanceTransactionId: number | null
  gameSessionResultId: number | null
  createdAt: Date
  updatedAt: Date
  gameSessionResult: null | IPlayerGameSessionResult
  balanceTransaction: null | IPlayerTransaction
}

class Players {
  prefix: string = '/api/player'

  prefixV2: string = '/api/v2/player'

  getPlayerById = async (id: string): Promise<IPlayer> => {
    const response = await http.get(`${this.prefixV2}/${id}`)

    return response.data
  }

  getPlayerBalanceById = async (id: string): Promise<IPlayer> => {
    const response = await http.get(`${this.prefixV2}/${id}`)

    return response.data
  }

  createPlayer = async (data: ICreatePlayer): Promise<void> => {
    await http.post(this.prefixV2, {
      ...data,
    })
  }

  getCouponsPlayerById = async (id: string): Promise<IPlayersRow> => {
    const response = await http.get(`${this.prefix}/${id}/coupon`)

    return response.data
  }

  changePassword = async (
    id: string,
    payload: {
      newPassword: string
    }
  ): Promise<void> => {
    await http.put(`${this.prefix}/${id}`, {
      ...payload,
      action: 'change-password',
    })
  }

  deletePlayerById = async (id: string): Promise<void> => {
    await http.delete(`${this.prefixV2}/${id}`)
  }

  updateFieldsPlayerById = async (
    id: string,
    data: Omit<Partial<IPlayer>, 'id' | 'createdAt' | 'updatedAt' | 'activatedAt'>
  ): Promise<void> => {
    await http.put(`${this.prefixV2}/${id}`, {
      ...data,
    })
  }

  getGameSessionResultsByPlayerId = async (payload: {
    playerId: string
    limit: number
    offset: number
    from?: string // 2024-06-15 14:24
    to?: string // 2024-07-15 23:59
  }): Promise<{
    count: number
    rows: Array<IPlayerGameSessionResult>
  }> => {
    const { playerId, limit, offset, from, to } = payload

    const queryParams = new URLSearchParams({
      limit: limit.toString(),
      offset: offset.toString(),
      ...(from && { from }),
      ...(to && { to }),
    }).toString()

    const response = await http.get(`${this.prefixV2}/${playerId}/game/results/?${queryParams}`)

    return response.data
  }

  getTransactionsByPlayerId = async (payload: {
    playerId: string
    limit: number
    offset: number
    from?: string // 2024-06-15 14:24
    to?: string // 2024-07-15 23:59
  }): Promise<{
    count: number
    rows: Array<IPlayerTransaction>
  }> => {
    const { playerId, limit, offset, from, to } = payload

    const queryParams = new URLSearchParams({
      limit: limit.toString(),
      offset: offset.toString(),
      ...(from && { from }),
      ...(to && { to }),
    }).toString()

    const response = await http.get(`${this.prefixV2}/${playerId}/transactions/?${queryParams}`)

    return response.data
  }

  getFinanceByPlayerId = async (
    payload: {
      playerId: string
    } & Partial<IQueryParams>
  ): Promise<IGetPlayerFinances> => {
    const { playerId, ...params } = payload

    const response = await http.get(`${this.prefixV2}/${playerId}/finances`, {
      params,
      paramsSerializer: qs.stringify,
    })

    return response.data
  }

  getFinanceTotalByPlayerId = async (
    payload: {
      playerId: string
    } & Partial<Omit<IQueryParams, 'order'>>
  ): Promise<{
    totalAmount: string
  }> => {
    const { playerId, ...params } = payload

    const response = await http.get(`${this.prefixV2}/${playerId}/finances/total`, {
      params,
      paramsSerializer: qs.stringify,
    })

    return response.data
  }

  exportXlsxFinanceByPlayerId = async (payload: { playerId: string } & Partial<IQueryParams>): Promise<Blob> => {
    const { playerId, ...params } = payload
    const response = await http.get(`${this.prefixV2}/${playerId}/finances/download`, {
      params,
      paramsSerializer: qs.stringify,
      responseType: 'blob',
    })

    return response.data
  }

  patchBalance = async (
    playerId: string,
    payload: {
      balance: number
      typePayment: string
      description?: string
    }
  ): Promise<void> => {
    await http.patch(`${this.prefixV2}/${playerId}`, {
      ...payload,
    })
  }
}

export default new Players()
