import { FC, useState } from 'react'
import { toast } from 'react-toastify'
import { useKeyPressEvent, useMountedState } from 'react-use'
import {
  Box,
  Button,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { get } from 'lodash'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { useQuery } from 'react-query'
import { IDetailHistoryByGameIdBySessionIdByPlayerId, IGameHistoryPlayer } from '../../api/gameHistory'
import { Select } from '../index'
import { numberWithCommas } from '../../utils/number'
import { EDateFormat, formatDateString } from '../../utils/date'
import { CenteredInfoNavBar, NavigateBar } from './styles'
import { ApiGameHistory, ApiRecordings } from '../../api'
import routes from '../../router/routes'
import { ERouletteGameStatus } from '../../constants/rouletteGameStatus'

export type DetailHistoryRouletteProps = {
  gameId: string
  sessionId: number
  dates: Array<string>
  sessions: Array<number>
}

type TBet = { fields: { fieldName: string; count: number }[]; serverTime: string }
type TWinBet = { fieldName: string; bet: number; win: number }

const DetailHistoryRoulette: FC<DetailHistoryRouletteProps> = ({ gameId, sessionId, dates, sessions }) => {
  const isMounted = useMountedState()
  const [selectedSessionId, setSelectedSessionId] = useState<number>(sessionId)
  const [dealer, setDealer] = useState('')
  const [ball, setBall] = useState('')
  const [players, setPlayers] = useState<IGameHistoryPlayer[]>([])
  const [selectPlayer, setSelectPlayer] = useState<string>('')
  const [detailHistoryPlayer, setDetailHistoryPlayer] = useState<IDetailHistoryByGameIdBySessionIdByPlayerId | null>(
    null
  )
  const [bets, setBets] = useState<TBet[]>([])
  const [winBets, setWinBets] = useState<TWinBet[]>([])
  const [spinTime, setSpinTime] = useState<string>('')

  const selectedSessionIndex = sessions.findIndex((item) => item === selectedSessionId)

  const handlePrev = (): void => {
    let prevIndex = selectedSessionIndex - 1
    if (prevIndex < 0) {
      prevIndex = sessions.length - 1
    }

    setSelectPlayer('')
    setSelectedSessionId(sessions[prevIndex])
  }
  const handleNext = (): void => {
    const nextIndex = (selectedSessionIndex + 1) % sessions.length

    setSelectPlayer('')
    setSelectedSessionId(sessions[nextIndex])
  }

  useKeyPressEvent('ArrowLeft', handlePrev)
  useKeyPressEvent('ArrowRight', handleNext)

  useQuery(
    ['detailHistory', gameId, selectedSessionId, selectPlayer],
    async () => {
      return ApiGameHistory.getDetailHistoryByGameIdBySessionIdByPlayerId(gameId, selectedSessionId, selectPlayer)
    },
    {
      enabled: !!gameId && !!selectedSessionId && !!selectPlayer,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onSuccess: (data) => {
        if (!isMounted()) return

        setDetailHistoryPlayer(data)

        const keysBets = Object.keys(data.playerbets)
        const detailBets = keysBets.map((item) => {
          return {
            fields: Object.keys(data.playerbets[item])
              .filter((itemKeyName) => itemKeyName !== 'serverTime')
              .map((itemBetName) => {
                return {
                  fieldName: itemBetName,
                  count: data.playerbets[item][itemBetName] as number,
                }
              }),
            serverTime: data.playerbets?.[item]?.serverTime || '',
          }
        })

        setBets(detailBets)

        if (data.playerresult) {
          const keyWinBets = Object.keys(data.playerresult.bets.win)
          const detailWinBets = keyWinBets.map((item) => {
            return {
              fieldName: item,
              bet: get(data.playerresult?.bets?.win, [item, 'bet'], 0),
              win: get(data.playerresult?.bets?.win, [item, 'win'], 0),
            }
          })

          setWinBets(detailWinBets)
        }
      },
      onError: (e) => {
        toast.error(`${e}`)
      },
    }
  )

  useQuery(
    ['detailHistoryPlayers', gameId, selectedSessionId],
    async () => {
      return ApiGameHistory.getDetailHistoryPlayersByGameIdBysSessionId(gameId, selectedSessionId)
    },
    {
      enabled: !!gameId && !!selectedSessionId,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onSuccess: (data) => {
        if (!isMounted()) return

        setPlayers([...data])

        if (data.length) setSelectPlayer(data[0].id)
      },
      onError: (e) => {
        toast.error(`${e}`)
      },
    }
  )

  useQuery(
    ['detailHistoryDealer', gameId, selectedSessionId],
    async () => {
      return ApiGameHistory.getDetailHistoryDealerByGameIdBysSessionId(gameId, selectedSessionId)
    },
    {
      enabled: !!gameId && !!selectedSessionId,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onSuccess: (data) => {
        if (!isMounted()) return

        setDealer(data.login)
        setBall(data.ball)
      },
      onError: (e) => {
        toast.error(`${e}`)
      },
    }
  )

  const { data: recording } = useQuery(
    ['get recording by playerId by sessionId', selectedSessionId, selectPlayer],
    async () => {
      return ApiRecordings.getRecordingByPlayerIdBySessionId({
        playerId: selectPlayer,
        sessionId: selectedSessionId,
      })
    },
    {
      enabled: !!selectPlayer && !!selectedSessionId,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  )

  useQuery(
    ['get statuses of session', selectedSessionId],
    async () => {
      return ApiGameHistory.getSessionStatuses(gameId, selectedSessionId)
    },
    {
      onSuccess: (data) => {
        const findSpinTime = data.find((itemStatus) => itemStatus.value === ERouletteGameStatus.SPIN)?.at || ''

        setSpinTime(findSpinTime)
      },
      enabled: !!selectedSessionId,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  )

  const recordingId = recording?.id || ''

  const headerSessionId = recordingId ? (
    <Link
      href={`${window.location.origin}${routes.recordings}/${recordingId}`}
      onClick={(e) => {
        e.preventDefault()

        window.open(`${window.location.origin}${routes.recordings}/${recordingId}`, '_blank')
      }}>
      <b>{selectedSessionId}</b>
    </Link>
  ) : (
    <b>{selectedSessionId}</b>
  )

  return (
    <Box
      sx={{
        p: 2,
      }}>
      <NavigateBar>
        <Button
          color="inherit"
          disabled={!sessions.length}
          startIcon={<NavigateBeforeIcon />}
          onClick={(e) => {
            e.preventDefault()

            handlePrev()
          }}>
          Предыдущая сессия
        </Button>
        <CenteredInfoNavBar>
          <Typography variant="h6" fontWeight="normal">
            ID сессии: {headerSessionId}
          </Typography>
          <Typography>{formatDateString(dates[selectedSessionIndex], EDateFormat.dd_MM_yyy_HH_mm_ss)}</Typography>
        </CenteredInfoNavBar>
        <Button
          color="inherit"
          disabled={!sessions.length}
          endIcon={<NavigateNextIcon />}
          onClick={(e) => {
            e.preventDefault()

            handleNext()
          }}>
          Следующая сессия
        </Button>
      </NavigateBar>
      <Typography variant="h6">Игрок</Typography>
      <Select
        fullWidth
        id="players-in-session"
        label="Выберите игрока"
        value={selectPlayer}
        options={players.map((item) => {
          return { label: item.login, value: item.id }
        })}
        onChange={(e) => {
          setSelectPlayer(e as string)
        }}
      />

      <Box sx={{ mt: 3, mb: 3 }} />

      <Typography variant="h6">Об игре</Typography>
      <TableContainer component={Paper} variant="outlined">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Дилер</TableCell>
              <TableCell>Выпавшее число</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{dealer}</TableCell>
              <TableCell>{ball}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ mt: 3, mb: 3 }} />

      {detailHistoryPlayer && (
        <>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12}>
              <Typography variant="h6">Баланс</Typography>
              <TableContainer component={Paper} variant="outlined">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Игрок</TableCell>
                      <TableCell>Баланс до</TableCell>
                      <TableCell>Баланс после</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography fontWeight="bold" fontSize={14}>
                          {detailHistoryPlayer.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight="bold" fontSize={14}>
                          {numberWithCommas(Number(detailHistoryPlayer.balance_before) || 0)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight="bold" fontSize={14}>
                          {numberWithCommas(Number(detailHistoryPlayer.balance_after) || 0)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item md={6} sm={12}>
              <Typography variant="h6">Суммарный итог</Typography>
              <TableContainer component={Paper} variant="outlined">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Проиграл</TableCell>
                      <TableCell>Выиграл</TableCell>
                      <TableCell>Итог</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography fontWeight="bold" fontSize={14}>
                          {(detailHistoryPlayer.playerresult?.amountRelativeNumber || 0) -
                            (detailHistoryPlayer.playerresult?.amountRelativeGame || 0)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight="bold" fontSize={14}>
                          {detailHistoryPlayer.playerresult?.amountRelativeNumber || 0}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography fontWeight="bold" fontSize={14}>
                          {detailHistoryPlayer.playerresult?.amountRelativeGame || 0}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          <Box sx={{ m: 3 }} />

          {bets.length > 0 && (
            <Grid container spacing={2}>
              <Grid item lg={5} md={12}>
                <Typography variant="h6">Выигрышные ставки</Typography>
                <TableContainer component={Paper} variant="outlined">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Название ставки</TableCell>
                        <TableCell>Сумма ставок</TableCell>
                        <TableCell>Выигрыш</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {winBets.map((itemWinBet, indexItemWinBet) => {
                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <TableRow key={indexItemWinBet}>
                            <TableCell>
                              <Typography fontWeight="bold" fontSize={14}>
                                {itemWinBet.fieldName}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography fontWeight="bold" fontSize={14}>
                                {itemWinBet.bet}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography fontWeight="bold" fontSize={14}>
                                {itemWinBet.win}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item lg={7} md={12}>
                <Typography variant="h6">История ставок</Typography>
                <TableContainer component={Paper} variant="outlined">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Cтавки</TableCell>
                        <TableCell>
                          Время регистрации ставки{' '}
                          {spinTime && (
                            <>
                              <br />
                              nmb: {formatDateString(spinTime, EDateFormat.HH_mm_ss)}
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bets.map((bet, index) => {
                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <TableRow key={index}>
                            <TableCell>
                              <TableContainer component={Paper} variant="outlined">
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell width="40%">Название ставки</TableCell>
                                      <TableCell>Сумма ставки</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {bet.fields.map((itemField, indexItemField) => {
                                      return (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <TableRow key={indexItemField}>
                                          <TableCell width="40%">
                                            <Typography fontWeight="bold" fontSize={14}>
                                              {itemField.fieldName}
                                            </Typography>
                                          </TableCell>
                                          <TableCell>
                                            <Typography fontWeight="bold" fontSize={15}>
                                              {itemField.count}
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                      )
                                    })}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </TableCell>
                            <TableCell>{formatDateString(bet.serverTime, EDateFormat.HH_mm_ss)}</TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Box>
  )
}

export default DetailHistoryRoulette
