import { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useInView } from 'react-intersection-observer'
import { DateRange } from 'react-day-picker'
import { endOfDay, format, startOfMonth } from 'date-fns'
import { toast } from 'react-toastify'
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table'
import { Box, Button, Stack } from '@mui/material'
import { MRT_Localization_RU } from 'material-react-table/locales/ru'
import { Currency, DateRangePicker, FilterBar, LoadMoreRows } from '../index'
import { IPlayer, IPlayerTransaction } from '../../api/players'
import { dateFallback, EDateFormat, formatDate } from '../../utils/date'
import { mrtDateTimeFilter } from '../../services/mrtFilters'
import useInfiniteQueryPlayerTransactions from '../../hooks/useInfiniteQueryPlayerTransactions'
import { moneyFormat } from '../../utils/money'
import { playerTransactionEntityAction, playerTransactionEntityType } from '../../constants/playerTransactionEntity'

type PlayerTransactionsProps = Pick<IPlayer, 'id' | 'login' | 'currency'>

const fetchSize = 15

const dateFormat = EDateFormat.yyy_MM_dd_HH_mm

const PlayerTransactions: FC<PlayerTransactionsProps> = observer((props) => {
  const { id, currency } = props

  const { ref, inView } = useInView()

  const [range, setRange] = useState<DateRange>({
    from: dateFallback(startOfMonth(new Date())),
    to: dateFallback(endOfDay(new Date())),
  })

  const queryDateFilters = {
    ...(range.from &&
      range.to && {
        from: format(range.from, dateFormat),
        to: format(range.to, dateFormat),
      }),
  }

  const { data, fetchNextPage, isFetchingNextPage, isFetching, isError, isLoading, hasNextPage } =
    useInfiniteQueryPlayerTransactions(
      id,
      {
        limit: fetchSize,
        offset: 0,
        ...queryDateFilters,
      },
      {
        enabled: !!id && !!range.from && !!range.to,
        onError: (err) => toast.error(`${err}`),
        keepPreviousData: true,
        refetchOnWindowFocus: false,
      }
    )

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage()
    }
  }, [inView, hasNextPage])

  const transactions = data ? data.pages.flatMap((page) => page.rows) : []

  const columns: MRT_ColumnDef<IPlayerTransaction>[] = [
    {
      accessorKey: 'createdAt',
      header: 'Время',
      sortingFn: 'datetime',
      enableColumnFilter: false,
      Cell: ({ cell }) => {
        const date: Date | null = cell.getValue<Date>()
        return formatDate(new Date(date), EDateFormat.dd_MM_yyy_HH_mm)
      },
    },
    {
      accessorKey: 'entityId',
      header: 'ID',
    },
    {
      accessorKey: 'entityType',
      accessorFn: (row) => {
        return playerTransactionEntityType[row.entityType]
      },
      header: 'Исполнитель',
    },
    {
      accessorKey: 'amount',
      header: 'Сумма',
      Cell: ({ cell }) => {
        const value = cell.getValue<string>() || '0'
        return (
          <>
            {moneyFormat(value)} <Currency currency={currency} />
          </>
        )
      },
    },
    {
      accessorFn: (row) => {
        return playerTransactionEntityAction[row.action]
      },
      header: 'Действие',
    },
  ]

  return (
    <Box>
      <FilterBar>
        <Stack gap={2} direction="row" alignItems="center">
          <DateRangePicker
            from={range.from}
            to={range.to}
            onChangeRange={(rangeDate) => {
              setRange({
                from: rangeDate?.from,
                to: rangeDate?.to ? endOfDay(rangeDate?.to) : undefined,
              })
            }}
          />
        </Stack>
      </FilterBar>

      <MaterialReactTable
        localization={MRT_Localization_RU}
        enableFullScreenToggle={false}
        enableGlobalFilter={false}
        enableFilterMatchHighlighting={false}
        filterFns={{
          mrtDateTimeFilter,
        }}
        state={{
          isLoading,
          showAlertBanner: isError,
          showProgressBars: isFetching,
        }}
        initialState={{
          showColumnFilters: true,
          density: 'comfortable',
        }}
        columns={columns}
        data={transactions || []}
        enablePagination={false}
        renderTopToolbarCustomActions={({ table }) => {
          return (
            <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}>
              <Button size="small" variant="outlined" onClick={() => table.resetColumnFilters()}>
                Сбросить фильтры
              </Button>
            </Box>
          )
        }}
        renderBottomToolbarCustomActions={() => {
          return (
            <LoadMoreRows
              ref={ref}
              rows={transactions.length} // historyRows
              totalRows={data?.pages[0].count || 0} // historyTotalRows
              isLoading={isFetchingNextPage}
              onLoad={() => {
                fetchNextPage()
              }}
            />
          )
        }}
      />
    </Box>
  )
})

export default PlayerTransactions
