import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { FC, ReactNode } from 'react'

type AlertDialogProps = {
  open: boolean
  title?: string
  content?: string | ReactNode
  onAgree: () => void
  onDisagree: () => void
}

const AlertDialog: FC<AlertDialogProps> = ({ open, title, content, onAgree, onDisagree }) => {
  return (
    <Dialog
      open={open}
      onClose={onDisagree}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={onDisagree} variant="outlined">
          Отмена
        </Button>
        <Button onClick={onAgree} variant="contained" autoFocus>
          Да
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AlertDialog
