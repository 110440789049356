import { FC } from 'react'
import { Box, Typography } from '@mui/material'

const AccessDenied: FC = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        p: 4,
      }}>
      <div>
        <Typography textAlign="center" variant="h2">
          403
        </Typography>
        <Typography textAlign="center" variant="h2">
          Доступ запрещен
        </Typography>
      </div>
    </Box>
  )
}

export default AccessDenied
