import { InfiniteData, useInfiniteQuery, UseInfiniteQueryOptions, UseInfiniteQueryResult } from 'react-query'
import { toast } from 'react-toastify'
import { ApiPlayers } from '../../api'
import { IGetPlayerFinances } from '../../api/players'
import { IQueryParams } from '../../constants/queryParams'

const useInfiniteQueryFinancesByPlayerId = (
  playerId: string,
  queryParams: Partial<IQueryParams>,
  config?: UseInfiniteQueryOptions<IGetPlayerFinances>
): UseInfiniteQueryResult<IGetPlayerFinances> => {
  return useInfiniteQuery<IGetPlayerFinances>(
    [
      'get infinite finances history player by id',
      playerId,
      {
        order: queryParams.order,
        limit: queryParams.limit,
        offset: queryParams.offset,
      },
    ],
    async ({ pageParam = 0 }) => {
      return ApiPlayers.getFinanceByPlayerId({
        playerId,
        ...queryParams,
        offset: pageParam,
      })
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const { count } = lastPage
        const totalLoadedItems = allPages.flatMap((page) => page.rows).length
        if (totalLoadedItems < count) {
          return totalLoadedItems
        }
        return undefined
      },
      select: (data: InfiniteData<IGetPlayerFinances>) => {
        return {
          ...data,
          totalCount: data.pages[0]?.count || 0,
          rows: data?.pages.flatMap((page) => page.rows) || [],
        }
      },
      ...config,
      staleTime: 30 * 1000,
      onError: (err) => toast.error(`${err}`),
    }
  )
}

export default useInfiniteQueryFinancesByPlayerId
