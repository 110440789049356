import { makeAutoObservable } from 'mobx'
import { ECurrency } from '@lbs/currency'
import { EPlayerStatus } from '@lbs/status'
import { IPlayer, IPlayerCoupon, IPlayerPhone } from '../api/players'

export interface IPlayerEdit extends IPlayer {}

export default class PlayerEditStore implements IPlayerEdit {
  id = ''

  login = ''

  phones: IPlayerPhone[] = []

  status = EPlayerStatus.NOT_ACTIVATED

  activatedAt = null

  updatedAt = ''

  createdAt = ''

  password = ''

  coupons: IPlayerCoupon[] = []

  currency = ECurrency.RUB

  rouletteRatio = 0

  balance = '0'

  availableBalance = '0'

  constructor(payload: Partial<IPlayerEdit>) {
    makeAutoObservable(this)

    Object.assign(this, payload)
  }

  get isBlocked(): boolean {
    return this.status === EPlayerStatus.BLOCKED
  }

  get isDeleted(): boolean {
    return this.status === EPlayerStatus.DELETED
  }

  get isActive(): boolean {
    return this.status === EPlayerStatus.ACTIVE
  }

  get isNotActive(): boolean {
    return this.status === EPlayerStatus.NOT_ACTIVATED
  }

  updateFields = (payload: Partial<IPlayerEdit>): void => {
    Object.assign(this, payload)
  }

  updateCoupons = (payload: IPlayerCoupon[]): void => {
    this.coupons = [...payload]
  }

  changeNumber = (tel: string, index: number): void => {
    this.phones[index] = {
      number: tel,
      priority: index + 1,
    }
  }
}
