// `${CONFIG.mediaServer.url}/WebRTCAppEE/rest/v2/broadcasts/list/0/50`)
import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.REACT_APP_ANT_MEDIA as string,
  timeout: 2000,
})

export interface IStream {
  streamId: string
  status: string
  playListStatus: any
  type: string
  publishType: any
  name: string
  description: any
  publish: boolean
  date: number
  plannedStartDate: number
  plannedEndDate: number
  duration: number
  endPointList: any
  playListItemList: any[]
  publicStream: boolean
  is360: boolean
  listenerHookURL: any
  category: any
  ipAddr: any
  username: any
  password: any
  quality: any
  speed: number
  streamUrl: any
  originAdress: string
  mp4Enabled: number
  webMEnabled: number
  expireDurationMS: number
  rtmpURL: string
  zombi: boolean
  pendingPacketSize: number
  hlsViewerCount: number
  dashViewerCount: number
  webRTCViewerCount: number
  rtmpViewerCount: number
  startTime: number
  receivedBytes: number
  bitrate: number
  userAgent: string
  latitude: any
  longitude: any
  altitude: any
  mainTrackStreamId: any
  subTrackStreamIds: any[]
  absoluteStartTimeMs: number
  webRTCViewerLimit: number
  hlsViewerLimit: number
  dashViewerLimit: number
  subFolder: any
  currentPlayIndex: number
  metaData: any
  playlistLoopEnabled: boolean
  updateTime: number
}

class AntMedia {
  getList = async (): Promise<IStream[]> => {
    const response = await instance.get('/WebRTCAppEE/rest/v2/broadcasts/list/0/50')

    return response.data
  }
}

export default new AntMedia()
