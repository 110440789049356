import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useStore } from '../../stores'
import routes from '../../router/routes'

const AuthLayout: FC = () => {
  const { loginStore } = useStore()

  return loginStore.isAuthenticated ? <Navigate to={routes.gameSchedule} /> : <Outlet />
}

export default observer(AuthLayout)
