import { FC, ReactElement } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import { OverridableStringUnion } from '@mui/types'
import { IconButtonPropsColorOverrides } from '@mui/material/IconButton/IconButton'

type CrudIconBtnProps = {
  disabled?: boolean
  onClick?: () => void
  tooltip: string
  color?: OverridableStringUnion<
    'inherit' | 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
    IconButtonPropsColorOverrides
  >
  icon: ReactElement<any, any>
}

const CrudIconBtn: FC<CrudIconBtnProps> = ({ onClick, disabled, tooltip, icon, color }) => {
  return (
    <IconButton
      color={color || 'default'}
      disabled={disabled}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()

        if (onClick) onClick()
      }}>
      <Tooltip title={tooltip}>{icon}</Tooltip>
    </IconButton>
  )
}

export default CrudIconBtn
