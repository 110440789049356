import { FC } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'

type SaveBtnProps = {
  disabled?: boolean
  onClick?: () => void
}

const SaveBtn: FC<SaveBtnProps> = ({ onClick, disabled }) => {
  return (
    <IconButton
      color="primary"
      disabled={disabled}
      onClick={(e) => {
        e.preventDefault()

        if (onClick) onClick()
      }}>
      <Tooltip title="Сохранить">
        <SaveIcon />
      </Tooltip>
    </IconButton>
  )
}

export default SaveBtn
