import { observer } from 'mobx-react-lite'
import React, { FC, useState } from 'react'
import { Box, Button, Container, Grid, TextField, InputAdornment } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { toast } from 'react-toastify'
import { useStore } from '../../stores'
import { StyledContainer, StyledImg } from './styles'

const Login: FC = observer(() => {
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [isError, setIsError] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const { loginStore } = useStore()

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()

    try {
      await loginStore.login({
        username,
        password,
      })
    } catch (err) {
      toast.error(`${err}`, {
        progress: 0,
      })
      setIsError(true)
    }
  }

  return (
    <StyledContainer>
      <Container component="main" maxWidth="xs">
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <StyledImg src={`${process.env.PUBLIC_URL}/img/logo.png`} alt="logo" />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={username}
                required
                onChange={(e) => {
                  setUsername(e.target.value)

                  setIsError(false)
                }}
                fullWidth
                label="Пользователь"
                error={isError}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        cursor: 'pointer',
                      }}
                      onClick={(e) => {
                        e.preventDefault()

                        setShowPassword(!showPassword)
                      }}>
                      <VisibilityIcon />
                    </InputAdornment>
                  ),
                }}
                required
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value)

                  setIsError(false)
                }}
                fullWidth
                type={showPassword ? 'text' : 'password'}
                label="Пароль"
                error={isError}
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" fullWidth variant="contained" size="large">
                Войти
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </StyledContainer>
  )
})

export default Login
