import { FC, useEffect, useState } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { toast } from 'react-toastify'
import { useStore } from '../../stores'
import { WrapDrawerEditForm } from '../index'
import LimitData from '../LimitData'
import LimitStore from '../../stores/Limit.store'
import { pickFieldsLimit } from '../LimitCreate'

type LimitEditProps = {
  limitId: string
}

const LimitEdit: FC<LimitEditProps> = observer(({ limitId }) => {
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const { limitsStore, drawerCRUDStore } = useStore()

  const limit = useLocalObservable(() => {
    return new LimitStore({})
  })

  useEffect(() => {
    ;(async () => {
      const data = await limitsStore.getLimitById(limitId)

      limit.updateFields({
        ...data,
      })
    })()
    return () => {
      ;(async () => {
        await limitsStore.getLimits()
      })()
    }
  }, [])

  const pickFields = pickFieldsLimit(limit.isCardGame, limit.isRouletteGame)

  return (
    <WrapDrawerEditForm
      title={`Лимит: ${limit.name}`}
      isBusy={limit.isBusy}
      busyText="Лимит закреплен за активной игрой"
      isShowEditBtn={!isEdit}
      isShowSaveBtn={isEdit}
      isShowBlockBtn={!limit.isBlocked}
      isShowUnBlockBtn={limit.isBlocked}
      onEdit={() => {
        setIsEdit(true)
      }}
      onSave={async () => {
        try {
          const data = await limitsStore.onUpdateById(limit.id, {
            name: limit.name,
            nameGame: limit.nameGame,
            minimum: limit.minimum,
            maximum: limit.maximum,
            maximumPayout: limit?.maximumPayout,
            minBetEvenChance: limit?.minBetEvenChance,
            maxBetEvenChance: limit?.maxBetEvenChance,
            minBetColDozen: limit?.minBetColDozen,
            maxBetColDozen: limit?.maxBetColDozen,
          })
          limit.updateFields({ ...data })
          toast.success(`Лимит "${limit.name}" успешно изменен`)
        } catch (e) {
          toast.error(`${e}`)
        } finally {
          setIsEdit(false)
        }
      }}
      onDelete={async () => {
        try {
          await limitsStore.onDeleteById(limit.id)
          toast.success(`Лимит ${limit.name} успешно удален`)
          drawerCRUDStore.resetView()
        } catch (e) {
          toast.error(`${e}`)
        }
      }}
      onBlock={async () => {
        try {
          const data = await limitsStore.onBlockById(limit.id)
          limit.updateFields({ ...data })
          toast.success(`Лимит ${limit.name} успешно заблокирован`)
        } catch (e) {
          toast.error(`${e}`)
        }
      }}
      onUnBlock={async () => {
        try {
          const data = await limitsStore.onUnBlockById(limit.id)
          limit.updateFields({ ...data })
          toast.success(`Лимит ${limit.name} успешно разблокирован`)
        } catch (e) {
          toast.error(`${e}`)
        }
      }}>
      <LimitData limit={limit} pickFields={['status', ...pickFields]} disabledAll={!isEdit} />
    </WrapDrawerEditForm>
  )
})

export default LimitEdit
