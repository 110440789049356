import { makeAutoObservable, runInAction } from 'mobx'
import Limits, { ILimit, ILimits } from '../api/limits'
import LimitStore from './Limit.store'
import { ELimitStatus } from '../constants/limit.status'
import { EGameName } from '../constants/gameNames'

export class LimitsStore implements ILimits {
  count: number = 0

  rows: ILimit[] = []

  limit: LimitStore | null = null

  constructor() {
    makeAutoObservable(this)
  }

  getLimits = async (): Promise<void> => {
    try {
      const data = await Limits.getLimits()

      runInAction(() => {
        this.count = data.count

        this.rows = [...data.rows]
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  getLimitsRoulette = async (): Promise<Array<ILimit>> => {
    const data = await Limits.getLimits()

    return data.rows.filter((item) => item.nameGame === EGameName.ROULETTE)
  }

  getLimitById = async (id: string): Promise<ILimit> => {
    return Limits.getLimitById(id)
  }

  onCreate = async (data: Partial<ILimit>): Promise<ILimit> => {
    return Limits.createLimit(data)
  }

  onUpdateById = async (id: string, payload: Partial<ILimit>): Promise<ILimit> => {
    return Limits.updateLimitById(id, payload)
  }

  onDeleteById = async (id: string): Promise<void> => {
    return Limits.deleteLimitById(id)
  }

  onBlockById = async (id: string): Promise<ILimit> => {
    return Limits.updateLimitById(id, {
      status: ELimitStatus.BLOCKED,
    })
  }

  onUnBlockById = async (id: string): Promise<ILimit> => {
    return Limits.updateLimitById(id, {
      status: ELimitStatus.ACCEPTED,
    })
  }
}
