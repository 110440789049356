import { autorun, makeAutoObservable, runInAction } from 'mobx'
import { toast } from 'react-toastify'
import GameSchedule, { ICreateGame, IGame, IGameSchedulePayload, IGameSettings } from '../api/games'
import { EGameStatus } from '../constants/game.status'
import { loginStore } from './index'

export class GamesStore {
  limit: number = 10

  offset: number = 0

  totalRows: number = 0

  rows: IGame[] = []

  acceptedDealer: string = ''

  status: EGameStatus[] = []

  constructor() {
    makeAutoObservable(this)

    autorun(() => {
      if (!loginStore.isAuthenticated) {
        runInAction(() => {
          this.totalRows = 0
          this.rows = []
          this.acceptedDealer = ''
          this.offset = 0
        })
      }
    })
  }

  getGames = async (payload?: IGameSchedulePayload): Promise<void> => {
    try {
      const { limit, offset, acceptedDealer } = this
      const data = await GameSchedule.getGames({
        limit,
        offset,
        acceptedDealer,
        ...payload,
      })

      runInAction(() => {
        this.totalRows = data.countAll
        this.rows = [...this.rows, ...data.rows]
      })
    } catch (e) {
      toast.error(`Failed load games: ${e}`)
    }
  }

  getRefreshGames = async (payload: IGameSchedulePayload): Promise<void> => {
    try {
      const data = await GameSchedule.getGames({
        ...payload,
      })

      runInAction(() => {
        this.totalRows = data.countAll
        this.rows = [...data.rows]
      })
    } catch (e) {
      toast.error(`Failed load games: ${e}`)
    }
  }

  onLoadMore = (): void => {
    this.offset += this.limit

    this.getGames()
  }

  setAcceptedDealer = (data: string): void => {
    this.acceptedDealer = data
  }

  onCreateGame = async (data: ICreateGame): Promise<void> => {
    return GameSchedule.onCreateGame(data)
  }

  onUpdateGameSettingsById = async (id: string, data: IGameSettings): Promise<void> => {
    return GameSchedule.updateGameSettingsById(id, data)
  }

  onCancelGameById = async (id: string): Promise<void> => {
    return GameSchedule.canceledGameById(id)
  }

  onFinishGameById = async (id: string): Promise<void> => {
    return GameSchedule.finishGameById(id)
  }

  getGameById = async (id: string): Promise<IGame> => {
    return GameSchedule.getGameById(id)
  }
}
