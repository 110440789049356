import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { EColorLastBalls } from '../../constants/colorLastBalls'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px 0;
`

export const WrapperModal = styled(Wrapper)`
  padding: 0;
`

export const RouletteTable = styled.div`
  width: 100%;
  display: inline-grid;
  grid-template-columns: ${100 / 13}% 1fr;
  gap: 8px;
`

export const StyledRows = styled.div`
  display: inline-grid;
  width: 100%;
  gap: 8px;
`

export const StyledRow = styled.div`
  display: inline-grid;
  width: 100%;
  gap: 8px;
  grid-template-columns: repeat(12, 1fr);
`

export const StyledCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85px;
  border: 1px solid #202e44;
  transition: all 0.2s;
  cursor: pointer;
  padding: 8px;
  color: white;
  opacity: 0.5;
  font-size: 17px;

  &:hover {
    background: #1e2b40;
    border: 1px solid #627796;
    opacity: 1;
    z-index: 1;
  }
`

export const StyledCellBtn = styled.div<{
  color: 'red' | 'black' | 'green'
}>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-radius: 4px;
  background-color: ${(props) => {
    switch (props.color) {
      case 'red':
        return '#D02645'
      case 'black':
        return '#000'
      case 'green':
        return '#779C51'
      default:
        return ''
    }
  }};
`

export const StyledCellBtnConfirm = styled(StyledCellBtn)`
  width: 64px;
  height: 64px;
`

export const StyledRowZero = styled.div``
export const StyledCellZero = styled(StyledCell)`
  height: 100%;
`

export const Bar = styled.div`
  background: #152032;
  border-radius: 4px;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  width: 100%;
  color: white;
  min-height: 50px;
`

export const InfoBars = styled.div`
  margin-top: 16px;
  display: grid;
  gap: 16px;
  width: 100%;
  grid-template-columns: 1fr 1fr;
`

export const InfoBar = styled.div`
  width: 100%;
`

export const InfoTitle = styled.div`
  color: #ffcb4b;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
`

export const InfoBarContent = styled.div`
  padding: 8px;
  height: 260px;
  width: 100%;
  overflow-y: auto;
  background: #152032;
  border: 1px solid #24334b;
  border-radius: 8px;
  color: white;
`

export const BallsHistory = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`

export const Ball = styled.div<{
  type: EColorLastBalls
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  font-weight: bold;

  // eslint-disable-next-line consistent-return
  ${(props) => {
    if (props.type === EColorLastBalls.BLACK) {
      return css({
        backgroundColor: '#000',
      })
    }

    if (props.type === EColorLastBalls.RED) {
      return css({
        backgroundColor: '#D02645',
      })
    }

    if (props.type === EColorLastBalls.GREEN) {
      return css({
        backgroundColor: '#779C51',
      })
    }

    if (props.type === EColorLastBalls.NO_SPIN) {
      return css({
        border: '1px solid #1E2B40',
      })
    }

    return css()
  }}
`
