import http from '../../services/http'
import { EGameStatus } from '../../constants/game.status'
import { EGameVisibility } from '../../constants/gameVisibility'
import { EGameName } from '../../constants/gameNames'
import { ESort } from '../../constants/sort'
import { IOperator } from '../operators'

export interface IGame {
  id: string
  status: EGameStatus
  comments: any
  gameSettings: IGameSettings
  startsAt: string
  endsAt: any
  acceptedDealer: string
  createdBy: ICreatedBy
  createdFor: any[]
  createdAt: string
  updatedAt: string
  operator: IOperator
}

export interface IGameSettings {
  timer: ITimer
  limitId: string
  standId: string
  game_name: EGameName
  game_type: EGameVisibility
  ipCyclops?: string
  limit?: {
    id: string
    name: string
  }
  stand?: {
    id: string
    name: string
  }
}

export interface ITimer {
  toSpin: number
  techPause: number
  toEndBets: number
}

export interface ICreatedBy {}

export interface IData {
  techCode: string
}

export interface IGames {
  count: number
  countAll: number
  rows: IGame[]
}

export interface IGameSchedulePayload {
  sort?: string
  order?: ESort
  limit?: number
  offset?: number
  gameName?: EGameName
  dateFrom?: string // 26.07.2023
  dateTo?: string // 26.07.2023
  status?: EGameStatus[]
  acceptedDealer?: string
}

export interface ICreateGame {
  acceptedDealer: string // dealer for game
  status: EGameStatus
  startsAt: string // Date
  createFor: []
  gameSettings: {
    game_name: EGameName
    game_type: EGameVisibility
    limitId: string
    standId: string
    timer: ITimer
    cyclops?: boolean
    ipCyclops?: string
  }
}

class Games {
  prefix: string = '/api/gameSchedule'

  getGames = async ({
    sort = 'startsAt',
    order = ESort.DESC,
    limit = 10,
    offset = 0,
    gameName,
    dateFrom,
    dateTo,
    status,
    acceptedDealer,
  }: IGameSchedulePayload): Promise<IGames> => {
    // http://api:3000/api/gameSchedule?
    // sort=startsAt
    // &order=DESC
    // &limit=10
    // &gameName=roulette
    // &dateFrom=26.07.2023
    // &dateTo=26.07.2023
    // &status=240

    const response = await http.get(`${this.prefix}`, {
      params: {
        sort,
        order,
        limit,
        offset,
        gameName,
        status,
        dateFrom,
        dateTo,
        acceptedDealer,
      },
    })

    return response.data
  }

  onCreateGame = async (data: ICreateGame): Promise<void> => {
    const response = await http.post(`${this.prefix}`, {
      ...data,
    })

    return response.data
  }

  canceledGameById = async (id: string): Promise<void> => {
    return http.put(`${this.prefix}/${id}`, {
      action: 'cancelByManager',
    })
  }

  finishGameById = async (id: string): Promise<void> => {
    return http.put(`${this.prefix}/${id}`, {
      action: 'finish',
    })
  }

  getGameById = async (id: string): Promise<IGame> => {
    const response = await http.get(`${this.prefix}/${id}`)

    return response.data
  }

  updateGameSettingsById = async (id: string, data: IGameSettings): Promise<void> => {
    return http.put(`${this.prefix}/${id}`, {
      action: 'updateGameSettings',
      ...data,
    })
  }
}

export default new Games()
