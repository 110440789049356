import { makeAutoObservable } from 'mobx'

export enum EDrawerMainNavStore {
  FULL = '240px',
  COMPACT = '60px',
  HIDDEN = '0px',
}

export type TView = 'compact' | 'full' | 'hidden'

export class DrawerMainNavStore {
  view: EDrawerMainNavStore = EDrawerMainNavStore.COMPACT

  constructor() {
    makeAutoObservable(this)
  }

  get isFullView(): boolean {
    return this.view === EDrawerMainNavStore.FULL
  }

  changeView = (data: EDrawerMainNavStore): void => {
    this.view = data
  }

  toggleView = (): void => {
    this.view = this.view === EDrawerMainNavStore.COMPACT ? EDrawerMainNavStore.FULL : EDrawerMainNavStore.COMPACT
  }
}
