import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Navigate, Outlet } from 'react-router-dom'
import { useStore } from '../stores'
import routes from './routes'

const PrivateRoute: FC = observer(() => {
  const { loginStore } = useStore()

  return loginStore.isAuthenticated ? <Outlet /> : <Navigate to={routes.login} />
})

export default PrivateRoute
