export enum EGameStatus {
  DELETED = 100,
  CANCELED_BY_USER = 130,
  CANCELED_BY_MANAGER = 140,
  CREATED_BY_USER = 210,
  CREATED_BY_MANAGER = 220,
  IN_PROCESSING = 230,
  CONFIRMED = 240,
  STARTED = 250,
  FINISHED = 290,
}

export const gameStatusesArr = [
  {
    code: EGameStatus.DELETED,
    name: 'Удалена',
  },
  {
    code: EGameStatus.CANCELED_BY_USER,
    name: 'Отменена пользователем',
  },
  {
    code: EGameStatus.CANCELED_BY_MANAGER,
    name: 'Отменена менеджером',
  },
  {
    code: EGameStatus.CREATED_BY_USER,
    name: 'Создана пользователем',
  },
  {
    code: EGameStatus.CREATED_BY_MANAGER,
    name: 'Создана менеджером',
  },
  {
    code: EGameStatus.IN_PROCESSING,
    name: 'В обработке',
  },
  {
    code: EGameStatus.CONFIRMED,
    name: 'Подтверждена',
  },
  {
    code: EGameStatus.STARTED,
    name: 'Начата',
  },
  {
    code: EGameStatus.FINISHED,
    name: 'Закончена',
  },
]

export const gameStatuses: {
  [key: string]: string
} = {
  [EGameStatus.DELETED]: 'Удалена',
  [EGameStatus.CREATED_BY_MANAGER]: 'Создана менеджером',
  [EGameStatus.CONFIRMED]: 'Подтверждена',
  [EGameStatus.STARTED]: 'Начата',
  [EGameStatus.FINISHED]: 'Закончена',
}

export const gameStatusesOptions = Object.keys(gameStatuses).map((item) => {
  return {
    value: item,
    label: gameStatuses[item],
  }
})

export const gameStatusName = (status: EGameStatus): string => {
  const subject = gameStatusesArr.find(({ code }) => code === status)

  return subject?.name || ''
}
