export enum ETypePaymentsByOperator {
  DROP = 'drop',
  ROLLBACK = 'rollback',
  CREDIT = 'credit',
  GIFT = 'gift',
}

export const typePaymentByOperator: {
  [key: string]: string
} = {
  [ETypePaymentsByOperator.DROP]: 'Дроп',
  [ETypePaymentsByOperator.ROLLBACK]: 'Откат',
  [ETypePaymentsByOperator.CREDIT]: 'Кредит',
  [ETypePaymentsByOperator.GIFT]: 'Подарок',
}

export const typePaymentByOperatorOptions = Object.values(ETypePaymentsByOperator).map((item) => ({
  value: item,
  label: typePaymentByOperator[item],
}))
