import { FC, ReactNode } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import SaveBtn from '../SaveBtn'

type WrapDrawerCreateFormProps = {
  title: string
  onSave?: () => void
  disabledSave?: boolean
  children: ReactNode
}

const WrapDrawerCreateForm: FC<WrapDrawerCreateFormProps> = ({ onSave, disabledSave, children, title }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: '1fr auto',
        height: '100%',
        gap: 1,
        minWidth: '240px',
        width: '280px',
        p: 2,
      }}>
      <Box>
        <Typography
          variant="h5"
          sx={{
            textAlign: 'center',
            mb: 1,
          }}>
          {title}
        </Typography>
        <Stack direction="column" gap={2}>
          {children}
        </Stack>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <SaveBtn
          disabled={disabledSave}
          onClick={() => {
            if (onSave) onSave()
          }}
        />
      </Box>
    </Box>
  )
}

export default WrapDrawerCreateForm
