import { FC, Fragment, ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import { TextField } from '@mui/material'
import LimitStore, { limitFields } from '../../stores/Limit.store'
import { formPropsDrawerCrud } from '../../constants/formPropsDrawerCrud'
import { limitStatusNames } from '../../constants/limit.status'
import { EGameName, gameNamesOptions } from '../../constants/gameNames'
import { Select } from '../index'

type LimitDataProps = {
  limit: LimitStore
  disabledAll?: boolean
  pickFields: Array<keyof LimitStore>
}
const LimitData: FC<LimitDataProps> = observer(({ limit, disabledAll, pickFields }) => {
  const fieldProps = {
    ...formPropsDrawerCrud,
    ...(disabledAll && { disabled: disabledAll }),
  }

  const fieldComponents: Partial<Record<keyof LimitStore, ReactNode>> = {
    id: <TextField {...fieldProps} disabled label={limitFields.id} value={limit.id} />,
    name: (
      <TextField
        {...fieldProps}
        label={limitFields.name}
        value={limit.name}
        onChange={(e) => {
          e.preventDefault()

          limit.updateFields({
            name: e.target.value,
          })
        }}
      />
    ),
    status: <TextField {...fieldProps} disabled label={limitFields.status} value={limitStatusNames[limit.status]} />,
    nameGame: (
      <Select
        {...fieldProps}
        disabled
        id="select-game-for-limit"
        label={limitFields.nameGame}
        value={limit.nameGame}
        options={gameNamesOptions}
        onChange={(e) => {
          limit.updateFields({
            nameGame: e as EGameName,
          })
        }}
      />
    ),
    minimum: (
      <TextField
        {...fieldProps}
        type="number"
        label={limitFields.minimum}
        value={limit.minimum}
        onChange={(e) => {
          e.preventDefault()

          limit.changeMinimum(Number(e.target.value))
        }}
      />
    ),
    maximum: (
      <TextField
        {...fieldProps}
        type="number"
        label={limitFields.maximum}
        value={limit.maximum}
        onChange={(e) => {
          e.preventDefault()

          limit.changeMaximum(Number(e.target.value))
        }}
      />
    ),
    maximumPayout: (
      <TextField
        {...fieldProps}
        type="number"
        label={limitFields.maximumPayout}
        value={limit.maximumPayout}
        onChange={(e) => {
          e.preventDefault()

          const maximumPayout = Math.max(1, Number(e.target.value))

          limit.updateFields({
            maximumPayout,
          })
        }}
      />
    ),
    minBetColDozen: (
      <TextField
        {...fieldProps}
        type="number"
        label={limitFields.minBetColDozen}
        value={limit.minBetColDozen}
        onChange={(e) => {
          e.preventDefault()

          const minBetColDozen = Math.max(1, Number(e.target.value))

          limit.updateFields({
            minBetColDozen,
          })
        }}
      />
    ),
    maxBetColDozen: (
      <TextField
        {...fieldProps}
        type="number"
        label={limitFields.maxBetColDozen}
        value={limit.maxBetColDozen}
        onChange={(e) => {
          e.preventDefault()

          const maxBetColDozen = Math.max(1, Number(e.target.value))

          limit.updateFields({
            maxBetColDozen,
          })
        }}
      />
    ),
    minBetEvenChance: (
      <TextField
        {...fieldProps}
        type="number"
        label={limitFields.minBetEvenChance}
        value={limit.minBetEvenChance}
        onChange={(e) => {
          e.preventDefault()

          const minBetEvenChance = Math.max(1, Number(e.target.value))

          limit.updateFields({
            minBetEvenChance,
          })
        }}
      />
    ),
    maxBetEvenChance: (
      <TextField
        {...fieldProps}
        type="number"
        label={limitFields.maxBetEvenChance}
        value={limit.maxBetEvenChance}
        onChange={(e) => {
          e.preventDefault()

          const maxBetEvenChance = Math.max(1, Number(e.target.value))

          limit.updateFields({
            maxBetEvenChance,
          })
        }}
      />
    ),
  }
  return (
    <>
      {pickFields.map((item) => {
        return <Fragment key={item}>{fieldComponents[item]}</Fragment>
      })}
    </>
  )
})

export default LimitData
