import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { useMountedState } from 'react-use'
import { Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useStore } from '../../stores'
import { FilterBar } from '../../components'
import { standStatusNames } from '../../constants/stand.status'
import { gameNames } from '../../constants/gameNames'
import { EDrawerView } from '../../stores/DrawerCRUD.store'

const tHead = ['Название', 'Видеопоток', 'Игра', 'Статус']

const Stands: FC = observer(() => {
  const isMounted = useMountedState()
  const { standsStore, drawerCRUDStore, antMediaStore } = useStore()

  useEffect(() => {
    ;(async () => {
      if (!isMounted()) return
      await antMediaStore.getList()
      await standsStore.getStands()
    })()
  }, [])

  return (
    <Box>
      <FilterBar>
        <IconButton
          onClick={(e) => {
            e.preventDefault()

            drawerCRUDStore.changeView(EDrawerView.STAND_CREATE)
          }}>
          <AddIcon />
        </IconButton>
      </FilterBar>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {tHead.map((item) => {
                return <TableCell key={item}>{item}</TableCell>
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {standsStore.rows.map((item) => {
              return (
                <TableRow
                  key={item.id}
                  hover
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={(e) => {
                    e.preventDefault()

                    drawerCRUDStore.changeView(EDrawerView.STAND_EDIT, item.id)
                  }}>
                  <TableCell component="th" scope="row">
                    {item.name}
                  </TableCell>
                  <TableCell>{item.data?.name || ''}</TableCell>
                  <TableCell>{gameNames[item.typeGame]}</TableCell>
                  <TableCell>{standStatusNames[item.status]}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
})

export default Stands
