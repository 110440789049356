export enum ERouletteGameStatus {
  PREPARING = 'preparing',
  START = 'start',
  SPIN = 'spin',
  BAll = 'ball',
  NO_SPIN = 'noSpin',
  RESULT = 'result',
  END = 'end',
  PAUSE = 'pause',
  END_BET = 'endBet',
  NEW_SESSION = 'newSession',
}
