import http from '../../services/http'
import { ILimit } from '../limits'
import { IStand } from '../stand'
import { IGame } from '../games'

export type TRouletteLastBall = number | 'no spin'
export type TRouletteLastBalls = Array<TRouletteLastBall>

export interface IGameRoulette {
  gameSchedule: IGame
  lastBalls: TRouletteLastBalls
  limits: ILimit
  stand: IStand
}

class Game {
  prefix: string = '/api/game'

  getGameById = async (id: string): Promise<IGameRoulette> => {
    const response = await http.get(`${this.prefix}/${id}`)

    return response.data
  }
}

export default new Game()
