import { EPlayerStatus } from '@lbs/status'

export const playerStatus: {
  [k: string]: string
} = {
  [EPlayerStatus.DELETED]: 'Удалён',
  [EPlayerStatus.BLOCKED]: 'Заблокирован',
  [EPlayerStatus.ACTIVE]: 'Активен',
  [EPlayerStatus.NOT_ACTIVATED]: 'Не активировался',
}

export const playerStatusOptions = Object.keys(playerStatus).map((item) => {
  return {
    value: item,
    label: playerStatus[item],
  }
})
