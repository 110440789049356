import { makeAutoObservable, runInAction } from 'mobx'
import { toast } from 'react-toastify'
import Operators, { IOperator, IOperators } from '../api/operators'
import { ERole } from '../constants/role'

export class OperatorsStore implements IOperators {
  count: number = 0

  rows: IOperator[] = []

  constructor() {
    makeAutoObservable(this)
  }

  getOperators = async (): Promise<void> => {
    try {
      const data = await Operators.getOperators({})

      runInAction(() => {
        this.count = data.count
        this.rows = [...data.rows]
      })
    } catch (e) {
      toast.error(`Failed load operators ${e}`)
    }
  }

  getDealers = async (): Promise<IOperators> => {
    return Operators.getOperators({ role: ERole.DEALER })
  }

  getOperatorById = async (id: string): Promise<IOperator> => {
    return Operators.getOperatorById(id)
  }
}
