import React, { FC } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { ru } from 'date-fns/locale/ru'
import { QueryClient, QueryClientProvider } from 'react-query'
import stores, { AppContext } from './stores'
import Router from './router/Router'

const queryClient = new QueryClient()

const App: FC = () => {
  return (
    <AppContext.Provider value={stores}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </LocalizationProvider>
        </SnackbarProvider>
      </QueryClientProvider>
    </AppContext.Provider>
  )
}

export default App
