import { FC, ReactNode } from 'react'
import AccessDenied from '../pages/AccessDenied'

type RoleAccessProps = {
  isAllowed: boolean
  children: ReactNode
}

const RoleAccess: FC<RoleAccessProps> = ({ isAllowed, children }) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return isAllowed ? <>{children}</> : <AccessDenied />
}

export default RoleAccess
