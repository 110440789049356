import { QueryOptions, useQuery, UseQueryResult } from 'react-query'
import { ApiOperators } from '../../api'
import { IOperators } from '../../api/operators'

const useQueryOperators = (options?: QueryOptions<IOperators, Error>): UseQueryResult<IOperators, Error> => {
  return useQuery<IOperators, Error>(
    ['operators'],
    async () => {
      return ApiOperators.getOperators({})
    },
    { ...options, staleTime: 1000 * 60 * 15, refetchOnWindowFocus: false }
  )
}

export default useQueryOperators
