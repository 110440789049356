export enum ESocketEvents {
  LIST_PLAYERS = 'listPlayers',
  REMOVE_PLAYER = 'removePlayer',
  NEW_OBSERVER = 'new-observer',
  CHAT = 'chat',
  CONNECT_ERROR = 'connect_error',
  GAME_ERROR = 'gameError',
  DISCONNECT = 'disconnect',
  CONNECTED = 'connected',
  TIMER = 'timer',
  PAUSE = 'pause',
  RESULT = 'result',
  SELECT_NUMBER_ROULETTE = 'selectNumberRoulette',
  NO_SPIN = 'noSpin',
  SPIN = 'spin',
  SESSION_END = 'sessionEnd',
  SESSION_NEW = 'newSessionOfRoulette',
  STOP_GAME = 'stopGame',
  START_GAME = 'startGame',
  SESSION_STATUS = 'sessionStatus',
}
