import http from '../../services/http'
import { ERole } from '../../constants/role'

// client_id: operator_place0
// grant_type: password
// username: dealer
// password: x6xQgf
// client_secret: theappwantstorule

const clientId = process.env.REACT_APP_CLIENT_ID as string
const clientSecret = process.env.REACT_APP_CLIENT_SECRET as string
const grantType: string = 'password'

export interface ILoginPayload {
  username: string
  password: string
}

export interface ILoginOperatorResponse {
  id: string
  login: string
  firstName: string
  lastName: string
  role: ERole
  status: number
}

export interface ILoginResponse {
  access_token: string
  refresh_token: string
  token_type: 'Bearer'
  token_life: number
  operator: ILoginOperatorResponse
}

export interface ILogoutPayload {
  token: string
}

export interface IRefreshTokenResponse {
  access_token: string
  refresh_token: string
  token_type: 'Bearer'
  token_life: number
  operator: string
}

class Security {
  prefix: string = '/security/operator'

  login = async (payload: ILoginPayload): Promise<ILoginResponse> => {
    const data = new URLSearchParams()

    data.append('client_id', clientId)
    data.append('grant_type', grantType)
    data.append('client_secret', clientSecret)
    data.append('username', payload.username)
    data.append('password', payload.password)

    const response = await http.post(`${this.prefix}/token`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })

    return response.data
  }

  refreshToken = async (payload: { refreshToken: string }): Promise<IRefreshTokenResponse> => {
    const data = new URLSearchParams()

    data.append('client_id', clientId)
    data.append('grant_type', 'refresh_token')
    data.append('client_secret', clientSecret)
    data.append('refresh_token', payload.refreshToken)

    const response = await http.post(`${this.prefix}/token`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })

    return response.data
  }

  logout = async (payload: ILogoutPayload): Promise<any> => {
    const data = new URLSearchParams()

    data.append('client_id', clientId)
    data.append('token', payload.token)
    data.append('token_type_hint', 'refresh_token')
    data.append('grant_type', 'revoke_token')
    data.append('client_secret', clientSecret)

    await http.post(`${this.prefix}/revoke`, data, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
  }
}

export default new Security()
