import { FC, useEffect, useState } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { AppBar, Box, Button, Container, Toolbar, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useStore } from '../../stores'
import { rouletteCells } from '../../constants/rouletteCells'
import {
  Ball,
  BallsHistory,
  Bar,
  InfoBar,
  InfoBarContent,
  InfoBars,
  InfoTitle,
  RouletteTable,
  StyledCell,
  StyledCellBtn,
  StyledCellZero,
  StyledRow,
  StyledRows,
  StyledRowZero,
  Wrapper,
} from './styles'
import ManageGameRouletteStore from '../../stores/ManageGameRoulette.store'
import Game from '../../api/game'
import { colorNumberRoulette } from '../../utils/is'
import { EColorLastBalls } from '../../constants/colorLastBalls'

type ManageRouletteProps = {}

const ManageGameRoulette: FC<ManageRouletteProps> = observer(() => {
  const [standName, setStandName] = useState('')
  const [isCyclops, setIsCyclops] = useState(false)
  const { gameId } = useParams()
  const { loginStore } = useStore()

  const game = useLocalObservable(() => {
    return new ManageGameRouletteStore({
      gameId: gameId as string,
      operatorId: loginStore.operator?.id || '',
    })
  })

  const handleSelectNumber = (num: number): void => {
    if (!game.isSpin) {
      toast('Действие не доступно', {
        type: 'error',
      })
      return
    }
    // eslint-disable-next-line no-restricted-globals
    const isConfirm = confirm(`На рулетке выпало ${num}?`)

    if (isConfirm) game.onSelectNumber(num)
  }

  const handleNoSpin = (): void => {
    // eslint-disable-next-line no-restricted-globals
    const isConfirm = confirm(`Ситуация нет спина, подтвердить?`)

    if (isConfirm) game.onNoSpin()
  }

  useEffect(() => {
    ;(async () => {
      try {
        const gameData = await Game.getGameById(gameId as string)

        game.setLastBalls(
          gameData.lastBalls.map((item) => {
            return {
              value: item,
              color: colorNumberRoulette(item),
            }
          })
        )

        setIsCyclops(Boolean(gameData.gameSchedule.gameSettings.ipCyclops))
        setStandName(gameData.stand.name)
        game.setStatus(gameData.gameSchedule.status)
        game.changeLimitMin(gameData.limits.minimum)
        game.changeLimitMax(gameData.limits.maximum)

        game.connectIo({
          gameId: gameId as string,
          accessToken: loginStore.accessToken,
        })
      } catch (e) {
        toast.error(`${e}`)
      }
    })()

    return () => {
      game.destroySocket()
    }
  }, [])

  return (
    <Box
      sx={{
        background: '#0C1525',
        width: '100%',
        minHeight: '100%',
        pointerEvents: loginStore.isDealer ? 'all' : 'none',
      }}>
      <AppBar position="static">
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'center',
              flexDirection: 'column',
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Typography variant="subtitle1">Лимиты стола: &nbsp;</Typography>
              <Typography variant="subtitle1">
                {game.limitMin} - {game.limitMax}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Typography variant="subtitle1">Название стола: &nbsp;</Typography>
              <Typography variant="subtitle1">{standName}</Typography>
            </Box>
          </Box>
          <Box>
            <Typography variant="h6">
              {loginStore.operator?.firstName} {loginStore.operator?.lastName}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>

      <Container maxWidth="xl">
        <Wrapper>
          <RouletteTable
            style={{
              pointerEvents: isCyclops ? 'none' : 'all',
            }}>
            <StyledRowZero>
              <StyledCellZero
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()

                  handleSelectNumber(0)
                }}>
                <StyledCellBtn color="green">0</StyledCellBtn>
              </StyledCellZero>
            </StyledRowZero>

            <StyledRows>
              <StyledRow>
                {rouletteCells.row1.map((item) => {
                  return (
                    <StyledCell
                      key={item.num}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()

                        handleSelectNumber(item.num)
                      }}>
                      <StyledCellBtn color={item.color}>{item.num}</StyledCellBtn>
                    </StyledCell>
                  )
                })}
              </StyledRow>
              <StyledRow>
                {rouletteCells.row2.map((item) => {
                  return (
                    <StyledCell
                      key={item.num}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()

                        handleSelectNumber(item.num)
                      }}>
                      <StyledCellBtn color={item.color}>{item.num}</StyledCellBtn>
                    </StyledCell>
                  )
                })}
              </StyledRow>
              <StyledRow>
                {rouletteCells.row3.map((item) => {
                  return (
                    <StyledCell
                      key={item.num}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()

                        handleSelectNumber(item.num)
                      }}>
                      <StyledCellBtn color={item.color}>{item.num}</StyledCellBtn>
                    </StyledCell>
                  )
                })}
              </StyledRow>
            </StyledRows>
          </RouletteTable>
          <Bar>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              {game.isTimer && (
                <Box>
                  <Typography variant="subtitle2">{`${game.remainingTime} сек. до спина`}</Typography>
                </Box>
              )}
              {game.isSpin && (
                <Box>
                  <Typography variant="subtitle2">Какое число выпало?</Typography>
                </Box>
              )}
              {game.isNotStartedGame && (
                <Box>
                  <Button
                    type="button"
                    variant="outlined"
                    size="small"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()

                      game.onStartGame()
                    }}>
                    Начать игру
                  </Button>
                </Box>
              )}
              {game.isSpin && !isCyclops && (
                <Box>
                  <Button
                    type="button"
                    variant="outlined"
                    size="small"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()

                      handleNoSpin()
                    }}>
                    Нет спина
                  </Button>
                </Box>
              )}
            </Box>
          </Bar>

          <InfoBars>
            <InfoBar>
              <InfoTitle>Список игроков</InfoTitle>
              <InfoBarContent>
                {[...game.players].map((item) => {
                  return (
                    <Typography key={item.id} variant="body1">
                      {item.name}
                    </Typography>
                  )
                })}
              </InfoBarContent>
            </InfoBar>
            <InfoBar>
              <InfoTitle>Чат</InfoTitle>
              <InfoBarContent />
            </InfoBar>
          </InfoBars>

          <Box
            sx={{
              mt: 2,
              width: '100%',
            }}>
            <InfoTitle>Ход игры</InfoTitle>
            <BallsHistory>
              <Ball type={EColorLastBalls.NO_SPIN}>?</Ball>
              {game.lastBalls.map((item, index) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <Ball key={index} type={item.color}>
                    {item.value === EColorLastBalls.NO_SPIN ? 'NS' : item.value}
                  </Ball>
                )
              })}
            </BallsHistory>
          </Box>
        </Wrapper>
      </Container>
    </Box>
  )
})

export default ManageGameRoulette
