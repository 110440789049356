import { createContext, useContext } from 'react'
import { ApplicationStore } from './Application.store'
import { LoginStore } from './Login.store'
import { StandsStore } from './Stands.store'
import { DrawerCRUDStore } from './DrawerCRUD.store'
import { LimitsStore } from './Limits.store'
import { OperatorsStore } from './Operators.store'
import { DrawerMainNavStore } from './DrawerMainNav.store'
import { RecordingsStore } from './Recordings.store'
import { GamesStore } from './Games.store'
import { AntMediaStore } from './AntMedia.store'
import { AlertDialogStore } from './AlertDialog.store'

export const drawerCRUDStore = new DrawerCRUDStore()
export const alertDialogStore = new AlertDialogStore()
export const drawerMainNavStore = new DrawerMainNavStore()
export const loginStore = new LoginStore()
export const gamesStore = new GamesStore()
export const standsStore = new StandsStore()
export const limitsStore = new LimitsStore()
export const operatorsStore = new OperatorsStore()
export const recordingsStore = new RecordingsStore()
export const antMediaStore = new AntMediaStore()
export const applicationStore = new ApplicationStore()

export const stores = {
  drawerCRUDStore,
  alertDialogStore,
  drawerMainNavStore,
  loginStore,
  gamesStore,
  standsStore,
  limitsStore,
  operatorsStore,
  recordingsStore,
  antMediaStore,
  applicationStore,
}

export const AppContext = createContext(stores)

export const useStore = (): typeof stores => useContext(AppContext) as typeof stores

export default stores
