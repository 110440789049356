export enum EStandStatus {
  DELETED = 100,
  BLOCKED = 150,
  ACCEPTED = 200,
  IN_GAME = 201,
}

export const standStatusNames: {
  [key: string]: string
} = {
  [EStandStatus.DELETED]: 'Удалён',
  [EStandStatus.BLOCKED]: 'Заблокирован',
  [EStandStatus.ACCEPTED]: 'Активен',
  [EStandStatus.IN_GAME]: 'В игре',
}

export const standStatusNamesOptions: { label: string; value: string }[] = Object.keys(standStatusNames).map((item) => {
  return {
    value: item,
    label: standStatusNames[item],
  }
})
