// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const mrtDateTimeFilter = (row, _columnIds: string, filterValue: any) => {
  // @ts-ignore
  const rowValue = row.getValue<Date | null>(_columnIds)

  if (!filterValue || (!filterValue.from && !filterValue.to)) return true

  if (!(rowValue instanceof Date)) return false

  const rowDate = new Date(rowValue)
  const { from, to } = filterValue

  if (from && to) {
    return rowDate >= from && rowDate <= to
  }
  if (from) {
    return rowDate >= from
  }
  if (to) {
    return rowDate <= to
  }

  return true
}
