import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { intervalToDuration } from 'date-fns'
import { toast } from 'react-toastify'
import { MaterialReactTable, MRT_ColumnDef, MRT_ColumnFiltersState, MRT_PaginationState } from 'material-react-table'
import { MRT_Localization_RU } from 'material-react-table/locales/ru'
import { useQuery } from 'react-query'
import { getDateFormat } from '../../utils/date'
import routes from '../../router/routes'
import ApiRecording, { IRecording } from '../../api/recordings'

const zeroPad = (num: number): string => String(num).padStart(2, '0')

enum ENameFields {
  USER_LOGIN = 'userLogin',
  SESSION_ID = 'sessionId',
}

const Recordings: FC = observer(() => {
  const [searchParams, setSearchParams] = useSearchParams({})
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    [...searchParams].map(([id, value]) => ({ id, value }))
  )
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 15,
  })

  const {
    data: recordings,
    isError: isErrorRecordings,
    isFetching: isFetchingRecordings,
    isLoading: isLoadingRecordings,
  } = useQuery(
    [ApiRecording.prefix, pagination.pageIndex, pagination.pageSize, columnFilters],
    async () => {
      const filters = columnFilters.reduce((acc, item) => {
        acc[item.id] = String(item.value)
        return acc
      }, {} as Record<string, string>)

      setSearchParams(filters)

      return ApiRecording.getRecordings({
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,

        ...filters,
      })
    },
    {
      select: (data) => data,
      onError: (err) => toast.error(`${err}`),
      enabled: true,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )

  const columns: MRT_ColumnDef<IRecording>[] = [
    {
      id: ENameFields.USER_LOGIN,
      accessorFn: (row) => row?.user?.id || '',
      header: 'Логин',
      Cell: ({ row }) => row.original.user.login,
    },
    {
      accessorKey: ENameFields.SESSION_ID,
      header: 'ID сессии',
    },
    {
      accessorFn: (row) => `${getDateFormat(row.startRecordTimestamp)} - ${getDateFormat(row.endRecordTimestamp)}`,
      header: 'Начало и конец записи',
      enableColumnFilter: false,
    },
    {
      id: 'duration',
      header: 'Продолжительность',
      Cell: ({ row }) => {
        const duration = intervalToDuration({
          start: row.original.startRecordTimestamp,
          end: row.original.endRecordTimestamp,
        })

        return `${zeroPad(duration?.hours || 0)}:${zeroPad(duration?.minutes || 0)}:${zeroPad(duration?.seconds || 0)}`
      },
      enableColumnFilter: false,
    },
  ]

  return (
    <Box>
      <MaterialReactTable
        localization={MRT_Localization_RU}
        enableFullScreenToggle={false}
        enableGlobalFilter={false}
        enableFilterMatchHighlighting={false}
        initialState={{
          showColumnFilters: true,
          density: 'comfortable',
        }}
        state={{
          pagination,
          isLoading: isLoadingRecordings,
          showAlertBanner: isErrorRecordings,
          showProgressBars: isFetchingRecordings,
          columnFilters,
        }}
        manualFiltering
        onColumnFiltersChange={setColumnFilters}
        manualPagination
        onPaginationChange={setPagination}
        columns={columns}
        data={recordings?.rows || []}
        rowCount={recordings?.totalRows ?? 0}
        muiTableBodyRowProps={({ row }) => ({
          onClick: (event) => {
            event.preventDefault()

            window.open(`${window.location.origin}${routes.recordings}/${row.original.id}`, '_blank')
          },
          sx: {
            cursor: 'pointer',
          },
        })}
      />
    </Box>
  )
})

export default Recordings
