import { FC } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import LockOpenIcon from '@mui/icons-material/LockOpen'

type UnBlockBtnProps = {
  disabled?: boolean
  onClick?: () => void
}

const UnBlockBtn: FC<UnBlockBtnProps> = ({ onClick, disabled }) => {
  return (
    <IconButton
      color="secondary"
      disabled={disabled}
      onClick={(e) => {
        e.preventDefault()

        if (onClick) onClick()
      }}>
      <Tooltip title="Разблокировать">
        <LockOpenIcon />
      </Tooltip>
    </IconButton>
  )
}

export default UnBlockBtn
