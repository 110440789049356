import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { useMountedState } from 'react-use'
import { Box, IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table'
import { MRT_Localization_RU } from 'material-react-table/locales/ru'
import { useStore } from '../../stores'
import { FilterBar } from '../../components'
import { EDrawerView } from '../../stores/DrawerCRUD.store'
import { ERole, roleNames, roleNamesOptions } from '../../constants/role'
import { EOperatorStatus, operatorStatusNames, operatorStatusNamesOptions } from '../../constants/operator.status'
import { IOperator } from '../../api/operators'

const Operators: FC = observer(() => {
  const isMounted = useMountedState()
  const { operatorsStore, drawerCRUDStore } = useStore()

  const columns: MRT_ColumnDef<IOperator>[] = [
    {
      accessorKey: 'id',
      header: 'ID',
      enableColumnFilter: false,
    },
    {
      accessorKey: 'login',
      header: 'Логин',
    },
    {
      accessorFn: (row) => `${row.firstName} ${row.lastName}`,
      header: 'Имя',
    },
    {
      accessorKey: 'role',
      header: 'Роль',
      Cell: ({ cell }) => {
        return roleNames[cell.getValue<ERole>()]
      },
      filterVariant: 'select',
      filterSelectOptions: roleNamesOptions.map((item) => ({ text: item.label, value: item.value })),
    },
    {
      accessorKey: 'status',
      header: 'Статус',
      Cell: ({ cell }) => {
        return operatorStatusNames[cell.getValue<EOperatorStatus>()]
      },
      filterVariant: 'select',
      filterSelectOptions: operatorStatusNamesOptions.map((item) => ({ text: item.label, value: item.value })),
    },
  ]

  useEffect(() => {
    ;(async () => {
      if (!isMounted()) return

      await operatorsStore.getOperators()
    })()
  }, [])

  return (
    <Box>
      <FilterBar>
        <IconButton
          onClick={(e) => {
            e.preventDefault()

            drawerCRUDStore.changeView(EDrawerView.OPERATOR_CREATE)
          }}>
          <AddIcon />
        </IconButton>
      </FilterBar>

      <MaterialReactTable
        localization={MRT_Localization_RU}
        enableFullScreenToggle={false}
        enableGlobalFilter={false}
        enableFilterMatchHighlighting={false}
        initialState={{
          showColumnFilters: true,
          density: 'comfortable',
        }}
        columns={columns}
        data={operatorsStore.rows || []}
        enablePagination={false}
        muiTableBodyRowProps={({ row }) => ({
          onClick: (event) => {
            event.preventDefault()

            drawerCRUDStore.changeView(EDrawerView.OPERATOR_EDIT, row.original.id)
          },
          sx: {
            cursor: 'pointer',
          },
        })}
      />
    </Box>
  )
})

export default Operators
