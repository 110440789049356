import { FC } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'

type EditBtnProps = {
  disabled?: boolean
  onClick?: () => void
}

const EditBtn: FC<EditBtnProps> = ({ onClick, disabled }) => {
  return (
    <IconButton
      color="primary"
      disabled={disabled}
      onClick={(e) => {
        e.preventDefault()

        if (onClick) onClick()
      }}>
      <Tooltip title="Редактировать">
        <EditIcon />
      </Tooltip>
    </IconButton>
  )
}

export default EditBtn
