import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { MaterialReactTable, MRT_ColumnDef, MRT_PaginationState } from 'material-react-table'
import { MRT_Localization_RU } from 'material-react-table/locales/ru'
import { useQuery } from 'react-query'
import { format } from 'date-fns'
import { DateRange } from 'react-day-picker'
import { DateRangePicker, FilterBar, Select } from '../../components'
import GameHistory, { IGameHistory } from '../../api/gameHistory'
import { dateFallback, EDateFormat, getDateFormat } from '../../utils/date'

enum SearchParams {
  DATE_FROM = 'dateFrom',
  DATE_TO = 'dateTo',
  PLAYER_ID = 'playerId',
}

const dateFormat = EDateFormat.yyy_MM_dd_HH_mm

const HistoryRoulette: FC = observer(() => {
  const { gameId } = useParams<{ gameId: string }>()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [range, setRange] = useState<DateRange>({
    from: dateFallback(searchParams.get(SearchParams.DATE_FROM)),
    to: dateFallback(searchParams.get(SearchParams.DATE_TO)),
  })
  const [selectedPlayer, setSelectedPlayer] = useState<string>(searchParams.get(SearchParams.PLAYER_ID) || '')

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 15,
  })

  const handleReset = (): void => {
    setRange({
      from: undefined,
      to: undefined,
    })
    setSelectedPlayer('')
  }

  const { data, isLoading, isError, isFetching } = useQuery(
    [`history roulette by game id ${gameId}`, pagination.pageIndex, pagination.pageSize, range, selectedPlayer],
    async () => {
      const { from, to } = range

      const queryFilters = {
        ...(from &&
          to && {
            [SearchParams.DATE_FROM]: format(from, dateFormat),
            [SearchParams.DATE_TO]: format(to, dateFormat),
          }),
        ...(selectedPlayer && {
          [SearchParams.PLAYER_ID]: selectedPlayer,
        }),
      }

      setSearchParams(queryFilters)

      return GameHistory.getHistoryById({
        id: gameId as string,
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,

        ...queryFilters,
      })
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  )

  const { data: playersInSession, isError: playersInSessionError } = useQuery(
    `history roulette player by game id ${gameId}`,
    async () => {
      return GameHistory.getDetailHistoryPlayersByGameId(gameId as string)
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  )

  if (playersInSessionError) return null

  const columns: MRT_ColumnDef<IGameHistory>[] = [
    {
      accessorKey: 'session_id',
      header: 'ID сессии',
    },
    {
      accessorFn: (row) => {
        return row.players.map((player) => player.name).join(', ')
      },
      header: 'Игроки',
      enableSorting: false,
    },
    {
      accessorFn: (row) => {
        return getDateFormat(new Date(row.time_session))
      },
      header: 'Дата',
      sortingFn: 'datetime',
      enableColumnFilter: false,
    },
  ]

  return (
    <>
      <Box>
        <FilterBar>
          <Typography variant="h6">История игры рулетка: {gameId}</Typography>
        </FilterBar>
      </Box>

      <Box>
        <FilterBar>
          <Stack gap={2} direction="row" alignItems="center">
            <DateRangePicker
              from={range.from}
              to={range.to}
              onChangeRange={(rangeDate) => {
                setRange({
                  from: rangeDate?.from,
                  to: rangeDate?.to,
                })
              }}
            />
            <Select
              size="small"
              variant="outlined"
              id="players"
              label="Игроки"
              value={selectedPlayer}
              options={[
                {
                  label: 'Все',
                  value: '',
                },
                ...(playersInSession?.map((playersInSessionItem) => {
                  return {
                    label: playersInSessionItem.login,
                    value: playersInSessionItem.id,
                  }
                }) || []),
              ]}
              onChange={(e) => {
                setSelectedPlayer(e as string)
              }}
            />
            <Button
              type="button"
              variant="outlined"
              onClick={(e) => {
                e.preventDefault()
                handleReset()
              }}>
              Сбросить
            </Button>
          </Stack>
        </FilterBar>
      </Box>

      <MaterialReactTable
        localization={MRT_Localization_RU}
        enableFullScreenToggle={false}
        enableGlobalFilter={false}
        enableFilterMatchHighlighting={false}
        initialState={{
          showColumnFilters: true,
          density: 'comfortable',
        }}
        state={{
          pagination,
          isLoading,
          showAlertBanner: isError,
          showProgressBars: isFetching,
        }}
        manualPagination
        onPaginationChange={setPagination}
        columns={columns}
        data={data || []}
        rowCount={Number(data?.[0]?.total_count) || 0}
        muiTableBodyRowProps={({ row }) => ({
          onClick: (event) => {
            event.preventDefault()

            navigate(`/game/${gameId}/session/${row.original.session_id}/detail`, {
              state: {
                dates: data?.map((item) => item.time_session) || [],
                sessions: data?.map((item) => item.session_id) || [],
              },
            })
          },
          sx: {
            cursor: 'pointer',
          },
        })}
      />
    </>
  )
})

export default HistoryRoulette
