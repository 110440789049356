import { makeAutoObservable, runInAction } from 'mobx'
import { toast } from 'react-toastify'
import AntMedia, { IStream } from '../api/antMedia'

export class AntMediaStore {
  streams: IStream[] = []

  constructor() {
    makeAutoObservable(this)
  }

  getList = async (): Promise<void> => {
    try {
      const streams = await AntMedia.getList()

      runInAction(() => {
        this.streams = [...streams]
      })
    } catch (e) {
      toast.error(`${e}`)
    }
  }

  get streamsOptions(): Array<{
    value: string
    label: string
  }> {
    return this.streams.map((item) => {
      return {
        value: item.streamId,
        label: item?.name || 'No name',
      }
    })
  }

  findStreamNameById = (id: string): string => {
    return this.streams.find((item) => item.streamId === id)?.name || ''
  }
}
