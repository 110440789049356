import { TRouletteLastBall } from '../api/game'
import { EColorLastBalls } from '../constants/colorLastBalls'

export function isNoSpin(ball: TRouletteLastBall): boolean {
  return ball === 'no spin'
}

export function isNumberZero(number: TRouletteLastBall): boolean {
  return number === 0
}

export function isNumberRed(number: TRouletteLastBall): boolean {
  if (typeof number === 'string') return false

  const redNumbers = [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36]

  return redNumbers.indexOf(number) >= 0
}

export function isNumberBlack(number: TRouletteLastBall): boolean {
  return !isNumberRed(number) && !isNumberZero(number)
}

export const colorNumberRoulette = (number: TRouletteLastBall): EColorLastBalls => {
  if (isNoSpin(number)) return EColorLastBalls.NO_SPIN
  if (isNumberZero(number)) return EColorLastBalls.GREEN
  if (isNumberRed(number)) return EColorLastBalls.RED
  return EColorLastBalls.BLACK
}
