import { observer } from 'mobx-react-lite'
import { FC, useEffect, useRef, useState } from 'react'
import { unpack } from 'rrweb'
import WebPlayer from 'rrweb-player'
import 'rrweb-player/dist/style.css'
import { useParams } from 'react-router-dom'
import { Box, Paper } from '@mui/material'
import { toast } from 'react-toastify'
import { useStore } from '../../stores'
import { FilterBar } from '../../components'
import { IRecordingWithEvents } from '../../api/recordings'

const Recording: FC = () => {
  const params = useParams()
  const videRef = useRef<HTMLDivElement>()
  const [data, setData] = useState<Pick<IRecordingWithEvents, 'user' | 'sessionId'>>()
  const { recordingsStore } = useStore()

  const playVideo = async (): Promise<void> => {
    try {
      const response = await recordingsStore.getRecordingById(params.recordId || '')

      setData({
        user: { ...response.user },
        sessionId: response.sessionId,
      })

      // eslint-disable-next-line new-cap,no-new
      new WebPlayer({
        target: videRef.current as HTMLElement,
        props: {
          width: 800,
          height: 400,
          autoPlay: false,
          events: response.events,
          skipInactive: true,
          unpackFn: unpack,
        },
      })
    } catch (e) {
      toast.error(`${e}`)
    }
  }

  useEffect(() => {
    playVideo()
  }, [params.recordId])

  return (
    <Box>
      <FilterBar>
        Запись игры игрока: {data?.user.id}({data?.user.login}), ID сессии: {data?.sessionId}
      </FilterBar>
      <Box
        component={Paper}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 2,
        }}>
        <Box ref={videRef} />
      </Box>
    </Box>
  )
}

export default observer(Recording)
