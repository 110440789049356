import { makeAutoObservable } from 'mobx'

export class AlertDialogStore {
  open: boolean = false

  title: string = ''

  content: string = ''

  isAgree: boolean = false

  // @ts-ignore
  // public myCallback: () => void

  constructor() {
    makeAutoObservable(this)
  }

  onOpen = (data: { title: string; content: string; onAgree: () => void; onDisagree: () => void }): void => {
    this.open = true
    this.title = data.title
    this.content = data.content
    // return Promise.resolve()
    // return Promise.reject()
  }

  onAgree = (): void => {}

  onClose = (): void => {
    this.open = false
    this.title = ''
    this.content = ''
  }
}
