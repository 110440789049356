export enum ERole {
  UNKNOWN = 'unknown',
  ADMINISTRATOR = 'administrator',
  SYSTEM = 'system',
  MANAGER = 'manager',
  DEALER = 'dealer',
}

export const roleNames: {
  [k: string]: string
} = {
  [ERole.ADMINISTRATOR]: 'Администратор',
  [ERole.SYSTEM]: 'Системный',
  [ERole.MANAGER]: 'Менеджер',
  [ERole.DEALER]: 'Дилер',
}

export const roleNamesOptions = Object.keys(roleNames)
  .filter((item) => item !== ERole.SYSTEM)
  .map((item) => {
    return {
      value: item,
      label: roleNames[item],
    }
  })
