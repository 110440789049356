export enum EGameName {
  ROULETTE = 'roulette',
  TEXAS_POKER = 'texas_poker',
  RUSSIAN_POKER = 'russian_poker',
}

export const gameNames: {
  [key: string]: string
} = {
  [EGameName.ROULETTE]: 'Рулетка',
  [EGameName.TEXAS_POKER]: 'Техасский покер',
  [EGameName.RUSSIAN_POKER]: 'Русский покер',
}

export const gameCodeName = Object.keys(gameNames).map((code) => {
  return {
    code,
    name: gameNames[code],
  }
})

export const gameNamesOptions = gameCodeName.map((item) => {
  return {
    value: item.code,
    label: item.name,
  }
})
