export enum EOperatorStatus {
  DELETED = 100,
  BLOCKED = 150,
  FIRED = 160,
  ACTIVE = 200,
}

export const operatorStatusNames: {
  [k: string]: string
} = {
  [EOperatorStatus.DELETED]: 'Неактивный',
  [EOperatorStatus.BLOCKED]: 'Заблокирован',
  [EOperatorStatus.FIRED]: 'Уволен',
  [EOperatorStatus.ACTIVE]: 'Активен',
}

export const operatorStatusNamesOptions: { label: string; value: string }[] = Object.keys(operatorStatusNames).map(
  (item) => {
    return {
      value: item,
      label: operatorStatusNames[item],
    }
  }
)
