import { useTheme } from '@mui/material/styles'
import { Outlet } from 'react-router-dom'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import { FC, useEffect } from 'react'
import { AppBar } from '@mui/material'
import { observer } from 'mobx-react-lite'
import MenuIcon from '@mui/icons-material/Menu'
import IconButton from '@mui/material/IconButton'
import { io } from 'socket.io-client'
import { Account, DrawerAdmin, DrawerCRUD } from '../../components'
import { useStore } from '../../stores'
import { EDrawerMainNavStore } from '../../stores/DrawerMainNav.store'

const AdminLayout: FC = () => {
  const { drawerMainNavStore, loginStore } = useStore()
  const theme = useTheme()

  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_API_URL}/cover`, {
      query: {
        accessToken: loginStore.accessToken,
      },
      secure: true,
      transports: ['websocket'],
      forceNew: true,
    })

    return () => {
      socket?.removeAllListeners()
      socket?.disconnect()
    }
  }, [loginStore.accessToken])

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}>
      <AppBar
        position="fixed"
        sx={{
          zIndex: theme.zIndex.drawer + 1,
        }}>
        <Toolbar
          sx={{
            width: '100%',
          }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}>
            <Box>
              {loginStore.isRoleAM && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={(e) => {
                    e.preventDefault()

                    drawerMainNavStore.toggleView()
                  }}>
                  <MenuIcon />
                </IconButton>
              )}
            </Box>

            <Account />
          </Box>
        </Toolbar>
      </AppBar>
      {loginStore.isRoleAM && <DrawerAdmin />}
      <Box component="main">
        <Toolbar />
        <Box
          sx={{
            p: 2,
            ml: loginStore.isDealer ? EDrawerMainNavStore.HIDDEN : drawerMainNavStore.view,
          }}>
          <Outlet />
        </Box>
      </Box>
      {loginStore.isRoleAM && <DrawerCRUD />}
    </Box>
  )
}

export default observer(AdminLayout)
