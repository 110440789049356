import { useParams } from 'react-router-dom'
import { FC } from 'react'
import { FullScreenRouteDialog, PlayerTransactionsTable } from '../index'
import useQueryPlayerById from '../../hooks/useQueryPlayerById'

const PlayerTransactionsDialog: FC = () => {
  const { playerId } = useParams()

  const { data: player } = useQueryPlayerById(playerId as string)

  if (!player) return null

  return (
    <FullScreenRouteDialog title={`История транзакций: ${player.login}`}>
      <PlayerTransactionsTable id={player.id} login={player.login} currency={player.currency} />
    </FullScreenRouteDialog>
  )
}

export default PlayerTransactionsDialog
