import { FC } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { toast } from 'react-toastify'
import { OperatorData, WrapDrawerCreateForm } from '../index'
import OperatorStore from '../../stores/Operator.store'
import { ERole } from '../../constants/role'
import { EOperatorStatus } from '../../constants/operator.status'
import { useStore } from '../../stores'
import { EDrawerView } from '../../stores/DrawerCRUD.store'

const OperatorCreate: FC = observer(() => {
  const { drawerCRUDStore } = useStore()

  const operator = useLocalObservable(() => {
    return new OperatorStore({
      countLoginError: 0,
      createdAt: '',
      data: {},
      firstName: '',
      id: '',
      lastName: '',
      login: '',
      password: '',
      role: ERole.ADMINISTRATOR,
      status: EOperatorStatus.ACTIVE,
      updatedAt: '',
    })
  })
  return (
    <WrapDrawerCreateForm
      title="Создание оператора"
      disabledSave={operator.isBlockSave}
      onSave={async () => {
        try {
          const { id } = await operator.onCreate()
          toast.success(`Оператор "${operator.login}" успешно создан`)
          drawerCRUDStore.changeView(EDrawerView.OPERATOR_EDIT, id)
        } catch (e) {
          toast.error(`${e}`)
        }
      }}>
      <OperatorData
        operator={operator}
        pickFields={['login', 'firstName', 'lastName', 'password', 'confirmPassword']}
      />
    </WrapDrawerCreateForm>
  )
})

export default OperatorCreate
